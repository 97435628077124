import {escapeRegExp,filter} from 'lodash'
import React, { useContext, useState } from 'react';
import { Form, Grid, Header, Icon, Message, Search, Segment } from 'semantic-ui-react';
import AuthContext from '../context/authContext';
import { useTranslation } from "react-i18next";
import axios from '../axios';
import {normalizeString, seperatorTxtUrl } from '../myfunctions';

const initialState = {
    loading: false,
    results: [],
    value: '',
  }
  
  function searchReducer(state, action) {
    switch (action.type) {
      case 'CLEAN_QUERY':
        return initialState
      case 'START_SEARCH':
        return { ...state, loading: true, value: action.query }
      case 'FINISH_SEARCH':
        return { ...state, loading: false, results: action.results }
      case 'UPDATE_SELECTION':
        return { ...state, value: action.selection }
  
      default:
        throw new Error()
    }
  }


function SearchLic({msize,closeOpenModal,pageLogin}) {
    const authContext = useContext(AuthContext);
    const { t } = useTranslation();
    const [searchMember, setsearchMember] = useState({ search: null, resultSearch: null, error: 0 })

    function findMembersText() {
       // console.log(value, loading, results, value)
        let ra = false
        if (value && results.length > 0) {
          results.forEach((row) => {
            if (row.title === value && row.member_id && row.member_name) {
                if(closeOpenModal){
                    closeOpenModal()
                } 
                ra = true
                window.location.href = "/member/" + row.member_id + "/" + normalizeString(row.member_name.split(' ').join(seperatorTxtUrl()));    
              //history.push("/member/" + row.member_id + "/" + normalizeString(row.member_name.split(' ').join(seperatorTxtUrl())));
            }
          })
        } 
        if(ra===false){
          setsearchMember({ ...searchMember, resultSearch: "Brak szukanego / wybranego profilu uczestnika", error: 1 })
        }

        
      }

      
  const [state, dispatch] = React.useReducer(searchReducer, initialState)
  const { loading, results, value } = state
  const timeoutRef = React.useRef()
  const [dataPlayerAll, setdataPlayerAll] = useState([])
  

  const handleSearchChange = (async (e, data) => {

    clearTimeout(timeoutRef.current)
    dispatch({ type: 'START_SEARCH', query: data.value })
    
    let arrtmp = [];
    if (data.value.length >= 4 || (Number(data.value) > 2 && data.value.length >= 4)) {
     
      try {
        if (Number(data.value) > 2 && data.value.length >= 4) {
          const reslic = await axios.post("/members/findMember", { lictl: data.value }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (reslic) {
           
            reslic.data.forEach((row) => {
             
              arrtmp.push({ title: row.member_name + " [NTG" + data.value + "]", description: "[ID" + row.member_id + "]", member_name: row.member_name, member_id: row.member_id })
            })
            setdataPlayerAll(arrtmp)
          }
        } else {
          const res = await axios.post("/members/findMembersBySubstring", { textSearch: data.value }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (res) {
            res.data.forEach((row) => {
              arrtmp.push({ title: row.member_name + " [NTG" + row.lictl + "]", description: "[ID" + row.member_id + "]", member_name: row.member_name, member_id: row.member_id })
            })
            setdataPlayerAll(arrtmp)
          }
        }
      } catch (error) {
        // Handle errors
      }
    }

    let dataPlayerAllTmp = arrtmp.length > 0 ? arrtmp : dataPlayerAll

    timeoutRef.current = setTimeout(() => {
      if (data.value.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' })
        return
      }
      const re = new RegExp(escapeRegExp(data.value), 'i')
      const isMatch = (result) => re.test(result.title)

      dispatch({
        type: 'FINISH_SEARCH',
        results: filter(dataPlayerAllTmp, isMatch),
      })
    }, 300)
  })// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Segment placeholder={pageLogin ? false : true} basic={pageLogin ? true : false}  style={{minHeight:msize==="small" ? 200 : 300}}>

        <Header as="h4" icon style={{padding:0,fontSize:msize==="small" ? 13 : 15}}>
          <Icon name='pie chart'/>
          {t("stronaStartowa.wpisz")} <span className="ModalInfo" onClick={() => authContext.changeModalInfo({ nameModal: "lictl", name: t("LicencjaTL"), value: "" })}>{t("LicencjaTL")}</span> {t("stronaStartowa.i_zobacz_swoje_statystyki")}
        </Header>
        <Grid stackable>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form size='tiny' >
                <Form.Field
                  loading={loading}
                  control={Search}
                  placeholder='Np 4 znaki nazwiska lub numer TG'
                  fluid
                  required
                  onResultSelect={(e, data) =>
                    dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title })
                  }
                  onSearchChange={handleSearchChange}
                  results={results}
                  value={value}

                />      
                <Form.Button size='tiny' type='button' onClick={()=>findMembersText()} id="playerstatsclick" color="instagram" fluid>{t("stronaStartowa.btn_zobacz_statystyki").toUpperCase()}</Form.Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>


        {searchMember && searchMember.error === 1 ?
          <Message size='small' negative>
            <Message.Header>{searchMember.resultSearch}</Message.Header>
          </Message>

          : ''}
      </Segment>
    )
}

export default SearchLic;