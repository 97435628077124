import React from 'react';
import { Icon, Message, Progress } from 'semantic-ui-react'
import { getLimitUsersPackage } from '../../../../myfunctions';
import { useTranslation } from 'react-i18next'
import MyTournamentAddUsers from './MyTournamentAddUsers';
import CountDownTimer from '../../countDownTimer/CountDownTimer';


function MyTournamentAddUsersPre({ tournamentJson, subTournamentJson, registrationTournament }) {
  const { t } = useTranslation();
  
  function getMemberReserve() {
    if (subTournamentJson) {
      if(isMemberReserve()){
        return true
      }
      else if (subTournamentJson.members_limit > 0 && subTournamentJson.members.length >= subTournamentJson.members_limit && subTournamentJson.members_reserve_limit > 0) {
        return true
      }
    }
    return false
  }

  function isMemberReserve() {
    if (subTournamentJson) {
      if (subTournamentJson.members_reserve_limit > 0 && subTournamentJson.members_reserve.length > 0) {
        return true
      }
    }
    return false
  }

  function showInfoIsEmpty() {
    try{
      if (subTournamentJson && registrationTournament===false) {
        if (subTournamentJson.members_reserve_limit > 0 && subTournamentJson.members_reserve.length > 0 && subTournamentJson.members_limit > 0 && subTournamentJson.members.length < subTournamentJson.members_limit) {
          return true
        }
      }
      return false
    }catch(e){
      return false
    }
  }

  function getCountEmpty(){
    try{
      return subTournamentJson.members_limit-subTournamentJson.members.length  
    }catch(e){
      return 0
    }
  }

  return (
    <>
      {registrationTournament && subTournamentJson && subTournamentJson.members && subTournamentJson.members_limit > 0 && subTournamentJson.members.length >= subTournamentJson.members_limit && subTournamentJson.members_reserve.length >= subTournamentJson.members_reserve_limit ?
        <Message size='small' >
          <Message.Header>{t("WidokTurnieju.zapisy_brak_mozliwosci")}</Message.Header>
          <p>{t("WidokTurnieju.zapisy_brak_mozliwosci_info_limit")}</p>
        </Message>
        : <>
          {tournamentJson && tournamentJson.tournament_free !== 2 && Number(subTournamentJson && subTournamentJson.members ? subTournamentJson.members.length : 0) >= getLimitUsersPackage(tournamentJson.tournament_free) ?
            <Message size='small' negative>
              <Message.Header>{t("WidokTurnieju.zapisy_brak_mozliwosci_info")}</Message.Header>
              <p>Limit {subTournamentJson.members_limit > 0 && subTournamentJson.members_limit > getLimitUsersPackage(tournamentJson.tournament_free) ? subTournamentJson.members_limit : getLimitUsersPackage(tournamentJson.tournament_free)} {t("WidokTurnieju.zapisy_brak_mozliwosci_info2")}</p>
            </Message>
            :
            <>
              {registrationTournament ?
                <>
                  {tournamentJson && (tournamentJson.tournament_free !== 2 || subTournamentJson.members_limit > 0) ?
                    getMemberReserve() ? <Progress color='orange' className={Number(subTournamentJson.members_reserve.length) > 2 ? 'tgprogress' : 'tgprogressp'} value={subTournamentJson.members_reserve.length} total={subTournamentJson.members_reserve_limit} progress='ratio' >
                      <span > Limit {subTournamentJson.members_reserve_limit} uczestników rezerwowych, zapisanych {subTournamentJson.members_reserve.length}</span>
                    </Progress> : <>
                      <Progress value={subTournamentJson && subTournamentJson.members ? subTournamentJson.members.length : 0} className={subTournamentJson && subTournamentJson.members && subTournamentJson.members.length > 2 ? 'tgprogress' : 'tgprogressp'} total={subTournamentJson.members_limit > 0 && subTournamentJson.members_limit <= getLimitUsersPackage(tournamentJson.tournament_free) ? subTournamentJson.members_limit : getLimitUsersPackage(tournamentJson.tournament_free)} progress='ratio' error>
                        Limit {subTournamentJson.members_limit > 0 && subTournamentJson.members_limit <= getLimitUsersPackage(tournamentJson.tournament_free) ? subTournamentJson.members_limit : getLimitUsersPackage(tournamentJson.tournament_free)} uczestników { subTournamentJson.members_reserve_limit > 0 ? <> + {subTournamentJson.members_reserve_limit} rezerwowych </>: ""}
                      </Progress>
                     
                      </>
                    : ''}
                  <CountDownTimer endDate={tournamentJson.register_stop_date} textValue={t("WidokTurnieju.koniec_zapisow_za")} textValueFinish="" beautifulText="0" />
                </> : ""}

                {showInfoIsEmpty() ? <Message success size='mini'><Icon name="info circle"/> Masz wolne miejsce (<b>{getCountEmpty()}</b>) w rozgrywkach, przypisz uczestnika z listy rezerwowej!</Message> : ""}
              <MyTournamentAddUsers member_reserve={getMemberReserve()} show_members_additional_info={tournamentJson.show_members_additional_info} subTournamentRow={subTournamentJson} google_ads={tournamentJson.google_ads} tournamentFree={tournamentJson.tournament_free} subTournamentId={subTournamentJson.subtournament_id} subTurnamentType={subTournamentJson.member_type} registrationTournament={registrationTournament} />
            </>}
        </>}

    </>
  )
}

export default MyTournamentAddUsersPre;