import React, { useEffect, useState, useContext } from 'react';
import { Icon, Button, Message, Input, Accordion, AccordionTitle, AccordionContent, Divider, MessageHeader, Grid, Segment, Table, TableHeaderCell, Form } from 'semantic-ui-react'
import { set } from 'lodash'
import JWT from 'expo-jwt';
import { getInfoFreeBlock,  getKeyJwtTG, matchplacetmpS2 } from '../../../myfunctions';
import { QRCodeSVG } from 'qrcode.react';
import { ConnectWS } from '../../../ConnectWS';
import AuthContext from '../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import InputCalendarAndTime from '../../../tools/InputCalendarAndTime';



function GetScoreDetails({subtournament_id,subtournamentJsonRoot,tournamentJsonRoot,hiddenScoreDetails,tournament_free, typeSubTournament, openModalScoreTMP, openModalScore, setopenModalScore, systemId }) {
  const { addToast } = useToasts();
  const [activeIndex2, setactiveIndex2] = useState(0)
  const [match_details, setmatch_details] = useState(null)
  const [refreshd, setrefreshd] = useState(1)
  const [activeIndex3, setactiveIndex3] = useState(0)
  const [isnoscore, setisnoscore] = useState(true)


  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
     
      //  console.log("openModalScoreTMP", openModalScoreTMP)
      // console.log("openModalScore", openModalScore)
    }
    if (systemId) {
      if (systemId && systemId !== 5) {
        setactiveIndex2(1)
      }
    }

    if (openModalScoreTMP.match_details != null) {

      setactiveIndex2(1)

    }


    if (openModalScoreTMP && openModalScoreTMP.member1_score !== null && openModalScoreTMP.member2_score !== null) {
      setisnoscore(false)
    }


    if (openModalScoreTMP && openModalScoreTMP.match_details !== undefined && openModalScoreTMP.match_details === null && systemId === 5) {
      //dart

      setrefreshd(refreshd + 1)

      // setopenModalScore({ ...openModalScore, match_details: JSON.stringify({ ...getJsonDartDef(openModalScoreTMP.match_member1_id, openModalScoreTMP.match_member2_id) }) })
    } else {
      if (openModalScoreTMP.match_details) {
        preStart()
      }
    }

    setactiveIndex3(1)
    if (openModalScore && openModalScore.match_place) {
      try{

        if(JSON.parse(openModalScore.match_place).place_id || JSON.parse(openModalScore.match_place).match_dt){    
  
          if(matchplaceJsonSave && matchplaceJson.place_id){
            setmatchplaceJson({...matchplaceJsonSave})
          }else{
            setmatchplaceJson(JSON.parse(openModalScore.match_place))
          }
          

        }
       
      }catch(e){
        //setmatchplaceJson(openModalScore.match_place)
      }
     

    }

    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [openModalScoreTMP, openModalScore])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
   if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      //  console.log("openModalScoreTMP", openModalScoreTMP)
      // console.log("openModalScore", openModalScore)
    }
    if (openModalScoreTMP && openModalScoreTMP.match_details !== undefined && openModalScoreTMP.match_details === null && systemId === 5) {
      setmatch_details(JSON.stringify({ ...getJsonDartDef(openModalScoreTMP.match_member1_id, openModalScoreTMP.match_member2_id) }))
    }
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [refreshd])// eslint-disable-line react-hooks/exhaustive-deps


  

  useEffect(() => {
     return () => {
      setmatchplaceJsonSave({place_id:null,place_desc:null,match_dt:null})
     }; // use effect cleanup to set flag false, if unmounted
   },[] )// eslint-disable-line react-hooks/exhaustive-deps
 
 


  function preStart() {
  
    if (openModalScoreTMP.match_details && JSON.parse(openModalScoreTMP.match_details).mde === true) {
      //  setloading(true)
      ConnectWS("/generator/checkMatchExists", null, { match_id: openModalScoreTMP.subtournament_match_id, member1_id: openModalScoreTMP.match_member1_id, member2_id: openModalScoreTMP.match_member2_id }, function (response, logout) {
        if (logout === 1) {

        } else if (response && response.status && Number(response.status) === 200) {
          if (response.data.match_data.match_details) {

            setmatch_details(response.data.match_data.match_details)
          } else {
            setmatch_details(null)
          }

        } else {
          setmatch_details(null)
        }

      });
    } else {
     
      setmatch_details(openModalScoreTMP.match_details)
    }

  }


  function setValueJsonScore() {

    ConnectWS("/generator/setMatchDetailsNoToken", authContext.token, { match_id: openModalScoreTMP.subtournament_match_id, member1_id: openModalScoreTMP.match_member1_id, member2_id: openModalScoreTMP.match_member2_id, match_details: match_details, removeScore: false }, function (response, logout) {
      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {

        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {

        addToast(response, { appearance: 'error' });
      }

    });


  }

  function addRowJson(data) {

    try {
      if (openModalScore.match_details !== undefined && openModalScore.match_details !== null && match_details !== null) {
        let arrS = JSON.parse(match_details).score
        arrS.push({ [data.match_member1_id]: 0, [data.match_member2_id]: 0, "set": arrS.length + 1 })
        setopenModalScore({ ...openModalScore, match_details: JSON.stringify({ score: arrS }) })
      }
    } catch (e) {
      console.log(e)
      return "Bład"
    }

  }

  function removeRowJson(i) {

    try {
      if (openModalScore.match_details !== undefined && openModalScore.match_details !== null && match_details !== null) {
        let arrS = JSON.parse(match_details).score
        arrS.splice(i, 1);
      
        if (arrS.length === 0) {
          ConnectWS("/generator/setMatchDetailsNoToken", authContext.token, { match_id: openModalScoreTMP.subtournament_match_id, member1_id: openModalScoreTMP.match_member1_id, member2_id: openModalScoreTMP.match_member2_id, match_details: null, removeScore: false }, function (response, logout) {
            if (logout === 1) {

            } else if (response && response.status && Number(response.status) === 200) {
              setmatch_details(null)
              setopenModalScore({ ...openModalScore, match_details: null })
              authContext.changeRefreshView(authContext.refreshView + 1)
            } else {

              addToast(response, { appearance: 'error' });
            }

          });

        } else {
          setopenModalScore({ ...openModalScore, match_details: JSON.stringify({ score: arrS }) })
        }


      }
    } catch (e) {
      console.log(e)
      return "Bład"
    }

  }

  function getMatchDetailsScore(data) {
    try {
      if (systemId === 5) {
        //dart
        return getMatchDetailsScoreDart()
      } else {

        if (match_details && match_details !== undefined && match_details !== null) {


          // ogólne
          let tmp = JSON.parse(match_details).score
          return (
            <Segment>
              <div style={{ position: "relative", marginBottom: 10 }}>
                {
                  tmp.map((el, i) => <p key={i} > {i + 1}  {<Input type="number" value={el[data.match_member1_id]} onChange={(e, d) => updateValueJsonScore(i, data.match_member1_id, d.value)} style={{ padding: 2, width: 110, borderRadius: 5 }} />} : {<Input type="number" value={el[data.match_member2_id]} onChange={(e, d) => updateValueJsonScore(i, data.match_member2_id, d.value)} style={{ padding: 2, width: 110, borderRadius: 5 }} />} {(i + 1) === tmp.length ? <Button type='button' style={{ position: "absolute", bottom: 5 }} size='mini' onClick={() => removeRowJson(i)}>USUŃ</Button> : ""}   </p>)
                }
              </div>
              <Button type='button' color='brown' style={{ marginBottom: 10 }} size='mini' onClick={() => addRowJson(data)}>Dodaj kolejną pozycję</Button>
              <div>

                <Button type='button' color='blue' style={{ marginBottom: 10 }} size='mini' onClick={() => setValueJsonScore()}>Zapisz wyniki szczegółowe</Button>
                <Button type='button' style={{ marginBottom: 10 }} size='mini' onClick={() => delValueJsonScore()}>Kasuj wyniki szczegółowe</Button>
              </div>
              <Message size='mini'>Jeżeli pojedynek jeszcze trwa to wpisz same wyniki szczegółowe bez wprowadzania wyniku pojedynku.</Message>

            </Segment>
          )


        } else {
          return <Button type='button' fluid color='blue' style={{ marginBottom: 10 }} size='mini' onClick={() => createStrucValueJsonScore(data)}>Chcę dodać wyniki szczegółowe dla tego pojedynku</Button>
        }
      }

    } catch (e) {
      console.log(e)
      return <Message negative>
        <MessageHeader>Bład</MessageHeader>
        <p>Problem z parsowaniem statystyk</p>
      </Message>
    }
  }

  //ogolne start

  function createStrucValueJsonScore(data) {
    try {
      if(tournament_free===0){
        addToast(getInfoFreeBlock(), { appearance: 'warning' });
        return
      }
      let countRow = 1
      
      if(systemId===2 || systemId===3 || systemId===6 ||  systemId===8 ||  systemId===17){
        countRow = Number(data.member1_score) + Number(data.member2_score)
      }else{
        countRow = 1
      }

      if (countRow === 0) {
        countRow = 1
      }

      // tworzenie sktruktury
      /*
{"score":[{"12591":1,"12593":32,"set":1},{"12591":12,"12593":33,"set":2},{"12591":1,"12593":3,"set":3},{"12591":1,"12593":3,"set":4},{"12591":1,"12593":3,"set":5}]}
      */
      let arrS = []

      for (var i = 0; i < countRow; i++) {
        arrS.push({ [data.match_member1_id]: 0, [data.match_member2_id]: 0, "set": i + 1 })
      }

      setopenModalScore({ ...openModalScore, match_details: JSON.stringify({ score: arrS }) })

    } catch (e) {
      console.log(e)
      return "Bład"
    }
  }

  function updateValueJsonScore(nrRow, nrMember, value) {
    try {
      if (openModalScore.match_details !== undefined && openModalScore.match_details !== null) {
        let tmp = openModalScore
        let tmp1 = JSON.parse(tmp.match_details).score
        tmp1[nrRow][nrMember] = Number(value)
        setopenModalScore({ ...openModalScore, match_details: JSON.stringify({ score: tmp1 }) })

        /*
        let currObj = {...JSON.parse(match_details) }
    set(currObj, json, valf)
    setmatch_details(JSON.stringify(currObj))
    setopenModalScore({ ...openModalScore, match_details: JSON.stringify(currObj)})
        */
      }
    } catch (e) {
      console.log(e)
      return "Bład"
    }
  }

  function delValueJsonScore() {
    try {
      ConnectWS("/generator/setMatchDetailsNoToken", authContext.token, { match_id: openModalScoreTMP.subtournament_match_id, member1_id: openModalScoreTMP.match_member1_id, member2_id: openModalScoreTMP.match_member2_id, match_details: null, removeScore: false }, function (response, logout) {
        if (logout === 1) {

        } else if (response && response.status && Number(response.status) === 200) {
          setmatch_details(null)
          setopenModalScore({ ...openModalScore, match_details: null })
          addToast(response.data.message, { appearance: 'success' });
          authContext.changeRefreshView(authContext.refreshView + 1)
        } else {

          addToast(response, { appearance: 'error' });
        }

      });

    } catch (e) {
      console.log(e)
      return "Bład"
    }
  }
  //ogolne koniec

  //dart start
  function getMatchDetailsScoreDart() {
    return <div>
      <Grid stackable divided style={{ marginBottom: 10 }}>
        <Grid.Row >
          <Grid.Column width={16} textAlign="center" >
            {match_details ?
              <Table compact striped unstackable>
                <Table.Header>
                  <Table.Row>
                    <TableHeaderCell colspan="3" style={{ fontSize: 12 }}>{isnoscore ? "Wprowadź wcześniej wynik pojedynku!" : "Wprowadź statystyki ręcznie"}</TableHeaderCell>

                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {JSON.parse(match_details).score_dart.stats.score_avg1D ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_avg1D[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_avg1D." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>SCORE AVG - 1 DART</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_avg1D[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_avg1D." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_avg3D ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_avg3D[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_avg3D." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>SCORE AVG - 3 DARTS</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_avg3D[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_avg3D." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_first1D9 ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_first1D9[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_first1D9." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>FIRST 9 - 1 DART</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_first1D9[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_first1D9." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_first3D9 ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_first3D9[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_first3D9." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>FIRST 9 - 3 DARTS</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_first3D9[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_first3D9." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_60p ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_60p[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_60p." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>60+</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_60p[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_60p." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_80p ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_80p[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_80p." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>80+</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_80p[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_80p." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_100p ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_100p[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_100p." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>100+</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_100p[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_100p." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_120p ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_120p[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_120p." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>120+</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_120p[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_120p." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_140p ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_140p[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_140p." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>140+</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_140p[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_140p." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_170p ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_170p[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_170p." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>170+</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_170p[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_170p." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_180 ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_180[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_180." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>180</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_180[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_180." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_hight_finish ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_hight_finish[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_hight_finish." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>HIGHT FINISH</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_hight_finish[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_hight_finish." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                  {JSON.parse(match_details).score_dart.stats.score_best_leg ?
                    <Table.Row>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_best_leg[openModalScoreTMP.match_member1_id]} onChange={(e, d) => updateJson("score_dart.stats.score_best_leg." + openModalScoreTMP.match_member1_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                      <Table.Cell singleLine textAlign='center'>BEST LEG</Table.Cell>
                      <Table.Cell textAlign='center'><Input disabled={isnoscore} type="number" value={JSON.parse(match_details).score_dart.stats.score_best_leg[openModalScoreTMP.match_member2_id]} onChange={(e, d) => updateJson("score_dart.stats.score_best_leg." + openModalScoreTMP.match_member2_id, d.value === "" ? null : Number(d.value))} step="0.01" /></Table.Cell>
                    </Table.Row>
                    : ""}
                </Table.Body>
              </Table>

              : ""}

          </Grid.Column>

        </Grid.Row>
      </Grid>
    </div>

  }


  function GetPlayDuel() {
    return <>
      {(openModalScoreTMP.member2_score === null || openModalScoreTMP.member1_score === null) ? <>
        <Divider horizontal>LUB</Divider>
        <Grid stackable divided style={{ marginBottom: 10 }}>
          <Grid.Row stretched>
            <Grid.Column width={8} textAlign="center" >
              <Segment> <Button type='button' color='blue' onClick={() => window.open(getLinkDuel(), '_self')} style={{ width: "100%", height: "100%" }}>
                ROZPOCZNIJ MECZ TUTAJ
              </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column width={8} textAlign="center" >
              <Segment textAlign='center'>
                <div style={{ fontSize: 13, marginBottom: 10 }}> Rozpocznij mecz na innym urządzeniu, zeskanuj qrcode lub kliknij go w celu skopiowania linku</div>

                <QRCodeSVG value={getLinkDuel()} className='cursorPointer' onClick={(e, i) => navigator.clipboard.writeText(getLinkDuel())} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </> : openModalScoreTMP.match_details ? <> <Message size='tiny'>

        <p>
          Skasuj wynik, jeżeli chcesz zagrać ponownie lub zmienić wyniki...
        </p>
      </Message>
        <Message size='tiny'>
          <MessageHeader>Informacja</MessageHeader>
          <p>
            Po zatwierdzeniu wprowadzonego wyniku pojedynku nie można go edytować z uwagi na statystyki, należy skasować wynik i wprowadzić nowe dane.
          </p>
        </Message>
      </> : ""}



    </>
  }

  //dart stop



  function updateJson(json, valf) {
    let currObj = { ...JSON.parse(match_details) }
    set(currObj, json, valf)
    setmatch_details(JSON.stringify(currObj))
    setopenModalScore({ ...openModalScore, match_details: JSON.stringify(currObj) })
  }


  function getLinkDuel() {

    let jsonData = {
      member1_name: openModalScoreTMP.member1_name,
      member2_name: openModalScoreTMP.member2_name,
      subtournament_match_id: openModalScoreTMP.subtournament_match_id,
      match_member1_id: openModalScoreTMP.match_member1_id,
      match_member2_id: openModalScoreTMP.match_member2_id,
      t: 1,
    }

    let jwt = JWT.encode(jsonData, getKeyJwtTG());
    let lnk = process.env.REACT_APP_PUBLIC_URL + "/duel/dart/" + jwt
    // console.log(lnk)
    return lnk
  }



  const [matchplacetmpStatus, setmatchplacetmpStatus] = useState({ info: "", status: 0 })


  const [matchplaceJson, setmatchplaceJson] = useState({place_id:null,place_desc:null,match_dt:null})
  const [matchplaceJsonSave, setmatchplaceJsonSave] = useState({place_id:null,place_desc:null,match_dt:null})

  function getMatchPlace(openModalScore) {
    return <Segment><Grid>
      <Grid.Row verticalAlign='middle'>

        <Grid.Column width={16} textAlign='left'>

          <Form  >

          <Icon style={{position:"absolute",right:0}}  className='cursorPointer' title="Skasuj miejsce pojedynku" name="trash" onClick={() => delMatchPlace()} />
         
            <Form.Group >
              <Form.Select
              width={4}
                fluid
                label={matchplaceJson && matchplaceJson.place_id ? 'Aktualny nr miejsca to: '+Number(matchplaceJson.place_id) : 'Nr miejsca pojedynku'}
                options={matchplacetmpS2(tournamentJsonRoot ? tournamentJsonRoot.playing_match_places : [],tournamentJsonRoot ? tournamentJsonRoot.match_places_amount : 0)}
                placeholder='Nr miejsca pojedynku'
                value={matchplaceJson && matchplaceJson.place_id ? Number(matchplaceJson.place_id) : 0}
                onChange={(e, d) => {
                  setmatchplacetmpStatus({ info: "", status: 0 })
                  setmatchplaceJson({...matchplaceJson,place_id:d.value})
                }}
              />
              <Form.Input width={4} value={matchplaceJson && matchplaceJson.place_desc ? (matchplaceJson.place_desc) : null}  onChange={(e, d) => {
                  setmatchplaceJson({...matchplaceJson,place_desc:d.value})
                }} fluid label='Opis miejsca pojedynku' placeholder='Opis miejsca pojedynku' />

              <Form.Field width={8}>
                  <label>Data i godzina</label>
                 
                  <InputCalendarAndTime form={matchplaceJson} setForm={setmatchplaceJson} inputValue={"match_dt"} returnString={true}/> 
                 
                 
                </Form.Field>
             
            </Form.Group>
            <Button type='button' onClick={()=>saveMatchPlace()} size='mini' color='blue' fluid>Zapisz informację</Button>
          </Form>
        
        </Grid.Column>

   
      </Grid.Row>
    </Grid>
      {matchplacetmpStatus.status > 0 ? <Message positive={matchplacetmpStatus.status === 1 ? true : false} negative={matchplacetmpStatus.status === 2 ? true : false}>
        {matchplacetmpStatus.info}
      </Message> : ""}
    </Segment>
  }

  function saveMatchPlace(){
    if(tournament_free===0){
      addToast(getInfoFreeBlock(), { appearance: 'warning' });
      return
    }

    setmatchplacetmpStatus({ info: "", status: 0 })
    ConnectWS("/generator/setMatchPlace", authContext.token, {subtournament_id: subtournament_id ? subtournament_id : subtournamentJsonRoot.subtournament_id, match_id: openModalScoreTMP.subtournament_match_id, member1_id: openModalScoreTMP.match_member1_id, member2_id: openModalScoreTMP.match_member2_id, match_place: matchplaceJson }, function (response, logout) {
      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        setmatchplacetmpStatus({ info: response.data.message, status: 1 })
        authContext.changeRefreshView(authContext.refreshView + 1)
        setmatchplaceJsonSave({...matchplaceJson})
      } else {
        setmatchplacetmpStatus({ info: response, status: 2 })
      }

    });

  }

  function delMatchPlace() {
    if(tournament_free===0){
      addToast(getInfoFreeBlock(), { appearance: 'warning' });
      return
    }
    setmatchplacetmpStatus({ info: "", status: 0 })
    ConnectWS("/generator/setMatchPlace", authContext.token, {subtournament_id:subtournament_id ? subtournament_id : subtournamentJsonRoot.subtournament_id, match_id: openModalScoreTMP.subtournament_match_id, member1_id: openModalScoreTMP.match_member1_id, member2_id: openModalScoreTMP.match_member2_id, match_place: null }, function (response, logout) {
      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        setmatchplaceJson({place_id:null,place_desc:null,match_dt:null})
        setmatchplaceJsonSave({place_id:null,place_desc:null,match_dt:null})
        setmatchplacetmpStatus({ info: response.data.message, status: 1 })
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {
        setmatchplacetmpStatus({ info: response, status: 2 })
      }

    });

  }


  return (
    <>
      {systemId === 5 ? GetPlayDuel() : ""}

      {typeSubTournament && typeSubTournament === "LEAGUE" ? "" :
        <Accordion style={{ marginBottom: 5 }}>
          <AccordionTitle
            active={activeIndex3 === 1}
            index={1}
            style={{ textAlign: "left", marginBottom: 10 }}
            onClick={() => setactiveIndex3(activeIndex3 === 0 ? 1 : 0)}
          >
            <Icon name='dropdown' />
            Informacje o pojedynku
          </AccordionTitle>
          <AccordionContent active={activeIndex3 === 1}>
            {getMatchPlace(openModalScore)}
          </AccordionContent>
        </Accordion>
      }

    {hiddenScoreDetails===true ? "" :
      <Accordion style={{ marginBottom: 5 }}>
        <AccordionTitle
          active={activeIndex2 === 1}
          index={1}
          style={{ textAlign: "left", marginBottom: 10 }}
          onClick={() => setactiveIndex2(activeIndex2 === 0 ? 1 : 0)}
        >
          <Icon name='dropdown' />
          Wyniki szczegółowe
        </AccordionTitle>
        <AccordionContent active={activeIndex2 === 1}>
          {getMatchDetailsScore(openModalScoreTMP)}
        </AccordionContent>
      </Accordion>
      }
    </>
  )
}

export default GetScoreDetails;



export function getJsonDartDef(mem1, mem2) {
  return {
    score_dart: {
      stats: {
        score_avg1D: {
          [mem1]: null,
          [mem2]: null
        },
        score_avg3D: {
          [mem1]: null,
          [mem2]: null
        },
        score_first9: {
          [mem1]: null,
          [mem2]: null
        },
        score_60p: {
          [mem1]: null,
          [mem2]: null
        },
        score_80p: {
          [mem1]: null,
          [mem2]: null
        },
        score_100p: {
          [mem1]: null,
          [mem2]: null
        },
        score_120p: {
          [mem1]: null,
          [mem2]: null
        },
        score_140p: {
          [mem1]: null,
          [mem2]: null
        },
        score_170p: {
          [mem1]: null,
          [mem2]: null
        },
        score_180: {
          [mem1]: null,
          [mem2]: null
        },
        score_hight_finish: {
          [mem1]: null,
          [mem2]: null
        },
        score_best_leg: {
          [mem1]: null,
          [mem2]: null
        },
      },
      game: {
      }
    }
  }
}


