import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { Container, Grid, Header } from 'semantic-ui-react';
import { Markup } from 'interweave';
import MyImageLazy from './MyImageLazy';
import { Trans } from 'react-i18next';

function CardInfoDedicatedPage(props) {
    const [products, setProducts] = useState([]);


    useEffect(() => {


        let tmp = []
        tmp.push({description:"<h4><b>Optymalizacja turniejów DART z wykorzystaniem aplikacji Turniej-Generator.pl wprowadzenie do zaawansowanych statystyk i efektywnego zarządzania</b></h4> <br/>DART, znany jako popularna gra w rzucanie lotkami do tarczy, stale ewoluuje zarówno jako sport, jak i rozrywka. Jednak wraz z rozwojem tego sportu pojawiają się również wyzwania związane z organizacją turniejów i monitorowaniem statystyk. Na szczęście, w erze cyfrowej, narzędzia takie jak aplikacja Turniej-Generator.pl stają się kluczowymi pomocnikami dla organizatorów, umożliwiając zarówno efektywne zarządzanie turniejami, jak i dostęp do zaawansowanych statystyk. Powstał specjalny panel do rozgrywek 1VS1 ONLINE",id:1000,img_file:process.env.REACT_APP_PUBLIC_URL + "/img/dart/7.png",name:<Trans i18nKey="stronaStartowa.dart1" />,url:"https://turniej-generator.pl/info/dart"})
        tmp.push(...props.dedicatedWebsites)
        setProducts(tmp)

        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps



    const productTemplate = (row) => {
        return (
            <div  >
                <Container style={{ marginBottom: 50, marginTop: 40 }}>
                <Grid stackable verticalAlign='middle' className='bgCenterPanel2' >
                    <Grid.Row textAlign='center'>

                        <Grid.Column verticalAlign='middle' width={10} >
                            <Header as='h1' textAlign='center' style={{ marginBottom: 50, marginTop: 20 }}>
                                {row.name}
                            </Header>

                            <Markup content={row.description} />
                            <br /> <br />
                            Dedykowana strona: <a href={row.url} className='linktl' target={"_blank"} rel="noopener noreferrer">{row.url}</a>

                        </Grid.Column>
                        <Grid.Column verticalAlign='middle' width={6} >
                            <MyImageLazy maxHeight={500} openView={true} src={row.img_file} alt={"img"+row.img_file} />
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
                </Container>
            </div>
        );
    };

    return (

        <div  >
            <Carousel value={products} circular autoplayInterval={3000} numScroll={1} numVisible={1} itemTemplate={productTemplate} />

        </div>

    )
}

export default CardInfoDedicatedPage;