import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Form, FormInput, Grid, Header, Icon, Image, Input, Label, Modal, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, } from 'semantic-ui-react'

import LogoTeamLigaGenerator from '../../../../assets/tglogov6_small.png';
import { ConnectWS } from '../../../../ConnectWS';
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import { getLimitUsersPackage } from '../../../../myfunctions';


function MyTournamentAddUsersCFG({ subTournamentJson, tournamentJson }) {
  const [openModal, setOpenModal] = useState(false);
  const authContext = useContext(AuthContext);
  const { addToast } = useToasts();
  const [jsonCFG, setjsonCFG] = useState(null);
  const [inputValue, setinputValue] = useState(null);

  const [from, setFrom] = useState({ members_limit: 0, members_reserve_limit: 0 });

  useEffect(() => {
    if (subTournamentJson.members_additional_info_conf_json !== null && checkTypeST()) {
      setjsonCFG(subTournamentJson.members_additional_info_conf_json)
    } else if (subTournamentJson.member_type === "USER") {
      setjsonCFG([{ name: "Telefon", value: null, patternInput: "", type: "number", enable: false, required: false },
      { name: "Płeć", value: null, patternInput: "", type: "select", enable: false, required: false,options:[{key:"Mężczyzna",text:"Mężczyzna",value:"Mężczyzna"},{key:"Kobieta",text:"Kobieta",value:"Kobieta"}] },
      { name: "Rok urodzenia (np: 1995)", value: null, patternInput: "", type: "number", enable: false, required: false },
      { name: "Data urodzenia (np: 1995-05-21)", value: null, patternInput: "", type: "text", enable: false, required: false },
      { name: "Miejscowość", value: null, patternInput: "", type: "text", enable: false, required: false },
      { name: "Informacje dodatkowe", value: null, patternInput: "", type: "text", enable: false, required: false }
      ])
    } else {
      setjsonCFG([{ name: "Telefon", value: null, patternInput: "", type: "number", enable: false, required: false },
      { name: "Miejscowość", value: null, patternInput: "", type: "text", enable: false, required: false },
      { name: "Informacje dodatkowe", value: null, patternInput: "", type: "text", enable: false, required: false },
      { name: "Ilość zawodników", value: null, patternInput: "", type: "text", enable: false, required: false }
      ])
    }
    setFrom({ members_limit: subTournamentJson.members_limit, members_reserve_limit: subTournamentJson.members_reserve_limit })
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [subTournamentJson])// eslint-disable-line react-hooks/exhaustive-deps

  function checkTypeST(){
    // jeżeli jest coś w bazie wówczas zostanie zastapione jeżeli typ się nie zgadza
    try{
      if(subTournamentJson.member_type === "TEAM" && JSON.stringify(subTournamentJson.members_additional_info_conf_json).includes("Rok urodzenia")){
        return false
      }else if(subTournamentJson.member_type === "USER" && JSON.stringify(subTournamentJson.members_additional_info_conf_json).includes("Ilość zawodników")){
        return false
      }
      return true
    }catch(e){
      return true
    }
  }


  function updateJsonData(value, i, co) {
    let jsonTmp = JSON.parse(JSON.stringify(jsonCFG))
    jsonTmp[i][co] = value
    setjsonCFG(jsonTmp)
  }

  function getLimitMembers() {
    try {
      let limitm = getLimitUsersPackage(tournamentJson.tournament_free === 0 && subTournamentJson.type === "LEAGUE" ? -1 : tournamentJson.tournament_free)
      let currMember = Number(from.members_limit) + Number(from.members_reserve_limit) + 0
      if (currMember > limitm) {
        addToast("Popraw parametry, ponieważ masz ustawione większe niż limit: " + limitm, { appearance: 'warning' });
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }

  function saveData() {

    if (getLimitMembers()) {

    } else {

      let members_limit = Number(from.members_limit)
      let members_reserve_limit = Number(from.members_reserve_limit)

      ConnectWS("/generator/editSubtournament", authContext.token, { tournament_id: tournamentJson.tournament_id, subtournament_id: subTournamentJson.subtournament_id, parent_subtournament_id: subTournamentJson.parent_subtournament_id, subtournament_type: subTournamentJson.type, member_type: subTournamentJson.member_type, subtournament_name: subTournamentJson.subtournament_name, members_limit: members_limit, members_reserve_limit: members_reserve_limit, members_additional_info_conf_json: jsonCFG }, function (response, logout) {

        if (logout === 1) {


        } else if (response && response.status && Number(response.status) === 200) {

          addToast(response.data.message, { appearance: 'success' });
          authContext.changeRefreshView(authContext.refreshView + 1)
          setOpenModal(false)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //console.log(response);
        }



      });
    }




  }


  function addNewParam() {
    try {
      let jsonTmp = JSON.parse(JSON.stringify(jsonCFG))
      let jsonTmp2 = { ...jsonTmp[0], removeOn: 1 }
      jsonTmp2.name = inputValue
      jsonTmp2.type = "text"
      jsonTmp.push(jsonTmp2)
      setjsonCFG(jsonTmp)

    } catch (e) {

    }
  }

  function removeRow(i) {
    let tmp = [...jsonCFG]
    tmp.splice(i, 1)
    setjsonCFG(tmp)
  }

  return (
    <>
      <Button size='mini' onClick={() => setOpenModal(true)} fluid style={{ marginTop: 10, marginBottom: 10 }}><Icon name="setting" />Konfiguracja formularza</Button>
      <Modal
        open={openModal}

      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h4' textAlign='center'>
                  <Icon name='setting' />
                  <Header.Content>Konfiguracja formularza</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content style={{ backgroundColor: "#f5f5f5" }}>
          {openModal ? <>
            <Label style={{ marginBottom: 10 }}>Konfiguracja limitów</Label>
            <Form size='tiny' >
              <Form.Group  >
                <FormInput
                  width={10}
                  fluid
                  control={Input}
                  value={from.members_limit}
                  type='number'
                  min="0"
                  max="500"
                  onChange={(event, data) => setFrom({ ...from, members_limit: data.value })}
                  label='Limit uczestników zapisy online: 0 - brak limitu'
                  placeholder='Limit uczestników '
                />
                <FormInput
                  width={10}
                  fluid

                  control={Input}
                  value={from.members_reserve_limit}
                  type='number'
                  min="0"
                  max="500"
                  onChange={(event, data) => setFrom({ ...from, members_reserve_limit: data.value })}
                  label='Lista rezerwowa: 0 - wyłączona'
                  placeholder='Lista rezerwowa: 0 - wyłączona'
                />

              </Form.Group>
            </Form>
            <Label>Dodatkowe pola w formularzu</Label>
            <Table singleLine compact size='mini' >
              <TableHeader>
                <TableRow>

                  <TableHeaderCell textAlign='center'>Nazwa pola</TableHeaderCell>
                  <TableHeaderCell textAlign='center' singleLine>Pole widoczne</TableHeaderCell>
                  <TableHeaderCell textAlign='center' singleLine>Pole wymagane</TableHeaderCell>

                </TableRow>
              </TableHeader>

              <TableBody>
                {jsonCFG ?
                  jsonCFG.map((row, i) => <TableRow key={i}>
                    <TableCell textAlign='center'>{row.name} {row.removeOn ? <Icon className='cursorPointer' onClick={() => removeRow(i)} name="trash" /> : ""}</TableCell>
                    <TableCell textAlign='center'>  <Checkbox toggle onChange={(e, d) => updateJsonData(d.checked, i, "enable")} checked={row.enable} /></TableCell>
                    <TableCell textAlign='center'> <Checkbox toggle disabled={row.enable === false} onChange={(e, d) => updateJsonData(d.checked, i, "required")} checked={row.required} /></TableCell>
                  </TableRow>)
                  : ""}
                {jsonCFG ?
                  <TableRow >
                    <TableCell colSpan={3} textAlign='center'><Input label={"Nazwa własna"} size='mini' value={inputValue} onChange={(e, d) => setinputValue(d.value)} placeholder='Nowe pole' /><Button style={{ marginLeft: 10 }} onClick={() => addNewParam()} size='mini'>DODAJ</Button></TableCell>
                  </TableRow>
                  : ""}
              </TableBody>


            </Table>
          </> : ""}
        </Modal.Content>
        <Modal.Actions >
          <Button floated='left' color='black' onClick={() => setOpenModal(false)}>
            WYJDŹ
          </Button>
          <Button color='green' labelPosition='right' icon='save' onClick={() => saveData()} content="ZAPISZ" />


        </Modal.Actions>
      </Modal>
    </>
  )
}

export default MyTournamentAddUsersCFG;