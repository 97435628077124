import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Divider, Form, Input, Button, Grid, Checkbox, Message } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import useAuth from '../../../../hooks/useAuth';
import { ConnectWS } from '../../../../ConnectWS'
import { confirmAlert } from 'react-confirm-alert';
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import { changeInitialScale, checkiscompanypay } from '../../../../myfunctions';

function ChangeMyData(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars

  const isDemo = useCheckUserIsDemo()


  const [iscompanypay, setiscompanypay] = useState(null);

  const [form, setForm] = useState({
    user_name: '',
    user_surname: '',
  });

  const [formIsCompany, setFormIsCompany] = useState({
    user_name: '',
    user_surname: '',
    user_tel: "",
    company: 0,
    nip: null,
    company_name: null,
    street: null,
    postal_code: null,
    place: null,
    postal: "Ukryte",
    buyer_is_payer: 1,
    payer_nip: null,
    payer_company_name: null,
    payer_street: null,
    payer_postal_code: null,
    payer_place: null,
    payer_postal: "Ukryte",
  });

  function getAccountData() {
    ConnectWS("login/getAccountData", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
        authContext.changeShowOther(false)
      } else if (response && response.status && Number(response.status) === 200) {
        setForm({ ...form, user_name: response.data.user_name, user_surname: response.data.user_surname })
        let def = { ...formIsCompany }
        def = { ...response.data }
        setFormIsCompany({ ...def, buyer_is_payer: response.data.buyer_is_payer === 0 ? 0 : 1, user_name: response.data.user_name, user_surname: response.data.user_surname, company: response.data.company ? response.data.company : 0,postal:"Ukryte",payer_postal:"Ukryte" })


      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }



    });
  }


  useEffect(() => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: ChangeMyData")
    }
    setiscompanypay(checkiscompanypay())
    changeInitialScale(0.5)
    getAccountData()

    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  function validatenip(nip) {
    var nipWithoutDashes = nip.replace(/-/g, "");
    var reg = /^[0-9]{10}$/;
    if (reg.test(nipWithoutDashes) === false) {
      return false;
    }
    else {
      var digits = ("" + nipWithoutDashes).split("");
      var checksum = (6 * parseInt(digits[0]) + 5 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 2 * parseInt(digits[3]) + 3 * parseInt(digits[4]) + 4 * parseInt(digits[5]) + 5 * parseInt(digits[6]) + 6 * parseInt(digits[7]) + 7 * parseInt(digits[8])) % 11;

      return (parseInt(digits[9]) === checksum);
    }
  }


  const submitModal = e => {
    e.preventDefault();

    if (formIsCompany.nip && formIsCompany.nip > 1) {
      if (!validatenip(formIsCompany.nip)) {
        addToast('Błędnie wprowadzony NIP', { appearance: 'warning' });
        return
      }
    }
    if (formIsCompany.payer_nip && formIsCompany.payer_nip > 1) {
      if (!validatenip(formIsCompany.payer_nip)) {
        addToast('Błędnie wprowadzony NIP dla płatnika', { appearance: 'warning' });
        return
      }
    }

    if (!formIsCompany.buyer_is_payer) {
      /*
      if(formIsCompany.payer_nip===formIsCompany.nip){
        addToast('Dlaczego skoro płatnikiem jest ktoś inny, jest wprowadzony ten sam NIP?', { appearance: 'warning' });
        return
      }
        */
      if (Number(formIsCompany.nip) < 5) {
        addToast('Hmm brakuje wprowadzonego NIP-u dla płatnika', { appearance: 'warning' });
        return
      }
    }



    setloading(true)

    ConnectWS("login/editAccountData", authContext.token, iscompanypay ? { ...formIsCompany } : { ...form }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {


        if (props.paymentView && props.setstep) {
          props.setstep(1)
        } else {
          addToast(response.data.message, { appearance: 'success' });
        }

      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloading(false)


    });


  }

  function delAccount() {
    confirmAlert({
      title: 'Czy chcesz usunąć swoje konto',
      message: 'Proszę potwierdzić',
      buttons: [
        {
          label: 'Tak',
          onClick: () => delAccountA()
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function delAccountA() {
      ConnectWS("login/delAccountAndPersonalData", authContext.token, {}, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);
          authContext.changeShowOther(false)
        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          setTimeout(() => {
            addToast('Zaraz nastapi wylogowanie', { appearance: 'success' });
          }, 1000);
          setTimeout(() => {
            setAuth(false);
          }, 3000);
        } else {
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }

        }
      });
    }
  }

  return (
    <div style={{ backgroundColor: "#f5f5f5", padding: 10 }}> {props.paymentView ? "" : <> <Header as='h5' textAlign='center' block>
      <Icon name='edit' />
      <Header.Content>Moje dane</Header.Content>
    </Header>
      <Divider />
    </>}

      {iscompanypay ? <Form onSubmit={submitModal} size='tiny'>
        <Header block>
          Konto w aplikacji TG
        </Header>
        <Form.Field
          control={Input}
          required
          label='Imię'
          placeholder='Imię'
          value={formIsCompany.user_name}
          onChange={(e, data) => setFormIsCompany({ ...formIsCompany, user_name: data.value })}
        />
        <Form.Field
          control={Input}
          required
          label='Nazwisko'
          placeholder='Nazwisko'
          value={formIsCompany.user_surname}
          onChange={(e, data) => setFormIsCompany({ ...formIsCompany, user_surname: data.value })}
        />
        <Form.Field
          control={Input}
          label='Telefon'
          type='number'
          placeholder='Telefon'
          value={formIsCompany.user_tel}
          onChange={(e, data) => setFormIsCompany({ ...formIsCompany, user_tel: data.value })}
        />
        <Divider style={{ marginTop: 40, marginBottom: 40 }} horizontal>{formIsCompany.company === 1 ? "DANE DO FAKTURY" : "DANE DO PŁATNOŚCI (FAKTURA IMIENNA)"}</Divider>
        {formIsCompany.company === 1 ?
          <Message info style={{ marginBottom: 20 }}>Jeśli zamiast płatności online potrzebują Państwo fakturę <b>proforma</b>, prosimy o kontakt. Udostępnimy ją w aplikacji, skąd będzie można ją pobrać i opłacić.</Message>
          : ""}
        <Form.Group inline>
          <Form.Radio
            label='Osoba prywatna'
            value={false}
            checked={formIsCompany.company === 1 ? false : true}
            onChange={(e, data) => setFormIsCompany({ ...formIsCompany, company: 0 })}
          />
          <Form.Radio
            label='Firma'
            value={true}
            checked={formIsCompany.company === 1 ? true : false}
            onChange={(e, data) => setFormIsCompany({ ...formIsCompany, company: 1 })}
          />

        </Form.Group>
        {formIsCompany.company ? <>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header block>
                  Nabywca
                </Header>
                <Form.Field
                  control={Input}
                  required
                  label='Nazwa firmy'
                  placeholder='Nazwa firmy'
                  value={formIsCompany.company_name}
                  onChange={(e, data) => setFormIsCompany({ ...formIsCompany, company_name: data.value })}
                />
                <Form.Field
                  control={Input}
                  required
                  label='NIP'
                  type="number"
                  placeholder='NIP'
                  value={formIsCompany.nip}
                  onChange={(e, data) => setFormIsCompany({ ...formIsCompany, nip: data.value })}
                />
                <Form.Field
                  control={Input}
                  required
                  label='Ulica i numer'
                  placeholder='Ulica i numer'
                  value={formIsCompany.street}
                  onChange={(e, data) => setFormIsCompany({ ...formIsCompany, street: data.value })}
                />

                <Form.Group >
                  <Form.Field
                    control={Input}
                    required
                    width={8}
                    label='Miejscowość'
                    placeholder='Miejscowość'
                    value={formIsCompany.place}
                    onChange={(e, data) => setFormIsCompany({ ...formIsCompany, place: data.value })}
                  />
                  <Form.Field
                    control={Input}
                    required
                    width={8}
                    pattern="^\d{2}-\d{3}$"
                    label='Kod pocztowy (xx-xxx)'
                    placeholder='Kod pocztowy'
                    value={formIsCompany.postal_code}
                    onChange={(e, data) => setFormIsCompany({ ...formIsCompany, postal_code: data.value })}
                  />


                </Form.Group>
              </Grid.Column>
              <Grid.Column width={8}>
                <Header block>
                  <Checkbox toggle label={formIsCompany.buyer_is_payer ? "Nabywca jest płatnikiem" : "Płatnik"} checked={formIsCompany.buyer_is_payer ? true : false} onChange={(e, data) => setFormIsCompany({ ...formIsCompany, buyer_is_payer: data.checked ? 1 : 0 })} />
                </Header>
                <Form.Field
                  control={Input}
                  required
                  label='Nazwa firmy'
                  placeholder='Nazwa firmy'
                  value={formIsCompany.payer_company_name}
                  disabled={formIsCompany.buyer_is_payer}
                  onChange={(e, data) => setFormIsCompany({ ...formIsCompany, payer_company_name: data.value })}
                />
                <Form.Field
                  control={Input}
                  required
                  label='NIP'
                  type="number"
                  placeholder='NIP'
                  disabled={formIsCompany.buyer_is_payer}
                  value={formIsCompany.payer_nip}
                  onChange={(e, data) => setFormIsCompany({ ...formIsCompany, payer_nip: data.value })}
                />
                <Form.Field
                  control={Input}
                  required
                  label='Ulica i numer'
                  placeholder='Ulica i numer'
                  disabled={formIsCompany.buyer_is_payer}
                  value={formIsCompany.payer_street}
                  onChange={(e, data) => setFormIsCompany({ ...formIsCompany, payer_street: data.value })}
                />

                <Form.Group >
                  <Form.Field
                    control={Input}
                    required
                    width={8}
                    label='Miejscowość'
                    placeholder='Miejscowość'
                    disabled={formIsCompany.buyer_is_payer}
                    value={formIsCompany.payer_place}
                    onChange={(e, data) => setFormIsCompany({ ...formIsCompany, payer_place: data.value })}
                  />
                  <Form.Field
                    control={Input}
                    required
                    width={8}
                    pattern="^\d{2}-\d{3}$"
                    label='Kod pocztowy (xx-xxx)'
                    disabled={formIsCompany.buyer_is_payer}
                    placeholder='Kod pocztowy'
                    value={formIsCompany.payer_postal_code}
                    onChange={(e, data) => setFormIsCompany({ ...formIsCompany, payer_postal_code: data.value })}
                  />

                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </> : <>
          <Form.Field
            control={Input}
            required
            label='Ulica i numer'
            placeholder='Ulica i numer'
            value={formIsCompany.street}
            onChange={(e, data) => setFormIsCompany({ ...formIsCompany, street: data.value })}
          />

          <Form.Group >
            <Form.Field
              control={Input}
              required
              width={8}
              label='Miejscowość'
              placeholder='Miejscowość'
              value={formIsCompany.place}
              onChange={(e, data) => setFormIsCompany({ ...formIsCompany, place: data.value })}
            />
            <Form.Field
              control={Input}
              required
              width={8}
              pattern="^\d{2}-\d{3}$"
              label='Kod pocztowy (xx-xxx)'
              placeholder='Kod pocztowy'
              value={formIsCompany.postal_code}
              onChange={(e, data) => setFormIsCompany({ ...formIsCompany, postal_code: data.value })}
            />

          </Form.Group>
        </>}





        <Button size='tiny' color='blue' style={{ marginTop: 20 }} fluid loading={loading ? true : false} disabled={loading ? true : false || isDemo ? true : false} >{props.paymentView ? "PRZEJDŹ DALEJ" : "AKTUALIZUJ DANE"}</Button>
      </Form>
        : <Form onSubmit={submitModal} size='tiny'>


          <Form.Field
            control={Input}
            required
            label='Imię'
            placeholder='Imię'
            value={form.user_name}
            onChange={(e, data) => setForm({ ...form, user_name: data.value })}
          />
          <Form.Field
            control={Input}
            required
            label='Nazwisko'
            placeholder='Nazwisko'
            value={form.user_surname}
            onChange={(e, data) => setForm({ ...form, user_surname: data.value })}
          />


          <Button size='tiny' color='google plus' fluid loading={loading ? true : false} disabled={loading ? true : false || isDemo ? true : false} > {props.paymentView ? "PRZEJDŹ DALEJ" : "AKTUALIZUJ DANE"}</Button>
        </Form>}

      {props.paymentView ? "" : <>
        <Divider />
        <Button icon labelPosition='left' size='tiny' disabled={isDemo} onClick={() => delAccount()} ><Icon name='trash' />USUŃ KONTO</Button>
      </>}
    </div>
  )
}

export default ChangeMyData;