import React from "react";
import { Button } from "semantic-ui-react";

const TokenCard = ({ tokens = 0,setactiveIndex,showButton = false }) => {
  return (
    <div className="token-card">
      <div className="token-header">
        <div className="token-icon">🪙</div>
        <h2>TOKEN-GTL</h2>
        <p className="token-description">
          Poniżej znajduje się liczba Twoich tokenów
        </p>
      </div>

      {tokens === 0 && showButton ? <Button className="token-button"  onClick={() => setactiveIndex(1)}>Dodaj tokeny</Button> : ""}

      <div className="token-footer">
        POSIADASZ: <span className={`token-count ${tokens === 0 ? "zero" : ""}`}>{tokens} GTL</span>
      </div>
    </div>
  );
};

export default TokenCard;
