import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Icon } from 'semantic-ui-react';

function CardInfoProduct(props) {
    const [products, setProducts] = useState([]);

    useEffect(() => {

        setProducts([
            { img: "https://turniej-generator.pl/img/product/1.png", name: "Widget treningów", body: "To moduł umożliwiający organizację wydarzeń sportowych, takich jak treningi, z możliwością zapisów online. Organizator może utworzyć wydarzenie, określić liczbę dostępnych miejsc i osadzić widget na własnej stronie internetowej. Zawodnicy lub uczestnicy mogą zapisywać się bezpośrednio przez stronę, a gdy limit miejsc zostanie osiągnięty, zapisy automatycznie się zamykają. Dzięki temu rozwiązaniu proces organizacji treningów staje się prostszy, bardziej przejrzysty i w pełni zautomatyzowany.",url:"/zapisy-na-treningi-online" },
            { img: "https://turniej-generator.pl/img/product/2.png", name: "System analizy protokołów meczowych AI", body: "To kompleksowe narzędzie umożliwiające wprowadzanie protokołów meczowych, wyników oraz statystyk. Pozwala to znacząco odciążyć organizatora rozgrywek z obowiązku wprowadzania wszystkich danych do systemu. Upoważnione osoby mogą ręcznie uzupełniać dane lub skorzystać z modułu System analizy protokołów meczowych AI, który automatycznie przetwarza informacje z dokumentów. Dzięki temu proces raportowania jest szybki, dokładny i zgodny z regulaminami, co usprawnia organizację rozgrywek.",url:"/info/analiza-protokolow-meczowych-AI" },
            { img: "https://turniej-generator.pl/img/product/3.png", name: "System analizy protokołów FIBA", body: "System analizy protokołów dla koszykówki FIBA to innowacyjne rozwiązanie, które automatycznie przetwarza i analizuje protokoły meczowe w formacie PDF, eliminując konieczność ręcznego przepisywania danych. Wykorzystuje zaawansowane algorytmy do ekstrakcji informacji o statystykach zawodników, wynikach i kluczowych wydarzeniach meczu, zgodnie z wytycznymi FIBA. Dzięki temu trenerzy, analitycy i zespoły mogą szybko uzyskać dokładne raporty bez ryzyka błędów ludzkich. System znacząco przyspiesza pracę i umożliwia efektywne porównywanie danych z różnych spotkań.",url:"/info/analiza-protokolow-FIBA" },
            { img: "https://turniej-generator.pl/img/product/4.png", name: "Panel nominacji i zarządzania pojedynkami dla sędziów", body: "To nowoczesne narzędzie, które usprawnia proces przydzielania arbitrów do meczów. Organizator przydziela sędziego do spotkania, a system automatycznie wysyła mu powiadomienie e-mail z linkiem do dedykowanego panelu. W panelu sędzia może zaakceptować lub odrzucić nominację, co eliminuje potrzebę ręcznego potwierdzania udziału. Dla zaakceptowanych meczów system udostępnia moduł do wprowadzania protokołu spotkania, wyników oraz szczegółowych statystyk",url:"/info/zarzadzanie-pojedynkami-dla-sedziow-nominacje" },
            { img: "https://turniej-generator.pl/img/product/5.png", name: "Panel zarządzania pojedynkami dla zawodników/drużyn", body: "To kompleksowe narzędzie umożliwiające wprowadzanie wyników meczowych oraz statystyk. Upoważnione osoby mogą ręcznie uzupełniać dane swoich pojedynków lub skorzystać z modułu System analizy protokołów meczowych AI, który automatycznie przetwarza informacje z dokumentów, eliminując konieczność ich ręcznego przepisywania. Dzięki temu proces raportowania jest szybki, dokładny i zgodny z regulaminami, co usprawnia organizację rozgrywek. Z panelu mogą korzystać zarówno zawodnicy, jak i drużyny uczestniczące w systemie turniejowym lub ligowym.",url:"/info/zarzadzanie-pojedynkami-dla-zawodnikow-druzyn" },
            { img: "https://turniej-generator.pl/img/product/6.png", name: "Kompleksowy system prowadzenia rozgrywek ligowych", body: "To nowoczesne rozwiązanie, które automatyzuje organizację i zarządzanie ligami sportowymi. Umożliwia tworzenie terminarzy, przydzielanie sędziów, wprowadzanie wyników oraz generowanie szczegółowych raportów. System oferuje zaawansowane statystyki dla drużyn i zawodników, analizując wyniki, efektywność gry oraz kluczowe wskaźniki wydajności. Dzięki integracji z Systemem analizy protokołów meczowych AI dane mogą być automatycznie przetwarzane. Platforma zapewnia transparentność, ułatwia podejmowanie decyzji oraz dostarcza cennych informacji dla trenerów, zawodników i organizatorów",url:"/info/system-ligowy" },
        ])
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps



    const responsiveOptions = [
        {
            breakpoint: '1499px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    const productTemplate = (product) => {
        return ( 
            <div className='productCardModule'>
                <LazyLoadImage
                    alt={product.name}
                    style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
                    width={"100%"}
                    src={product.img}
                    className="imgProductTG"
                    effect="blur" />

                <div className='productCardModuleContent'>
                    <div style={{  margin: 7 }}><b>{product.name}</b></div>
                    <div className='productCardModuleContent2'>
                        {product.body}
                    </div>
                    {product.url ? <div style={{textAlign:"right",fontSize:12}} className='cursorPointer tllink' onClick={()=>window.open(product.url, "_blank")}>
                        Więcej informacji <Icon name="external"/>
                    </div>:""}
                </div>
            </div>
        );
    };

    return (

        <div  >
            <Carousel value={products} circular autoplayInterval={3000} numScroll={1} numVisible={props.numVisible} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} />
            
        </div>
    )
}

export default CardInfoProduct;