import React, { useEffect, useState } from 'react';

import { loadingShow } from '../myfunctions';
import { confirmAlert } from 'react-confirm-alert';
import { Modal, ModalContent } from 'semantic-ui-react';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import useWindowDimensions from '../hooks/useWindowDimensions';

function GooglePhotosViewer({ url, openView, setGooglePhotosViewerStatus }) {
    const [images, setimages] = useState(null);
    const [loading, setloading] = useState(false);
    const [open, setOpen] = useState(false);
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    async function showDataPhoto(url) {
        try {
            setloading(true)
            setOpen(true)
            setimages(null)
            const items = [];
            const album = await getPhotosG(url);
            if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
                console.log(album)
              }

            let THUMBNAIL_WIDTH = 200
            let THUMBNAIL_WIDTH2 = 1920
            let THUMBNAIL_HEIGHT = 200
            let THUMBNAIL_HEIGHT2 = 1080
            album.mediaItems.forEach((mediaItem, i) => {
                const item = {

                    description: "Zdjęcie " + (i + 1) + " z " + album.mediaItems.length,
                    original: mediaItem.url.split('=')[0] + `=w${THUMBNAIL_WIDTH2}-h${THUMBNAIL_HEIGHT2}`,
                    thumbnail: mediaItem.url.split('=')[0] + `=w${THUMBNAIL_WIDTH}-h${THUMBNAIL_HEIGHT}`
                };
                items.push(item);
            });
            setimages(items)
            setloading(false)
            setTimeout(() => {
                setGooglePhotosViewerStatus({ url: url, openView: false })
            }, 1000);


        } catch (e) {
            console.log(e)
           // sendErrorToDB("googlePhoto", e, "123123")
            setGooglePhotosViewerStatus({ url: url, openView: false })
            setloading(false)
            setOpen(false)
            confirmAlert({
                title: 'BŁĄD',
                message: 'Wystąpił problem z pobraniem zdjęć z albumu ' + url,
                buttons: [
                    {
                        label: 'Rozumiem, sprawdzę poprawność linku',
                        onClick: () => ''
                    }
                ]
            });
        }
    }

    async function getPhotosG(url) {
        const payload = {
            method: 'getGooglePhotosAlbum',
            params: {
                sharedLink: url,
                imageWidth: 1920,
                imageHeight: 1080,
                includeThumbnails: true,
                videoQuality: '1080p',
                attachMetadata: false
            },
            id: 1
        };
    
        try {
            const response = await fetch('https://proxy.turniej-generator.pl', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error(`Błąd HTTP: ${response.status}`);
            }
    
            const data = await response.json();
            return data?.result || [];
        } catch (error) {
            console.error('Błąd podczas pobierania zdjęć:', error);
            return [];
        }
    }

    /*
    async function getPhotosG(url) {
        const payload = {
            method: 'getGooglePhotosAlbum',
            params: {
                sharedLink: url,
                imageWidth: 1920,
                imageHeight: 1080,
                includeThumbnails: true,
                videoQuality: '1080p',
                attachMetadata: false
            },
            id: 1
        };
        return await fetch('https://proxy.turniej-generator.pl', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'text/plain;charset=UTF-8',
                'Accept': 'application/json'
            }
        }).then(response => response.json()).then(response => response["result"]);
    }
        */


    useEffect(() => {
        if (openView) {
            showDataPhoto(url)
        }

        return () => {

        }; // use effect cleanup to set flag false, if unmounted
    }, [openView])// eslint-disable-line react-hooks/exhaustive-deps



    return (

        <>

            <Modal
                basic
                dimmer={"blurring"}
                open={open}
                size='fullscreen'
                onClose={() => setOpen(false)}
                closeIcon={true}
                centered={width < 900 ? false : true}
            >

                <ModalContent>
                    <ImageGallery useTranslate3D={false} lazyLoad={true} showThumbnails={false} infinite={false} showFullscreenButton={false} showPlayButton={false} items={images && images.length > 0 ? images : []} />
                    {loading ? <div style={{ backgroundColor: "#f9fafb", borderRadius: 5 }}>{loadingShow()}</div> : ""}
                </ModalContent>

            </Modal>
        </>

    )
}

export default GooglePhotosViewer;