import React, { useState, useEffect, useContext } from 'react';
import { Button, Icon, Label, Message, Segment, Table, Modal, Grid, Header, Image } from 'semantic-ui-react'
import { getFreePlace, getInfroMatchPlace2, getStosunekPTK, getlinkplayerprofile, loadingShow } from '../../../myfunctions';

import AuthContext from '../../../context/authContext';
import LogoTeamLigaGenerator from '../../../assets/tglogov6_small.png';
import { ConnectWS } from '../../../ConnectWS';
import ShowImgAvatar from '../../../tools/ShowImgAvatar';
import ShowScoreDetails from './ShowScoreDetails';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

function BracketGenCurrentMatch(propsRoot) {

    const [jsonRemainingMatchesFull, setjsonRemainingMatchesFull] = useState([])

    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    const authContext = useContext(AuthContext);
    const [open, setOpen] = useState(false)
    const [loading, setloading] = useState(false)
    const [dataRow, setdataRow] = useState([])
    const { t } = useTranslation();

    function preanaliza(jsonBreacket) {

        let jsonRemainingMatches = []
        jsonBreacket.forEach(element => {
            loopJsonB(element)
        })
        function loopJsonB(jsonTMP) {

            let alljsonTMP = jsonTMP
            Object.entries(jsonTMP).forEach(
                ([key1, value1]) => {
                    if (key1 === "sides") {
                        if ((((value1.home.score.score === null && value1.visitor.score.score === null) || propsRoot.viewTV === 1) && value1.home.team.id !== null && value1.visitor.team.id !== null && value1.home.team.id !== 0 && value1.visitor.team.id !== 0) || (alljsonTMP.match_place && value1.home.score.score === null && value1.visitor.score.score === null)) {
                            jsonRemainingMatches.push({ game: alljsonTMP })
                        }

                        if (value1.home.seed && value1.home.seed.sourceGame) {
                            loopJsonB(value1.home.seed.sourceGame)
                        }
                        if (value1.visitor.seed && value1.visitor.seed.sourceGame) {
                            loopJsonB(value1.visitor.seed.sourceGame)
                        }
                    }
                }
            );
        }


        try {
            setjsonRemainingMatchesFull(jsonRemainingMatches.sort(function (a, b) {

                return new Date(getDateS(a.game.match_place)).getTime() - new Date(getDateS(b.game.match_place)).getTime() || a.game.id - b.game.id;
            }))
        } catch (e) {
            setjsonRemainingMatchesFull(jsonRemainingMatches)
        }

    }

    function getDateS(match_place) {
        try {
            if (match_place && JSON.parse(match_place).match_dt) {

                return JSON.parse(match_place).match_dt
            } else {
                return "2555-01-01 12:00:00"
            }

        } catch (e) {
            return "2555-01-01 12:00:00"
        }
    }

    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps



        preanaliza(propsRoot.jsonBreacket)

        return () => {

        }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.jsonBreacket])// eslint-disable-line react-hooks/exhaustive-deps





    function closeModal() {
        setOpen(false)
    }

    function openModal() {
        setdataRow([])
        generateSubtournamentResultTableForLosers()
        setOpen(true)
    }

    function generateSubtournamentResultTableForLosers() {
        setloading(true)
        ConnectWS("/generator/generateSubtournamentResultTableForLosers", authContext.token, { subtournament_id: propsRoot.subtournament_id }, function (response, logout) {

            if (logout === 1) {
                closeModal()
            } else if (response && response.status && Number(response.status) === 200) {
                setdataRow(response.data)
            } else {
                closeModal()
            }
            setloading(false)


        });
    }

    return (
        <>
            {jsonRemainingMatchesFull && jsonRemainingMatchesFull.length > 0 && propsRoot.onlyView === 0 ?
                <Segment className='c hiddenPrint' style={{ marginBottom: 10, marginTop: 10, backgroundColor: "#eeeeee" }}>
                    <Label color='blue' ribbon style={{ marginBottom: 10 }}>
                        <Icon name="winner" /> Tabela pomocnicza
                    </Label>

                    {propsRoot.onlyView === 0 ? <Message info size='mini'>Poniższa tabela przedstawia aktualne pojedynki do rozegrania. Nie są one uporządkowane według żadnego algorytmu, jak miało to miejsce w przypadku systemu grupowego i algorytmu BERGERA. W tym przypadku kolejność rozgrywania meczów ustala organizator.</Message> : ""}


                    <div style={{ overflowX: "auto", width: "100%" }} >


                        <Table singleLine size='small' inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
                            <Table.Header>
                                <Table.Row>
                                    {propsRoot.hide_bracket_names ? "" : <Table.HeaderCell width={1} textAlign='center'>{t("WidokTurnieju.runda")}</Table.HeaderCell>}

                                    <Table.HeaderCell width={1} textAlign='center' style={{ lineHeight: "12px" }}>{t("WidokTurnieju.miejsce_pojedynku")}<br /><span style={{ fontSize: 10 }}>{t("WidokTurnieju.miejsce_pojedynku1")}</span></Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.aktualne_pojedynki").toLocaleUpperCase()}</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {jsonRemainingMatchesFull.map((row, i) =>

                                    <Table.Row>

                                        {propsRoot.hide_bracket_names ? "" :
                                            <Table.Cell width={1} textAlign='center'>
                                                {row.game.name}
                                            </Table.Cell>
                                        }
                                        <Table.Cell width={1} textAlign='center' style={{ position: "relative" }}>
                                            {getInfroMatchPlace2(row.game.match_place)}
                                        </Table.Cell>
                                        <Table.Cell textAlign='center' style={{ position: "relative" }}>

                                            <b>{row.game.sides.home.team.name}</b> vs <b>{row.game.sides.visitor.team.name}</b>
                                            <ShowScoreDetails refreshView={authContext.refreshView} systemId={propsRoot.systemId} matche_info={{ member1_img_file: row.game.sides.home.team.url_img, member2_img_file: row.game.sides.visitor.team.url_img, member1_name: row.game.sides.home.team.name, member2_name: row.game.sides.visitor.team.name, member1_score: null, member2_score: null }} match_detailstmp={{ subtournament_match_id: row.game.id, bracket: false, match_details: row.game.match_details, match_member1_id: row.game.sides.home.team.id, match_member2_id: row.game.sides.visitor.team.id, reverse: false, checkboxMD: true }} />
                                        </Table.Cell>
                                        <Table.Cell width={1} textAlign='center'>
                                            <Button size='mini' color='orange' fluid onClick={() => propsRoot.eventShow(row)}>Wprowadź wynik</Button>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                        <Message size='mini'>
                            Wolne miejsca do gry - nr: {propsRoot && propsRoot.tournamentJsonRoot && propsRoot.tournamentJsonRoot.match_places_amount ? getFreePlace(propsRoot.tournamentJsonRoot.match_places_amount, propsRoot.tournamentJsonRoot.playing_match_places) : ""}
                        </Message>
                        <Button size='mini' color='grey' onClick={() => openModal()}>Pokaż statystyki oraz miejsca zajęte przez uczestników, którzy zakończyli już udział w turnieju.</Button>
                    </div>
                </Segment>
                :
                jsonRemainingMatchesFull && jsonRemainingMatchesFull.length > 0 && propsRoot.onlyView === 1 ?
                    <Segment className='hiddenPrint' style={{ marginBottom: 50, marginTop: 10,  }}>
                        <div style={{ overflowX: "auto", width: "100%" }} >

                            <Table singleLine size='small' inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
                                <Table.Header>
                                    <Table.Row>
                                        {propsRoot.hide_bracket_names ? "" : <Table.HeaderCell width={1} textAlign='center'>{t("WidokTurnieju.runda")}</Table.HeaderCell>}
                                        <Table.HeaderCell width={1} textAlign='center' style={{ lineHeight: "12px" }}>{t("WidokTurnieju.miejsce_pojedynku")}<br /><span style={{ fontSize: 10 }}>{t("WidokTurnieju.miejsce_pojedynku1")}</span></Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.aktualne_pojedynki").toLocaleUpperCase()}</Table.HeaderCell>

                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {jsonRemainingMatchesFull.map((row, i) =>
                                        <Table.Row>
                                            {propsRoot.hide_bracket_names ? "" : <Table.Cell width={1} textAlign='center'>
                                                {row.game.name}
                                            </Table.Cell>}
                                            <Table.Cell width={1} textAlign='center' style={{ position: "relative" }}>
                                                {getInfroMatchPlace2(row.game.match_place)}

                                            </Table.Cell>
                                            <Table.Cell textAlign='center' style={{ position: "relative" }}>

                                                {propsRoot.viewTV === 1 && row.game.sides.home.score.score !== null ? row.game.sides.home.score.score > row.game.sides.visitor.score.score ? <><b>{row.game.sides.home.team.name}</b> {row.game.sides.home.score.score}:{row.game.sides.visitor.score.score} {row.game.sides.visitor.team.name}</>
                                                    : <>{row.game.sides.home.team.name} {row.game.sides.home.score.score}:{row.game.sides.visitor.score.score} <b>{row.game.sides.visitor.team.name}</b></>
                                                    : <><b>{row.game.sides.home.team.name}</b> vs <b>{row.game.sides.visitor.team.name}</b></>}

                                                <ShowScoreDetails refreshView={authContext.refreshView} systemId={propsRoot.systemId} matche_info={{ member1_img_file: row.game.sides.home.team.url_img, member2_img_file: row.game.sides.visitor.team.url_img, member1_name: row.game.sides.home.team.name, member2_name: row.game.sides.visitor.team.name, member1_score: null, member2_score: null }} match_detailstmp={{ subtournament_match_id: row.game.id, bracket: false, match_details: row.game.match_details, match_member1_id: row.game.sides.home.team.id, match_member2_id: row.game.sides.visitor.team.id, reverse: false, checkboxMD: true }} />

                                            </Table.Cell>

                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>

                        </div>
                    </Segment>
                    :
                    ""
            }
            <Modal
                onClose={() => closeModal()}
                open={open}
                dimmer='blurring'
                centered={width < 900 ? false : true}
            >
                <Modal.Header>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as='h3' textAlign='center'>
                                    <Icon name='winner' color="red" />
                                    <Header.Content>Miejsca uczestników, którzy zakończyli już rozgrywki</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" only='large screen'>
                                <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Modal.Header>
                <Modal.Content scrolling>

                    {loading ? loadingShow() : dataRow.length > 0 ?

                        <div style={{ overflowX: "auto", marginTop: 5, width: "100%" }} >
                            <Table size='small' inverted={authContext.darkMode} celled striped compact  >
                                <Table.Header>
                                    <Table.Row>

                                        <Table.HeaderCell singleLine width={1} textAlign='center'>MIEJSCE</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' singleLine>UCZESTNIK</Table.HeaderCell>
                                        <Table.HeaderCell width={1} textAlign='center' singleLine>POJEDYNKI</Table.HeaderCell>
                                        <Table.HeaderCell width={1} textAlign='center' singleLine>WYGRANE</Table.HeaderCell>
                                        <Table.HeaderCell width={1} textAlign='center' singleLine>PRZEGRANE</Table.HeaderCell>
                                        <Table.HeaderCell width={1} textAlign='center' singleLine>BILANS</Table.HeaderCell>
                                        <Table.HeaderCell width={1} textAlign='center' singleLine>STOSUNEK</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {dataRow.length > 0 ?
                                        dataRow.map((line3, i) => <Table.Row key={i} >
                                            <Table.Cell textAlign='center'>{line3.order_nr_range}</Table.Cell>
                                            <Table.Cell textAlign='center' singleLine><figure className='imgPlayer'><ShowImgAvatar urlpic={line3.img_file} mtype={"0"} /><figcaption>{getlinkplayerprofile(null, line3.member_name)}</figcaption></figure></Table.Cell>
                                            <Table.Cell textAlign='center'>{line3.matches_played}</Table.Cell>
                                            <Table.Cell textAlign='center'>{line3.wins}</Table.Cell>
                                            <Table.Cell textAlign='center'>{line3.loses}</Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>{line3.total_points_winners} / {line3.total_points_losers}</Table.Cell>
                                            <Table.Cell textAlign='center' singleLine>{getStosunekPTK(line3.total_points_winners, line3.total_points_losers)}</Table.Cell>



                                        </Table.Row>)
                                        : ""}

                                </Table.Body>


                            </Table>
                        </div>

                        : <Message>Brak danych - nie ma jeszcze wyłonionych żadnych miejsc</Message>}


                </Modal.Content>
                <Modal.Actions>

                    <Button size="tiny" color='black' onClick={() => closeModal()}>
                        ZAMKNIJ
                    </Button>

                </Modal.Actions>
            </Modal>
        </>

    )
}

export default BracketGenCurrentMatch;