import React from 'react';
import {  Container, List, Icon, Grid } from 'semantic-ui-react'
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkiscompanypay } from '../../../myfunctions';


function FooterPage() {
  let history = useHistory();
  const { t } = useTranslation();

  function clickLink(link) {
    //addLinkToSitemap(link)
    history.push(link)

  }

  return (
    <>
      <div  style={{backgroundColor:"#1b1c1e"}} >
        <Container textAlign='center'>
          <Grid >
            <Grid.Row verticalAlign='middle'>
              <Grid.Column width={5} textAlign='left'>

                <List style={{ margin: 0, marginTop: 5 }} horizontal inverted divided link size='small'>
                  <List.Item style={{ marginRight: 1, lineHeight: "15px" }}>©{new Date().getFullYear()} TURNIEJ-GENERATOR.PL {checkiscompanypay() ? <span style={{fontSize:11}}>by <b>MBIT</b>LAB</span> : ""}</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={11} textAlign='right'>
                <List style={{ margin: 0, marginTop: 5 }} horizontal inverted divided link size='small'>

                  <List.Item className="cursorPointer hoverColor" onClick={() => clickLink("/polityka-prywatnosci")}>
                    {t("menuStopka.polityka_prywatnosci").toUpperCase()}
                  </List.Item>
                  <List.Item className="cursorPointer hoverColor" onClick={() => clickLink("/regulamin")}>
                    {t("menuStopka.regulamin").toUpperCase()}
                  </List.Item>
                  <List.Item className="cursorPointer hoverColor" onClick={() => clickLink("/kontakt")}>
                    {t("menuStopka.kontakt").toUpperCase()}
                  </List.Item>
                  <List.Item className="cursorPointer hoverColor" onClick={() => clickLink("/cennik")}>
                    {t("CENNIK").toUpperCase()}
                  </List.Item>
             
                  <List.Item className="cursorPointer hoverColor" onClick={() => clickLink("/faq")}>
                    FAQ
                  </List.Item>
                  <List.Item className="cursorPointer hoverColor" >
                    <Link to={{ pathname: "https://www.facebook.com/TurniejGeneratorPL" }} target="_blank">  <Icon name="facebook f" color="grey" /></Link>
                  </List.Item>


                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>




        </Container>
      </div>

    </>
  )
}

export default FooterPage;