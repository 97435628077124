import React, { useEffect, useContext, useState } from 'react';
import { Icon, Header, Divider, Segment, Grid, Image, Container, Message, Table, Button, Input } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import packageJson from '../../../../../package.json';
import pucharpatg from '../../../../assets/pucharpatg.png';
import pucharpatg1 from '../../../../assets/pucharpatg1.png';
import { ConnectWS } from '../../../../ConnectWS';
import Ticker from '../../../../hooks/Ticker';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { addDateToDate, bruttoToNetto, changeInitialScale, checkiscompanypay, getlinkcycle, getlinksubt, getlinktournament, packageColor, subscripteUserDateURL } from '../../../../myfunctions';
import { confirmAlert } from 'react-confirm-alert';
import CountDownTimer from '../../countDownTimer/CountDownTimer';
import BuySubscription from '../../SinglePage/Other/BuySubscription';
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import { TabView, TabPanel } from 'primereact/tabview';
import BuyProductCompany from '../../SinglePage/Other/BuyProductCompany';
import CardInfoProduct from '../../../../tools/CardInfoProduct';
import MoveYtTG from '../../../../tools/MoveYtTG';
import TokenCard from '../../../../tools/Cards/TokenCard';

function ViewRoot({ setactiveIndex }) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [getUserTokenStatsV, setgetUserTokenStatsV] = useState();
  const [getLastEventsInfoForAdmV, setgetLastEventsInfoForAdmV] = useState();
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const isDemo = useCheckUserIsDemo();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: ViewRoot")
    }
    changeInitialScale(0.5)
    setmdatabyadmin(null)
    getUserTokenStats()
    getLastEventsInfoForAdm()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const [mdatabyadmin, setmdatabyadmin] = useState(null);
  const [mdatabyadminloading, setmdatabyadminloading] = useState(false);
  function generateEmptySubtournamentResultTableByAdmin() {

    setmdatabyadminloading(true)
    ConnectWS("/generator/generateEmptySubtournamentResultTableByAdmin", authContext.token, { subtournament_id: Number(mdatabyadmin) }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }


      }
      setmdatabyadminloading(false)

    });
  }
  function sendTournamentToExternalServiceByAdmin() {

    setmdatabyadminloading(true)
    ConnectWS("/generator/sendTournamentToExternalServiceByAdmin", authContext.token, { tournament_id: Number(mdatabyadmin) }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }


      }
      setmdatabyadminloading(false)

    });
  }



  function notifyMemberMergeDoneByAdmin() {

    setmdatabyadminloading(true)
    ConnectWS("/members/notifyMemberMergeDoneByAdmin", authContext.token, { member_id: Number(mdatabyadmin) }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }


      }
      setmdatabyadminloading(false)

    });
  }

  function setTournamentAsCharityByAdmin() {

    setmdatabyadminloading(true)
    ConnectWS("/generator/setTournamentAsCharityByAdmin", authContext.token, { tournament_id: Number(mdatabyadmin) }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }


      }
      setmdatabyadminloading(false)

    });
  }


  function getUserTokenStats() {


    ConnectWS("/generator/getUserTokenStats", authContext.token, {}, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        setgetUserTokenStatsV(response.data)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }


      }


    });
  }

  function getLastEventsInfoForAdm() {
    if (authContext.user && authContext.user.admin === 1) {
      ConnectWS("/login/getLastEventsInfoForAdm", authContext.token, {}, function (response, logout) {

        if (logout === 1) {

        } else if (response && response.status && Number(response.status) === 200) {
          setgetLastEventsInfoForAdmV(response.data)
        } else {

        }

      });
    }

  }



  function resetAndCalcEloRank() {
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Przeliczyć ponownie ranking ELO?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => resetAndCalcEloRankACC()
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });
    function resetAndCalcEloRankACC() {

      ConnectWS("/generator/resetAndCalcEloRank", authContext.token, {}, function (response, logout) {

        if (logout === 1) {

        } else if (response && response.status && Number(response.status) === 200) {
          //setRowDataTournament(response.data)
          addToast(response.data.message, { appearance: 'success' });
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
        }



      });


    }
  }




  function getSubscruptionCard() {

    return <>
      <div className="subscription-card">
        <div className="subscription-header">
          <span className="subscription-icon">{authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended === 1 ? packageColor(5) : authContext.user.subscription_info.subscription_extended === 2 ? packageColor(4) : packageColor(authContext.user.subscription_info.subscription_extended)}</span>
          <h2>Diamentowy {authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended === 1 ? "Plus" : authContext.user.subscription_info.subscription_extended === 2 ? "" : "Lite"}</h2>
          <p className="subscription-active">
            Subskrypcja<br /> Aktywna do: <strong>{authContext.user.subscription_info.subscription_to}</strong>
          </p>
        </div>

        <div className="subscription-price">
          <span className="netto">{bruttoToNetto(authContext.user.subscription_info.subscription_price)} PLN<span>netto</span></span><br />
          <span className="brutto">{authContext.user.subscription_info.subscription_price} PLN brutto</span>
        </div>

        {checkiscompanypay() === 1 ?

          <BuyProductCompany payable_service_id={null} buyProductType={2} isToken={false} messageProduct={"Subskrypcja"} isDemo={isDemo} issmall={true} btnname={"PRZEDŁUŻ"} price={authContext.user.subscription_info.subscription_price} />
          :
          <BuySubscription isDemo={isDemo} issmall={true} btnname={"PRZEDŁUŻ " + authContext.user.subscription_info.subscription_price + " PLN"} price={authContext.user.subscription_info.subscription_price} subscription_type={authContext.user.subscription_info.subscription_extended === 1 ? 2 : 1} />
        }

        <div className="next-renewal">
          Przedłużenie do: <strong>{authContext.user.subscription_info.subscription_to ? addDateToDate(authContext.user.subscription_info.subscription_to, 31) : "n/n"}</strong>
        </div>

        <div className="subscription-countdown">
          <CountDownTimer type={1} endDate={authContext.user.subscription_info.subscription_to} textValue="DO KOŃCA SUBSKRYPCJI" textValueFinish="" beautifulText="0" />
        </div>
      </div>
    </>
  }

  /*
    function getStatCard(icon, label, value){
      return   <Card raised style={{ borderRadius: '16px', textAlign: 'center', flex: 1, minWidth: 140 }}>
      <Card.Content>
        <Icon name={icon} size="big" color="blue" />
        <Card.Header style={{ fontSize: '1.8rem', marginTop: '0.5rem' }}>{value}</Card.Header>
        <Card.Meta style={{ fontSize: '1rem', color: '#666' }}>{label}</Card.Meta>
      </Card.Content>
    </Card>
    }
  
      <Card.Group centered>
                  {getStatCard("winner",getUserTokenStatsV ? getUserTokenStatsV.totalCyclesCount : "?","CYKLI")}
                  {getStatCard("winner",getUserTokenStatsV ? getUserTokenStatsV.totalTournamentsCount : "?","ROZGRYWEK")}
                  {getStatCard("arrow alternate circle right",getUserTokenStatsV ? getUserTokenStatsV.totalSubtournamentsCount : "?","KATEGORII")}
                  {getStatCard("users",getUserTokenStatsV ? getUserTokenStatsV.totalMembersCount : "?","UCZESTNIKÓW")}
                  {getStatCard("handshake",getUserTokenStatsV ? getUserTokenStatsV.totalMatchesCount : "?","POJEDYNKÓW")}
                </Card.Group>
  
                */


  return (
    <>



      <Grid stackable divided   >
        <Grid.Row stretched>

          <Grid.Column width={width > 900 ? 8 : 7} >
            <Header as='h5' block>
              <Image
                alt="puchartg"
                style={{ width: 70 }}
                src={pucharpatg}
              />
              <Header.Content>
                Cześć! <span style={{ textTransform: "capitalize" }}>{authContext.user && authContext.user.fullName ? authContext.user.fullName : "?"}</span>

                <Header.Subheader>Witaj w generatorze turniejów jest to Twój widok głowny.</Header.Subheader>
                <Header.Subheader>Dodawanie/Zarządzanie rozgrywkami znajduje się po prawej stronie klikając w ikonę <Icon name="bars" /></Header.Subheader>
              </Header.Content>
            </Header>
            <Message size='tiny'>
              <Icon name="info circle" /> Wersja app - <b> {packageJson.version}</b>
            </Message>
            {authContext.user && authContext.user.userId && subscripteUserDateURL(authContext.user.userId) ?
              <Message icon size='mini'>
                <Icon name='tag' />
                <Message.Content>
                  <Message.Header>Przejdź do dedykowanej strony </Message.Header>
                  <Icon name="linkify" /><span><a className={"tllink"} href={process.env.REACT_APP_PUBLIC_URL + subscripteUserDateURL(authContext.user.userId)}> {"turniej-generator.pl" + subscripteUserDateURL(authContext.user.userId)}</a></span>
                </Message.Content>
              </Message>
              : ""}
            <Segment placeholder >


              <div className="stat-container">
                <div className="stat-card">
                  <i className="trophy icon large blue" />
                  <div className="stat-label">CYKLI</div>
                  <div className="stat-value"><Ticker className="count" end={getUserTokenStatsV ? getUserTokenStatsV.totalCyclesCount : 0} suffix="" /></div>
                </div>
                <div className="stat-card">
                  <i className="trophy icon large blue" />
                  <div className="stat-label">ROZGRYWKI</div>
                  <div className="stat-value"><Ticker className="count" end={getUserTokenStatsV ? getUserTokenStatsV.totalTournamentsCount : 0} suffix="" /></div>
                </div>
                <div className="stat-card">
                  <i className="arrow circle right icon large blue" />
                  <div className="stat-label">KATEGORII</div>
                  <div className="stat-value"><Ticker className="count" end={getUserTokenStatsV ? getUserTokenStatsV.totalSubtournamentsCount : 0} suffix="" /></div>
                </div>
                <div className="stat-card">
                  <i className="users icon large blue" />
                  <div className="stat-label">UCZESTNIKÓW</div>
                  <div className="stat-value"><Ticker className="count" end={getUserTokenStatsV ? getUserTokenStatsV.totalMembersCount : 0} suffix="" /></div>
                </div>
                <div className="stat-card">
                  <i className="handshake icon large blue" />
                  <div className="stat-label">POJEDYNKÓW</div>
                  <div className="stat-value"><Ticker className="count" end={getUserTokenStatsV ? getUserTokenStatsV.totalMatchesCount : 0} suffix="" /></div>
                </div>
              </div>


            </Segment>

          </Grid.Column>

          <Grid.Column width={width > 900 ? 8 : 9} >

            <Segment placeholder className='bgpatg' style={{ padding: 40 }}>
              <div className="dashboard-cards">
                {authContext.user.subscription_info && authContext.user.subscription_info.subscription_active === true && authContext.user.subscription_info.subscription_extended >= -1 && getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount === 0 ? getSubscruptionCard() :
                  <>
                    {authContext.user.subscription_info && authContext.user.subscription_info.subscription_active === true && authContext.user.subscription_info.subscription_extended >= -1 ? getSubscruptionCard() : ""}
                    {authContext.user && getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount ? <TokenCard showButton={true} tokens={getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount ? getUserTokenStatsV.currentTokensAmount : 0} setactiveIndex={setactiveIndex} /> : ""}
                  </>
                }

              </div>
            </Segment>

          </Grid.Column>

        </Grid.Row>
      </Grid >

      {
        getLastEventsInfoForAdmV ?
          <>


            <TabView activeIndex={activeIndex} scrollable onTabChange={(e) => {
              setActiveIndex(e.index)
            }}>
              <TabPanel header={"Przyciski"} style={{ padding: 0, margin: 0 }} >
                <Input label="tournament_id lub subtournament_id lub member_id" fluid value={mdatabyadmin} onChange={(e, d) => setmdatabyadmin((d.value))} />
                <br />
                <Button fluid onClick={() => resetAndCalcEloRank()}>Przelicz ELO ponownie</Button>
                <br />
                <Button fluid onClick={() => authContext.changeModalInfo({ nameModal: "BuyTestHotPay", name: "TESTOWANIE HOTPAY", value: { t: 2 } })}>TESTOWANIE HOTPAY</Button>
                <br />
                <Button fluid loading={mdatabyadminloading} disabled={mdatabyadmin === null || mdatabyadmin === "" ? true : false} onClick={() => generateEmptySubtournamentResultTableByAdmin()}>PUSTA TABELA KONCOWA - subtournament_id</Button>
                <br />
                <Button fluid loading={mdatabyadminloading} disabled={mdatabyadmin === null || mdatabyadmin === "" ? true : false} onClick={() => sendTournamentToExternalServiceByAdmin()}>DANE DO 6CALI - tournament_id</Button>
                <br />
                <Button fluid loading={mdatabyadminloading} disabled={mdatabyadmin === null || mdatabyadmin === "" ? true : false} onClick={() => setTournamentAsCharityByAdmin()}>Turniej charytatywny - tournament_id</Button>
                <br />
                <Button fluid loading={mdatabyadminloading} disabled={mdatabyadmin === null || mdatabyadmin === "" ? true : false} onClick={() => notifyMemberMergeDoneByAdmin()}>Powiadomienie mailowe o scaleniu membersa - member_id</Button>


              </TabPanel>
              <TabPanel header={"Cykle"} style={{ padding: 0, margin: 0 }} >
                <Header as='h3' block>
                  CYKLE
                </Header>

                <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                  <Table className='admtab' unstackable compact celled >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell >Data</Table.HeaderCell>
                        <Table.HeaderCell>Nazwa Cyklu</Table.HeaderCell>
                        <Table.HeaderCell >Nazwa kategorii</Table.HeaderCell>
                        <Table.HeaderCell >Typ kategorii</Table.HeaderCell>
                        <Table.HeaderCell>Użytkownik</Table.HeaderCell>
                        <Table.HeaderCell>Link</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_cycles && getLastEventsInfoForAdmV.last_cycles.length > 0 ? getLastEventsInfoForAdmV.last_cycles.map((row, i) =>
                        <Table.Row key={i}>
                          <Table.Cell>{row.create_ts}</Table.Cell>
                          <Table.Cell>{row.cycle_name}</Table.Cell>
                          <Table.Cell>{row.subtournament_name}</Table.Cell>
                          <Table.Cell>{row.subtournament_type}</Table.Cell>
                          <Table.Cell>{row.user_name} {row.user_surname}</Table.Cell>
                          <Table.Cell>{getlinkcycle(row.cycle_id, row.cycle_name, row.subtournament_name)}</Table.Cell>
                        </Table.Row>
                      )
                        : ""}

                    </Table.Body>
                  </Table>
                </div>

              </TabPanel>
              <TabPanel header={"Kategorie"} style={{ padding: 0, margin: 0 }} >
                <Header as='h3' block>
                  KATEGORIE
                </Header>

                <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                  <Table className='admtab' unstackable compact celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Data</Table.HeaderCell>
                        <Table.HeaderCell>Modyfikacja</Table.HeaderCell>
                        <Table.HeaderCell>Nazwa Kat</Table.HeaderCell>
                        <Table.HeaderCell>Typ</Table.HeaderCell>
                        <Table.HeaderCell>Zatwierdzone</Table.HeaderCell>
                        <Table.HeaderCell>Użytkownik</Table.HeaderCell>
                        <Table.HeaderCell>Link</Table.HeaderCell>
                        <Table.HeaderCell>UserID</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_subtournaments && getLastEventsInfoForAdmV.last_subtournaments.length > 0 ? getLastEventsInfoForAdmV.last_subtournaments.map((row, i) =>
                        <Table.Row key={i}>
                          <Table.Cell>{row.create_ts}</Table.Cell>
                          <Table.Cell>{row.last_modify_ts}</Table.Cell>
                          <Table.Cell>{row.subtournament_name}</Table.Cell>
                          <Table.Cell>{row.subtournament_type}</Table.Cell>
                          <Table.Cell>{row.struct_confirmed}</Table.Cell>
                          <Table.Cell>{row.user_name} {row.user_surname}</Table.Cell>
                          <Table.Cell>{getlinksubt(row.tournament_id, row.subtournament_id)}</Table.Cell>
                          <Table.Cell>{row.create_user_id}</Table.Cell>
                        </Table.Row>
                      )
                        : ""}

                    </Table.Body>
                  </Table>
                </div>

              </TabPanel>
              <TabPanel header={"Turnieje trwajace"} style={{ padding: 0, margin: 0 }} >
                <Header as='h3' block>
                  TURNIEJE TRWAJACE
                </Header>

                <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                  <Table className='admtab' unstackable compact celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Data</Table.HeaderCell>
                        <Table.HeaderCell>Modyfikacja</Table.HeaderCell>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Nazwa</Table.HeaderCell>
                        <Table.HeaderCell>Start</Table.HeaderCell>
                        <Table.HeaderCell>Stop</Table.HeaderCell>
                        <Table.HeaderCell>Pakiet</Table.HeaderCell>
                        <Table.HeaderCell>Rejestracja Onlie</Table.HeaderCell>
                        <Table.HeaderCell>Użytkownik</Table.HeaderCell>
                        <Table.HeaderCell>Link</Table.HeaderCell>
                        <Table.HeaderCell>UserID</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.current_tournaments && getLastEventsInfoForAdmV.current_tournaments.length > 0 ? getLastEventsInfoForAdmV.current_tournaments.map((row, i) =>
                        <Table.Row key={i}>
                          <Table.Cell>{row.create_ts}</Table.Cell>
                          <Table.Cell>{row.last_modify_ts}</Table.Cell>
                          <Table.Cell>{row.tournament_id}</Table.Cell>
                          <Table.Cell>{row.name}</Table.Cell>
                          <Table.Cell>{row.start_date}</Table.Cell>
                          <Table.Cell>{row.stop_date}</Table.Cell>
                          <Table.Cell>{row.tournament_free}</Table.Cell>
                          <Table.Cell>{row.register_online}</Table.Cell>
                          <Table.Cell>{row.user_name} {row.user_surname}</Table.Cell>
                          <Table.Cell>{getlinktournament(row.tournament_id)}</Table.Cell>
                          <Table.Cell>{row.create_user_id}</Table.Cell>
                        </Table.Row>
                      )
                        : ""}

                    </Table.Body>
                  </Table>
                </div>

              </TabPanel>
              <TabPanel header={"Turnieje"} style={{ padding: 0, margin: 0 }} >
                <Header as='h3' block>
                  TURNIEJE
                </Header>

                <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                  <Table className='admtab' unstackable compact celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Data</Table.HeaderCell>
                        <Table.HeaderCell>Modyfikacja</Table.HeaderCell>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Nazwa</Table.HeaderCell>
                        <Table.HeaderCell>Start</Table.HeaderCell>
                        <Table.HeaderCell>Stop</Table.HeaderCell>
                        <Table.HeaderCell>Pakiet</Table.HeaderCell>
                        <Table.HeaderCell>Rejestracja Onlie</Table.HeaderCell>
                        <Table.HeaderCell>Użytkownik</Table.HeaderCell>
                        <Table.HeaderCell>Link</Table.HeaderCell>
                        <Table.HeaderCell>UserID</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_tournaments && getLastEventsInfoForAdmV.last_tournaments.length > 0 ? getLastEventsInfoForAdmV.last_tournaments.map((row, i) =>
                        <Table.Row key={i}>
                          <Table.Cell>{row.create_ts}</Table.Cell>
                          <Table.Cell>{row.last_modify_ts}</Table.Cell>
                          <Table.Cell>{row.tournament_id}</Table.Cell>
                          <Table.Cell>{row.name}</Table.Cell>
                          <Table.Cell>{row.start_date}</Table.Cell>
                          <Table.Cell>{row.stop_date}</Table.Cell>
                          <Table.Cell>{row.tournament_free}</Table.Cell>
                          <Table.Cell>{row.register_online}</Table.Cell>
                          <Table.Cell>{row.user_name} {row.user_surname}</Table.Cell>
                          <Table.Cell>{getlinktournament(row.tournament_id)}</Table.Cell>
                          <Table.Cell>{row.create_user_id}</Table.Cell>
                        </Table.Row>
                      )
                        : ""}

                    </Table.Body>
                  </Table>
                </div>

              </TabPanel>
              <TabPanel header={"Modyfikacje"} style={{ padding: 0, margin: 0 }} >
                <Header as='h3' block>
                  OSTATNIE MODYFIKACJE
                </Header>

                <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                  <Table className='admtab' unstackable compact celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Ostatnia modyfikacja tabeli koncowej</Table.HeaderCell>
                        <Table.HeaderCell>Ostatnia modyfikacja</Table.HeaderCell>
                        <Table.HeaderCell>Typ uczestników</Table.HeaderCell>
                        <Table.HeaderCell>Start</Table.HeaderCell>
                        <Table.HeaderCell>Stop</Table.HeaderCell>
                        <Table.HeaderCell>Id kat</Table.HeaderCell>
                        <Table.HeaderCell>Nazwa kategorii</Table.HeaderCell>
                        <Table.HeaderCell>Typ kategorii</Table.HeaderCell>
                        <Table.HeaderCell>Pakiet</Table.HeaderCell>
                        <Table.HeaderCell>ID turnieju</Table.HeaderCell>
                        <Table.HeaderCell>Nazwa turnieju </Table.HeaderCell>
                        <Table.HeaderCell>Użytkownik</Table.HeaderCell>
                        <Table.HeaderCell>Link kat</Table.HeaderCell>
                        <Table.HeaderCell>Link turniej</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_edit_subtournaments && getLastEventsInfoForAdmV.last_edit_subtournaments.length > 0 ? getLastEventsInfoForAdmV.last_edit_subtournaments.map((row, i) =>
                        <Table.Row key={i}>
                          <Table.Cell>{row.final_table_last_modify}</Table.Cell>
                          <Table.Cell>{row.match_last_modify}</Table.Cell>
                          <Table.Cell>{row.member_type}</Table.Cell>
                          <Table.Cell>{row.start_date}</Table.Cell>
                          <Table.Cell>{row.stop_date}</Table.Cell>
                          <Table.Cell>{row.subtournament_id}</Table.Cell>
                          <Table.Cell>{row.subtournament_name}</Table.Cell>
                          <Table.Cell>{row.subtournament_type}</Table.Cell>
                          <Table.Cell>{row.tournament_free}</Table.Cell>
                          <Table.Cell>{row.tournament_id}</Table.Cell>
                          <Table.Cell>{row.tournament_name}</Table.Cell>
                          <Table.Cell>{row.username}</Table.Cell>
                          <Table.Cell>{getlinksubt(row.tournament_id, row.subtournament_id)}</Table.Cell>
                          <Table.Cell>{getlinktournament(row.tournament_id)}</Table.Cell>
                        </Table.Row>
                      )
                        : ""}

                    </Table.Body>
                  </Table>
                </div>

              </TabPanel>

              <TabPanel header={"Aktywne subskrypcje"} style={{ padding: 0, margin: 0 }} >
                <Header as='h3' block>
                  AKTYWNE SUBSKRYPCJE
                </Header>

                <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                  <Table className='admtab' unstackable compact celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>LP</Table.HeaderCell>

                        <Table.HeaderCell>Nazwa</Table.HeaderCell>
                        <Table.HeaderCell>OD</Table.HeaderCell>
                        <Table.HeaderCell>Do</Table.HeaderCell>
                        <Table.HeaderCell>Ostatnie logowanie</Table.HeaderCell>
                        <Table.HeaderCell>P</Table.HeaderCell>
                        <Table.HeaderCell>Typ</Table.HeaderCell>
                        <Table.HeaderCell>Dodatkowe informacje</Table.HeaderCell>
                        <Table.HeaderCell>Nazwa</Table.HeaderCell>
                        <Table.HeaderCell>UserID</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.active_subscriptions && getLastEventsInfoForAdmV.active_subscriptions.length > 0 ? getLastEventsInfoForAdmV.active_subscriptions.map((row, i) =>
                        <Table.Row key={i}>
                          <Table.Cell>{i + 1}</Table.Cell>

                          <Table.Cell>{row.website_name}</Table.Cell>
                          <Table.Cell singleLine>{row.date_from}</Table.Cell>
                          <Table.Cell singleLine>{row.date_to}</Table.Cell>
                          <Table.Cell singleLine>{row.last_login_dt}</Table.Cell>
                          <Table.Cell>{row.prolong_count}</Table.Cell>
                          <Table.Cell>{row.subscription_extended}</Table.Cell>
                          <Table.Cell>{row.additional_info}</Table.Cell>
                          <Table.Cell>{row.user_name} {row.user_surname}</Table.Cell>
                          <Table.Cell>{row.user_id}</Table.Cell>

                        </Table.Row>
                      )
                        : ""}

                    </Table.Body>
                  </Table>
                </div>

              </TabPanel>
              <TabPanel header={"Tokeny"} style={{ padding: 0, margin: 0 }} >

                <Header as='h3' block>
                  UŻYTKOWNICY - TOKENY
                </Header>
                <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                  <Table className='admtab' unstackable compact celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Użytkownik</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Tokeny do wykorzystania</Table.HeaderCell>
                        <Table.HeaderCell>Ostatnie logowanie</Table.HeaderCell>
                        <Table.HeaderCell>Ostatnie transakcje</Table.HeaderCell>
                        <Table.HeaderCell>Ostatnie transakcje ilosc tokenów</Table.HeaderCell>
                        <Table.HeaderCell>Kwota</Table.HeaderCell>
                        <Table.HeaderCell>Ostatni turniej</Table.HeaderCell>
                        <Table.HeaderCell>UserID</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.users_tokens && getLastEventsInfoForAdmV.users_tokens.length > 0 ? getLastEventsInfoForAdmV.users_tokens.map((row, i) =>
                        <Table.Row key={i}>
                          <Table.Cell>{row.user_name} {row.user_surname}</Table.Cell>
                          <Table.Cell>{row.user_email}</Table.Cell>
                          <Table.Cell>{row.tokens_amount}</Table.Cell>
                          <Table.Cell>{row.last_login_dt}</Table.Cell>
                          <Table.Cell>{row.max_payment_confirm_dt}</Table.Cell>
                          <Table.Cell>{row.token_amount}</Table.Cell>
                          <Table.Cell>{row.price}</Table.Cell>
                          <Table.Cell>{row.max_create_tournament}</Table.Cell>
                          <Table.Cell>{row.user_id}</Table.Cell>

                        </Table.Row>
                      )
                        : ""}

                    </Table.Body>
                  </Table>
                </div>

              </TabPanel>
              <TabPanel header={"Użytkownicy"} style={{ padding: 0, margin: 0 }} >

                <Header as='h3' block>
                  UŻYTKOWNICY
                </Header>
                <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                  <Table className='admtab' unstackable compact celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Data ostatniego logowania</Table.HeaderCell>
                        <Table.HeaderCell>Użytkownik</Table.HeaderCell>
                        <Table.HeaderCell>UserID</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_user_logins && getLastEventsInfoForAdmV.last_user_logins.length > 0 ? getLastEventsInfoForAdmV.last_user_logins.map((row, i) =>
                        <Table.Row key={i}>
                          <Table.Cell>{row.last_login_dt}</Table.Cell>
                          <Table.Cell>{row.user_name} {row.user_surname}</Table.Cell>
                          <Table.Cell>{row.user_id}</Table.Cell>
                        </Table.Row>
                      )
                        : ""}

                    </Table.Body>
                  </Table>
                </div>

              </TabPanel>
              <TabPanel header={"Płatności"} style={{ padding: 0, margin: 0 }} >

                <Header as='h3' block>
                  PŁATNOŚCI
                </Header>
                <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                  <Table className='admtab' unstackable compact celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Miesiąc</Table.HeaderCell>
                        <Table.HeaderCell>Płatności A - Brutto</Table.HeaderCell>
                        <Table.HeaderCell>Płatności A - Na czysto</Table.HeaderCell>
                        <Table.HeaderCell>Płatności A - ilość</Table.HeaderCell>
                        <Table.HeaderCell>Płatności R - Brutto</Table.HeaderCell>
                        <Table.HeaderCell>Płatności R - Na czysto</Table.HeaderCell>
                        <Table.HeaderCell>Płatności R - ilość</Table.HeaderCell>
                        <Table.HeaderCell>Wydatki</Table.HeaderCell>
                        <Table.HeaderCell>Wydatki ilość</Table.HeaderCell>
                        <Table.HeaderCell>Suma na czysto</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_payments_summary && getLastEventsInfoForAdmV.last_payments_summary.length > 0 ? getLastEventsInfoForAdmV.last_payments_summary.map((row, i) =>
                        <Table.Row key={i}>
                          <Table.Cell>{row.p_month}</Table.Cell>
                          <Table.Cell>{row.total_transactions_gross}</Table.Cell>
                          <Table.Cell>{row.total_transactions_net}</Table.Cell>
                          <Table.Cell>{row.count_transactions}</Table.Cell>
                          <Table.Cell>{row.total_payable_services_gross}</Table.Cell>
                          <Table.Cell>{row.total_payable_services_net}</Table.Cell>
                          <Table.Cell>{row.count_payable_services}</Table.Cell>
                          <Table.Cell>{row.total_expenses_gross}</Table.Cell>
                          <Table.Cell>{row.count_expenses}</Table.Cell>
                          <Table.Cell>{row.final_sum}</Table.Cell>
                        </Table.Row>
                      )
                        : ""}

                    </Table.Body>
                  </Table>
                </div>

              </TabPanel>
              <TabPanel header={"Membersi"} style={{ padding: 0, margin: 0 }} >

                <Header as='h3' block>
                  MEMBERSI
                </Header>
                <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                  <Table className='admtab' unstackable compact celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Utworzony</Table.HeaderCell>
                        <Table.HeaderCell>NUMER TG</Table.HeaderCell>
                        <Table.HeaderCell>Użytkownik</Table.HeaderCell>

                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_members && getLastEventsInfoForAdmV.last_members.length > 0 ? getLastEventsInfoForAdmV.last_members.map((row, i) =>
                        <Table.Row key={i}>
                          <Table.Cell>{row.create_ts}</Table.Cell>
                          <Table.Cell>{row.lictl}</Table.Cell>
                          <Table.Cell>{row.member_name}</Table.Cell>

                        </Table.Row>
                      )
                        : ""}

                    </Table.Body>
                  </Table>
                </div>

              </TabPanel>
              <TabPanel header={"Errory"} style={{ padding: 0, margin: 0 }} >

                <TabView scrollable >
                  <TabPanel header={<>ERR_SQL - {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_errors && getLastEventsInfoForAdmV.last_errors.ERR_SQL ? getLastEventsInfoForAdmV.last_errors.ERR_SQL.length : ""} </>} style={{ padding: 0, margin: 0 }} >
                    {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_errors && getLastEventsInfoForAdmV.last_errors.ERR_SQL && getLastEventsInfoForAdmV.last_errors.ERR_SQL.length > 0 ? <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                      <Table className='admtab' unstackable compact celled>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>logtime</Table.HeaderCell>
                            <Table.HeaderCell>sql_error</Table.HeaderCell>
                            <Table.HeaderCell>sql_params</Table.HeaderCell>
                            <Table.HeaderCell>sql_query</Table.HeaderCell>
                            <Table.HeaderCell>user_id</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {getLastEventsInfoForAdmV.last_errors.ERR_SQL.map((row, i) =>
                            <Table.Row key={i} >
                              <Table.Cell width={1} singleLine><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.logtime}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.sql_error}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.sql_params}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.sql_query}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.user_id}</div></Table.Cell>

                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div> : ""}
                  </TabPanel>
                  <TabPanel header={<>ERR - {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_errors && getLastEventsInfoForAdmV.last_errors.ERR ? getLastEventsInfoForAdmV.last_errors.ERR.length : ""} </>} style={{ padding: 0, margin: 0 }} >
                    {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_errors && getLastEventsInfoForAdmV.last_errors.ERR && getLastEventsInfoForAdmV.last_errors.ERR.length > 0 ? <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                      <Table className='admtab' unstackable compact celled>
                        <Table.Header>
                          <Table.Row>

                            <Table.HeaderCell>logtime</Table.HeaderCell>
                            <Table.HeaderCell>method</Table.HeaderCell>
                            <Table.HeaderCell>request_data</Table.HeaderCell>
                            <Table.HeaderCell>response_data</Table.HeaderCell>
                            <Table.HeaderCell>user_id</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {getLastEventsInfoForAdmV.last_errors.ERR.map((row, i) =>
                            <Table.Row key={i} >
                              <Table.Cell width={1} singleLine><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.logtime}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.method}</div></Table.Cell>
                              <Table.Cell ><div style={{ overflow: "auto", height: 70, maxWidth: 800 }}>{row.request_data}</div></Table.Cell>
                              <Table.Cell ><div style={{ overflow: "auto", height: 70, maxWidth: 800 }}>{row.response_data}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.user_id}</div></Table.Cell>

                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div> : ""}
                  </TabPanel>
                  <TabPanel header={<>WARN - {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_errors && getLastEventsInfoForAdmV.last_errors.WARN ? getLastEventsInfoForAdmV.last_errors.WARN.length : ""} </>} style={{ padding: 0, margin: 0 }} >
                    {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_errors && getLastEventsInfoForAdmV.last_errors.WARN && getLastEventsInfoForAdmV.last_errors.WARN.length > 0 ? <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                      <Table className='admtab' unstackable compact celled>
                        <Table.Header>
                          <Table.Row>

                            <Table.HeaderCell>logtime</Table.HeaderCell>
                            <Table.HeaderCell>method</Table.HeaderCell>
                            <Table.HeaderCell>request_data</Table.HeaderCell>
                            <Table.HeaderCell>response_data</Table.HeaderCell>
                            <Table.HeaderCell>sql_error</Table.HeaderCell>
                            <Table.HeaderCell>sql_params</Table.HeaderCell>
                            <Table.HeaderCell>sql_query</Table.HeaderCell>
                            <Table.HeaderCell>user_id</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {getLastEventsInfoForAdmV.last_errors.WARN.map((row, i) =>
                            <Table.Row key={i} >
                              <Table.Cell width={1} singleLine><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.logtime}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.method}</div></Table.Cell>
                              <Table.Cell ><div style={{ overflow: "auto", height: 70, maxWidth: 800 }}>{row.request_data}</div></Table.Cell>
                              <Table.Cell ><div style={{ overflow: "auto", height: 70, maxWidth: 800 }}>{row.response_data}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.sql_error}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.sql_params}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.sql_query}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.user_id}</div></Table.Cell>

                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div> : ""}
                  </TabPanel>
                  <TabPanel header={<>INFO - {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_errors && getLastEventsInfoForAdmV.last_errors.INFO ? getLastEventsInfoForAdmV.last_errors.INFO.length : ""} </>} style={{ padding: 0, margin: 0 }} >
                    {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_errors && getLastEventsInfoForAdmV.last_errors.INFO && getLastEventsInfoForAdmV.last_errors.INFO.length > 0 ? <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                      <Table className='admtab' unstackable compact celled>
                        <Table.Header>
                          <Table.Row>

                            <Table.HeaderCell>logtime</Table.HeaderCell>
                            <Table.HeaderCell>method</Table.HeaderCell>
                            <Table.HeaderCell>request_data</Table.HeaderCell>
                            <Table.HeaderCell>response_data</Table.HeaderCell>
                            <Table.HeaderCell>user_id</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {getLastEventsInfoForAdmV.last_errors.INFO.map((row, i) =>
                            <Table.Row key={i} >
                              <Table.Cell width={1} singleLine><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.logtime}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.method}</div></Table.Cell>
                              <Table.Cell ><div style={{ overflow: "auto", height: 70, maxWidth: 800 }}>{row.request_data}</div></Table.Cell>
                              <Table.Cell ><div style={{ overflow: "auto", height: 70, maxWidth: 800 }}>{row.response_data}</div></Table.Cell>
                              <Table.Cell width={1}><div style={{ overflow: "auto", height: 70, maxWidth: 200 }}>{row.user_id}</div></Table.Cell>

                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div> : ""}
                  </TabPanel>
                </TabView>




              </TabPanel>
              <TabPanel header={"ErroryFront"} style={{ padding: 0, margin: 0 }} >

                <Header as='h3' block>
                  ERRORY FRONT
                </Header>
                <div style={{ overflowX: "auto", width: width < 700 ? (width - 140) : "100%" }} >
                  <Table className='admtab' unstackable compact celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>logtime</Table.HeaderCell>
                        <Table.HeaderCell>uuid</Table.HeaderCell>
                        <Table.HeaderCell>err_val</Table.HeaderCell>
                        <Table.HeaderCell>url_val</Table.HeaderCell>
                        <Table.HeaderCell>info_val</Table.HeaderCell>
                        <Table.HeaderCell>navigator_val</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {getLastEventsInfoForAdmV && getLastEventsInfoForAdmV.last_front_errors && getLastEventsInfoForAdmV.last_front_errors.length > 0 ? getLastEventsInfoForAdmV.last_front_errors.map((row, i) =>
                        <Table.Row key={i}>
                          <Table.Cell singleLine width={1}><div style={{ overflow: "auto", height: 100, maxWidth: 200 }}>{row.logtime}</div></Table.Cell>
                          <Table.Cell width={3}><div style={{ overflow: "auto", height: 100, maxWidth: 800 }}>{row.uuid}</div></Table.Cell>
                          <Table.Cell width={5}><div style={{ overflow: "auto", height: 100, maxWidth: 800 }}>{row.err_val}</div></Table.Cell>
                          <Table.Cell width={1} ><div style={{ overflow: "auto", height: 100, maxWidth: 200 }}>{row.url_val}</div></Table.Cell>
                          <Table.Cell width={1} ><div style={{ overflow: "auto", height: 100, maxWidth: 200 }}>{row.info_val}</div></Table.Cell>
                          <Table.Cell width={3}><div style={{ overflow: "auto", height: 100, maxWidth: 800 }}>{row.navigator_val}</div></Table.Cell>
                        </Table.Row>
                      )
                        : ""}

                    </Table.Body>
                  </Table>
                </div>

              </TabPanel>
            </TabView>
            <Divider />

          </>
          : ""
      }



      <Container style={{ marginTop: 100 }}>
        <CardInfoProduct numVisible={5} />
      </Container>

      <Divider />
      <MoveYtTG />

      <Container textAlign='center' style={{ marginTop: 40 }}>
        <Grid stackable >
          <Grid.Row verticalAlign='middle' stretched >
            <Grid.Column width={12}>
              <Header as='h3' icon>
                <Icon name='question circle' />
                Potrzebujesz Pomocy z Naszą Aplikacją?
                <Header.Subheader>
                  Hej, drogi użytkowniku!
                </Header.Subheader>
              </Header>

              <p>
                Jesteśmy bardzo wdzięczni, że wybrałeś naszą aplikację rozumiemy,<br />
                że czasami mogą pojawić się pytania, wątpliwości, ale nie martw się – jesteśmy tutaj, aby pomóc!
              </p>
              <Header as='h4'>Masz pytanie? Potrzebujesz wsparcia? A może szkolenia?</Header>
              <p>
                Nie wahaj się skontaktować z nami.<br />
                Jesteśmy gotowi odpowiedzieć na Twoje pytania oraz rozwiązać problemy.<br />
                Naszym celem jest zapewnienie Wam najlepszej jakości obsługi, więc nie krępuj się – pytaj śmiało!
              </p>
              <Header as='h4'>Potrzebujesz personalizacji rozgrywek?</Header>
              <p>
                Dla nas nie ma rzeczy niemożliwych, napisz do Nas!.
              </p>


              <Header as='h4'>Jak nas znaleźć? To proste!</Header>
              <p>
                W menu - zakładka kontakt lub w stopce strony.
              </p>


            </Grid.Column>
            <Grid.Column width={4} >
              <Image
                alt="puchartg"
                size='medium'
                src={pucharpatg1}
                style={{ margin: "0 auto" }}

              />

            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Container>



    </>
  )
}

export default ViewRoot;