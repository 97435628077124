import React, { useEffect, useState, useContext } from 'react';
import { Button, Grid, Header, Icon, Image, Modal } from 'semantic-ui-react';
import LogoTeamLigaGenerator from '../assets/tglogov6_small.png';

import { ConnectWS } from '../ConnectWS';

import AuthContext from '../context/authContext';
import { useToasts } from 'react-toast-notifications';
import { Markup } from 'interweave';


function ShowUserInfo() {

    const { addToast } = useToasts();
    const [open, setOpen] = useState(false)
    const authContext = useContext(AuthContext);
    const [rowData, setrowData] = useState(null);
    const [timeLeft, setTimeLeft] = useState(5);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {

        getUserInfo()
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {

        if(open){
            if (timeLeft > 0) {
                const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
                return () => clearTimeout(timer);
              } else {
                setDisabled(false);
              }
        }else{
            setTimeLeft(5)
            setDisabled(true)
        }
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [open,timeLeft])// eslint-disable-line react-hooks/exhaustive-deps


    function getUserInfo() {
        ConnectWS("/data/getFuncView", authContext.token, {}, function (response, logout) {
            if (logout === 1) {
            } else if (response && response.status && Number(response.status) === 200) {

                if (response.data && response.data.length > 0) {
                    setOpen(true)
                    setrowData(response.data[0])
                } else {
                    setOpen(false)
                    setrowData(null)
                }

            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }

        });
    }



    function closeModal() {
        ConnectWS("/data/setFuncViewSeen", authContext.token, { id: rowData.id }, function (response, logout) {
            if (logout === 1) {
            } else if (response && response.status && Number(response.status) === 200) {

                getUserInfo()

            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }

        });

    }



    return (
        rowData ?
            <Modal
                open={open}
                size='large'
                dimmer="blurring"
            >
                <Modal.Header>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as='h2' textAlign='center'>
                                    <Icon name='info circle' color="blue" />
                                    <Header.Content>Informacja</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" only='large screen'>
                                <Image floated='right' size='big' alt="logo" centered src={LogoTeamLigaGenerator} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>
                <Modal.Content style={{ backgroundColor: "#f5f5f5" }} >

                    <Modal.Description >
                        {rowData ? <Markup content={rowData.message_body} /> : ""}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>

                    <Button color='grey' disabled={disabled} onClick={() => {
                        closeModal()
                    }}>
                        {disabled ? `Odblokowanie za ${timeLeft}s` : "ZAMKNIJ"}
                    </Button>


                </Modal.Actions>
            </Modal>
            : ""
    )
}

export default ShowUserInfo;