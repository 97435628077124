import React, { useEffect, useState, useContext } from 'react';
import { Form, Icon, Button, Input, Label, Segment, Table } from 'semantic-ui-react';

import "cropperjs/dist/cropper.css";
import { ConnectWS } from '../ConnectWS';
import AuthContext from '../context/authContext';
import { useToasts } from 'react-toast-notifications';
import { confirmAlert } from 'react-confirm-alert';
import { noMemberId, noSubIsActive, removeEmojis, removeSpecialChars } from '../myfunctions';


function AddStaffToTeam(props) {
    const { addToast } = useToasts();

    const authContext = useContext(AuthContext);


    const [staffList, setstaffList] = useState(null);
    const [isAS, setisAS] = useState(true)

    const [formAddUsers, setFormAddUsers] = useState({
        person_name: "",
        staff_function: "",
        person_email: "",
    });
    const [loading, setloading] = useState(false);
    const [isMemberId, setisMemberId] = useState(null)

    useEffect(() => {

        if (props.openModalMembersTeams) {
            getData()
            setisMemberId(props.openModalMembersTeams.data.row.member_id)

        }
        checkSubIsActiv()
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [props.subTournamentJson, props.openModalMembersTeams])// eslint-disable-line react-hooks/exhaustive-deps


    function checkSubIsActiv() {
        try {
            if (authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended >= 0 && authContext.user.subscription_info.subscription_active) {
                setisAS(true)
            } else {
                // wylaczone sprwadzanie bo dla innych turniejów też można dodać 
                setisAS(true)
            }
        } catch (e) {
            setisAS(true)
        }
    }

    function removeMember(row) {
        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz skasować osobę ' + row.person_name + '?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => removeMemberAcc(row)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });

        function removeMemberAcc(row) {
            let subtournament_id = props.openModalMembersTeams.data.subtournament_id
            ConnectWS("/generator/removePersonFromSubtournamentTeam", authContext.token, { subtournament_id: subtournament_id, ...row }, function (response, logout) {

                if (logout === 1) {

                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    authContext.changeRefreshView(authContext.refreshView + 1)
                    getData()
                } else {

                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }

                }

            });
        }
    }

    function addDBStaff2(row) {
        setloading(true)


        let team_id = props.openModalMembersTeams.data.row.member_id;
        let subtournament_id = props.openModalMembersTeams.data.subtournament_id


        ConnectWS("/generator/addPersonToSubtournamentTeam", authContext.token, { team_id: team_id, subtournament_id: subtournament_id, ...row }, function (response, logout) {

            if (logout === 1) {


            } else if (response && response.status && Number(response.status) === 200) {

                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
                getData()
                // getData()
            } else {

                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
                //console.log(response);
            }


            setloading(false)


        });

    }


    function addDBStaff() {
        setloading(true)


        let team_id = props.openModalMembersTeams.data.row.member_id;
        let subtournament_id = props.openModalMembersTeams.data.subtournament_id
        if (!team_id) {
            return
        }

        ConnectWS("/generator/addPersonToSubtournamentTeam", authContext.token, { team_id: team_id, subtournament_id: subtournament_id, person_id: null, person_name: formAddUsers.person_name, staff_function: formAddUsers.staff_function, person_email: formAddUsers.person_email }, function (response, logout) {

            if (logout === 1) {


            } else if (response && response.status && Number(response.status) === 200) {
                setFormAddUsers({
                    person_name: "",
                    staff_function: "",
                    person_email: "",
                })
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
                getData()

            } else {

                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
                //console.log(response);
            }


            setloading(false)


        });

    }

    function getDataStaff() {
        try {
            let team_id = props.openModalMembersTeams.data.row.member_id;
            if (props.subTournamentJson && props.subTournamentJson.members && props.subTournamentJson.members.length > 0) {
                let ret = []
                props.subTournamentJson.members.forEach(element => {
                    if (element.member_id === team_id) {
                        ret = element.staff_list
                        return ret
                    }

                });
                return ret
            }
            return []
        } catch (e) {
            return []
        }

    }

    function getData() {
        let team_id = props.openModalMembersTeams.data.row.member_id;
        let subtournament_id = props.openModalMembersTeams.data.subtournament_id

        if (!team_id) {
            return
        }

        if (props.onlyView === 0) {
            ConnectWS("/generator/getAllPersonsInSubtournamentTeam", authContext.token, { subtournament_id: subtournament_id, team_id: team_id }, function (response, logout) {

                if (logout === 1) {

                } else if (response && response.status && Number(response.status) === 200) {
                    //

                    if (response.data.length > 0) {
                        setstaffList(response.data)
                    } else {
                        setstaffList(null)
                    }

                } else {

                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }
                    //console.log(response);
                }

            });
        } else {
            setstaffList(getDataStaff())
        }
    }

    return (
        <>


            <div style={{ overflowX: "auto", marginTop: 5, width: "100%" }} >
                <Table size='small' inverted={authContext.darkMode} celled striped compact unstackable >
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell textAlign='center' singleLine>OSOBA</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' singleLine>FUNKCJA</Table.HeaderCell>
                            {props.onlyView === 1 ? "" : <Table.HeaderCell textAlign='center' singleLine>EMAIL</Table.HeaderCell>}
                            {props.onlyView === 1 ? "" : <Table.HeaderCell width={1} textAlign='center' singleLine></Table.HeaderCell>}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {staffList && staffList.length > 0 ? staffList.map((row2, j) =>
                            <Table.Row key={j} >

                                <Table.Cell textAlign='center'>{row2.person_name}</Table.Cell>
                                <Table.Cell textAlign='center'>{row2.staff_function}</Table.Cell>
                                {props.onlyView === 1 ? "" : <Table.Cell textAlign='center'>{row2.person_email}</Table.Cell>}
                                {props.onlyView === 1 ? "" : <Table.Cell textAlign='center'>
                                    {row2.current_subtournament_person_exists === 1 ? <Icon name="trash" className='cursorPointer' onClick={() => removeMember(row2)} title="KASUJ OSOBĘ" />
                                        : <Icon name="add" className='cursorPointer' onClick={() => addDBStaff2(row2)} title="DODAJ OSOBĘ" />}

                                </Table.Cell>}
                            </Table.Row>
                        ) : <Table.Row  >
                            <Table.Cell textAlign='center' colSpan={3}>

                                Brak dodanych osób funkcyjnych
                            </Table.Cell>

                        </Table.Row>}
                    </Table.Body>
                </Table>
            </div>
            {props.onlyView === 1 ? "" :
                <Segment style={{ backgroundColor: "#f0f0f0" }}>
                    <Label color='blue' ribbon style={{ marginBottom: 10 }}>
                        <Icon name="user" /> Dodaj osobę do sztabu
                    </Label>

                    {isAS && isMemberId !== null ?
                        <Form size='tiny' onSubmit={() => addDBStaff()}>

                            <Form.Field
                                control={Input}
                                value={formAddUsers.person_name}
                                onChange={(event, data) => setFormAddUsers({ ...formAddUsers, person_name: removeSpecialChars(removeEmojis(data.value)) })}
                                required={true}
                                label="Osoba"
                                placeholder="Osoba"


                            />
                            <Form.Field
                                control={Input}
                                value={formAddUsers.staff_function}
                                onChange={(event, data) => setFormAddUsers({ ...formAddUsers, staff_function: removeSpecialChars(removeEmojis(data.value)) })}
                                required={true}
                                label="Funkcja (np. trener, asystent)"
                                placeholder="Funkcja"
                            />
                            <Form.Field
                                control={Input}
                                type='email'
                                value={formAddUsers.person_email}
                                onChange={(event, data) => setFormAddUsers({ ...formAddUsers, person_email: ((data.value)) })}
                                required={false}
                                label="Email"
                                placeholder="Email"
                            />


                            <Button loading={loading} disabled={loading} color='blue' size='small' fluid type='submit'>DODAJ OSOBĘ</Button>
                        </Form>
                        : !isAS ? noSubIsActive() : noMemberId()}

                </Segment>
            }

        </>
    )
}

export default AddStaffToTeam;