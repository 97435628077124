import React, { useState, useEffect, useContext, useRef } from 'react';
import { Icon, Header, Divider, Button, Message, Card, Statistic, Table, Image, Segment, Grid } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import useAuth from '../../../../hooks/useAuth';
import { ConnectWS } from '../../../../ConnectWS'
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { addDateToDate, changeInitialScale, checkDateStartBeforeNow, getlinkplayerprofile, loadingShowPlayerv2, mobileW, mobileWSmall } from '../../../../myfunctions';
import ShowImgAvatar from '../../../../tools/ShowImgAvatar';
import { confirmAlert } from 'react-confirm-alert';
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import ShowScoreDetails from '../../Bracket/ShowScoreDetails';
import ShowPagination from '../../../../tools/ShowPagination';
import { useHistory } from "react-router-dom";
import pucharpatg from '../../../../assets/pucharpatg.png';
import { TabView, TabPanel } from 'primereact/tabview';

function Duel1vs1(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const isDemo = useCheckUserIsDemo()
  let history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);


  const [rowData, setrowData] = useState(null);
  const [rowData2, setrowData2] = useState(null);
  let interval2 = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      changeInitialScale(0.7)
    }, 200);

    getMatches()
    interval2.current = setInterval(() => {
      getMatches()
    }, 5000);
    return () => { ClearAllInterval() }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  function ClearAllInterval() {
    clearInterval(interval2.current)
  }



  function removeMatch(match_id) {
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować pojedynek?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => removeMatchACC(match_id)
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });



    function removeMatchACC(match_id) {


      ConnectWS("/singular/removeMatch", authContext.token, { match_id: match_id }, function (response, logout) {

        if (logout === 1) {

        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          getMatches()
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }

        }

      });
    }
  }

  function linkDuel(match_id, member1_id) {
    return "/duel/lobby/dart/" + match_id + "/" + member1_id
  }

  function playDuel(game) {
    if (isDemo) {
      addToast('Zablokowane na koncie testowym', { appearance: 'info' });
      return
    }
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz rozpocząć pojedynek?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => playDuelACC(game)
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });



    function playDuelACC(game) {

      if (game === "dart") {
        ConnectWS("/singular/createMatch", authContext.token, { points_calc_system_id: 5 }, function (response, logout) {

          if (logout === 1) {
            addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
            setTimeout(() => {
              setAuth(false);
            }, 4000);
          } else if (response && response.status && Number(response.status) === 200) {

            let urltmp = linkDuel(response.data.match_id, response.data.member1_id)
            history.push(urltmp)

          } else {

            if (logout === 101) {
              addToast(response, { appearance: 'info' });
            } else if (logout === 102) {
              addToast(response, { appearance: 'warning' });
            } else {
              addToast(response, { appearance: 'error' });
            }

          }

        });


      }
    }

  }

  function getMatches() {

    ConnectWS("/singular/getMatches", authContext.token, {}, function (response, logout) {
      if (logout === 1) {
      } else if (response && response.status && Number(response.status) === 200) {
        if (response.data.length > 0) {
          let tmparr = []
          let tmparr2 = []
          response.data.forEach((row) => {
            // aktualne pojedynki
            if(checkDateStartBeforeNow(addDateToDate(row.create_ts, 1))){
              tmparr.push({ ...row })
            }

            //Moje pojedynki
            if(authContext && (authContext.user.userId === row.user1_id || authContext.user.userId === row.user2_id) && row.member1_score!==null && row.member2_score!==null){
              tmparr2.push({ ...row })
            }
            
          })
          setrowData({ current: tmparr, filter: null, data: tmparr, activePage: 1 })
          setrowData2({ current: tmparr2, filter: null, data: tmparr2, activePage: 1 })
        } else {
          setrowData({ current: [], filter: null, data: [], activePage: 1 })
        }
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }

    });


  }


  function moveToDuel(row) {

    if (row.member1_score !== null && row.member2_score !== null) {
      return false
    } else if (authContext.user.userId === row.user1_id || authContext.user.userId === row.user2_id) {
      return true
    } else if (row.member2_id === null) {
      return true
    } else {
      return false
    }


  }

  return (
    <div style={{ marginLeft: width < mobileWSmall() ? -20 : 0, marginRight: width < mobileWSmall() ? -20 : 0 }}>
      <Segment > <Header as='h5' textAlign='center' block>
        <Icon name='handshake' />
        <Header.Content>1VS1 - ZAGRAJ NORMALNIE LUB ONLINE</Header.Content>
      </Header>
       <Divider />
        <Message icon size='small'>
          <Image
            alt="puchartg"
            style={{ width: 70, marginRight: 10 }}
            src={pucharpatg}

          />
          <Message.Content>
            <Message.Header>  Witaj w panelu gracza 1vs1! </Message.Header>
            <div >
              Tutaj możesz rozpocząć nowy pojedynek lub dołączyć do istniejącego.<br />
              Jeśli masz pomysł na inny rodzaj gry, który chciałbyś zagrać, daj nam znać!
              <br /><br />
              <b>Pojedynki zostaja automatycznie usunięte jeżeli:</b><br />
              1. Przeciwnik nie dołączy do pojedynku w ciągu 1 godziny.<br />
              2. Pojedynek nie zostanie rozstrzygnięty w ciągu 6 godzin.
            </div>
          </Message.Content>
        </Message>
        <Card.Group centered >
          <Card onClick={() => playDuel("dart")} link style={{ boxShadow: "none", background: "linear-gradient(140deg, rgba(221,75,57,0.5) 0%, rgba(225,225,225,1) 28%, rgba(225,225,225,1) 53%, rgba(225,225,225,1) 100%)" }}>
            <div className="ribbonWrap ribbon-top-left"><span style={{ backgroundColor: "#dd4b39" }}>DART</span></div>
            <Card.Content textAlign='center'>

              <Card.Header  >🎯</Card.Header>
              <Card.Description >
                <Statistic size='tiny' >
                  <Statistic.Value> ZAGRAJ </Statistic.Value>
                  <Statistic.Label style={{ lineHeight: "15px", fontSize: 14, marginTop: 10 }}>NOWY POJEDYNEK 1VS1</Statistic.Label>
                </Statistic>
              </Card.Description>
            </Card.Content>
          </Card>

        </Card.Group>


        <Divider />


        <TabView activeIndex={activeIndex} scrollable onTabChange={(e) => {
          setActiveIndex(e.index)
        }}>

          <TabPanel header={"AKTUALNE POJEDYNKI Z 24H"} style={{padding:0,margin:0}} >
          
          <div style={{ overflowX: "auto", width: "100%" }} >
          {width < mobileWSmall() ?

            rowData && rowData.current.length > 0 ?
              rowData.current.map((row, i) =>
                <Table key={i} size='small' className='smalltab' celled striped compact unstackable >
                  <Table.Header>
                    <Table.HeaderCell colSpan={4} textAlign='center'>
                      <Grid style={{ margin: 0, padding: 0 }}>
                        <Grid.Row style={{ margin: 0, padding: 0 }} columns={2} verticalAlign='middle'>
                          <Grid.Column>
                            <Icon name="handshake" /> {row.points_calc_system} <br /><Icon name="calendar" /> {row.create_ts}
                          </Grid.Column>

                          <Grid.Column>
                            {moveToDuel(row) ? <> {row.private ? <span style={{ fontSize: 12 }}><Icon name="lock" color='red' /> PRYWATNY</span> : ""}<Button color='blue' size='mini' fluid onClick={() => window.open(linkDuel(row.match_id, row.member1_id), '_target')}>Przejdź do pojedynku</Button></> : <Button color='grey' size='mini' fluid disabled={true}>Przejdź do pojedynku</Button>}

                          </Grid.Column>

                        </Grid.Row>
                      </Grid>
                    </Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>

                    <Table.Row >

                      <Table.Cell textAlign='center' singleLine width={6}>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <figure className='imgPlayer'><span style={{ marginRight: 5 }}>{getlinkplayerprofile(row.member1_id, row.member1_name, 1, width < mobileWSmall() ? 13 : 15)}</span><ShowImgAvatar urlpic={row.member1_img} mtype={"0"} msize={'mini'} /><figcaption></figcaption></figure>
                        </div>
                      </Table.Cell>
                      <Table.Cell textAlign='center' width={2}>{row.member1_score !== null && row.member2_score !== null ? row.member1_score + ":" + row.member2_score : row.match_details !== null ? "" : "-:-"}
                        <ShowScoreDetails matche_info={{ member1_name: row.member1_name, member2_name: row.member2_name, member1_score: row.member1_score, member2_score: row.member2_score }} systemId={row.points_calc_system_id} match_detailstmp={{ subtournament_match_id: row.match_id, bracket: false, match_details: row.match_details, match_member1_id: row.member1_id, match_member2_id: row.member2_id, reverse: false, checkboxMD: true, member1_video: row.member1_video, member2_video: row.member2_video }} />
                      </Table.Cell>
                      <Table.Cell textAlign='center' width={6} singleLine>{row.member2_id === null ? width < mobileWSmall() ? <> <Icon name="spinner" loading /> Oczekuje</> : loadingShowPlayerv2() : <figure className='imgPlayer'><ShowImgAvatar urlpic={row.member2_img} mtype={"0"} msize={'mini'} /><figcaption>{getlinkplayerprofile(row.member2_id, row.member2_name, 1, width < mobileWSmall() ? 13 : 15)}</figcaption></figure>}</Table.Cell>
                      <Table.Cell textAlign='center' width={2}>
                        {(authContext && authContext.user.userId === row.user1_id) || (authContext.user && authContext.user.admin === 1) ? <Icon name="trash" className='cursorPointer' onClick={() => removeMatch(row.match_id)} title="KASUJ POJEDYNEK" /> : ""}

                      </Table.Cell>
                    </Table.Row>


                  </Table.Body>


                </Table>
              ) : ""

            :
            <Table size='small' className='smalltab' celled striped compact unstackable >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell singleLine width={1} textAlign='center'>DATA UTWORZENIA</Table.HeaderCell>
                  <Table.HeaderCell singleLine width={1} textAlign='center'>RODZAJ</Table.HeaderCell>
                  <Table.HeaderCell singleLine textAlign='center'>ZAWODNIK 1</Table.HeaderCell>
                  <Table.HeaderCell singleLine textAlign='center'>WYNIK</Table.HeaderCell>
                  <Table.HeaderCell singleLine textAlign='center'>ZAWODNIK 2</Table.HeaderCell>
                  <Table.HeaderCell singleLine width={1} textAlign='center'>POJEDYNEK</Table.HeaderCell>
                  <Table.HeaderCell singleLine width={1} textAlign='center'></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rowData && rowData.current.length > 0 ?
                  rowData.current.map((row, i) =>
                    <Table.Row key={i}>
                      <Table.Cell textAlign='center'>{row.create_ts}</Table.Cell>
                      <Table.Cell textAlign='center'>{row.points_calc_system} </Table.Cell>
                      <Table.Cell textAlign='center' singleLine>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <figure className='imgPlayer'><span style={{ marginRight: 5 }}>{getlinkplayerprofile(row.member1_id, row.member1_name)}</span><ShowImgAvatar urlpic={row.member1_img} mtype={"0"} msize={'mini'} /><figcaption></figcaption></figure>
                        </div>
                      </Table.Cell>
                      <Table.Cell textAlign='center' width={1}>{row.member1_score !== null && row.member2_score !== null ? row.member1_score + ":" + row.member2_score : row.match_details !== null ? "" : "-:-"}
                        <ShowScoreDetails matche_info={{ member1_name: row.member1_name, member2_name: row.member2_name, member1_score: row.member1_score, member2_score: row.member2_score }} systemId={row.points_calc_system_id} match_detailstmp={{ subtournament_match_id: row.match_id, bracket: false, match_details: row.match_details, match_member1_id: row.member1_id, match_member2_id: row.member2_id, reverse: false, checkboxMD: true, member1_video: row.member1_video, member2_video: row.member2_video }} />
                      </Table.Cell>
                      <Table.Cell textAlign='center' singleLine>{row.member2_id === null ? loadingShowPlayerv2() : <figure className='imgPlayer'><ShowImgAvatar urlpic={row.member2_img} mtype={"0"} msize={'mini'} /><figcaption>{getlinkplayerprofile(row.member2_id, row.member2_name)}</figcaption></figure>}</Table.Cell>
                      <Table.Cell textAlign='center' width={3}>{moveToDuel(row) || (authContext.user && authContext.user.admin === 1) ? <> {row.private ? <span style={{ fontSize: 12 }}><Icon name="lock" color='red' /> PRYWATNY</span> : ""}<Button color='blue' size='mini' fluid onClick={() => window.open(linkDuel(row.match_id, row.member1_id), '_target')}>Przejdź do pojedynku</Button></> : <Button color='grey' size='mini' fluid disabled={true}>Przejdź do pojedynku</Button>}</Table.Cell>
                      <Table.Cell textAlign='center' width={1}>{(authContext && authContext.user.userId === row.user1_id) || (authContext.user && authContext.user.admin === 1) ? <Icon name="trash" className='cursorPointer' onClick={() => removeMatch(row.match_id)} title="KASUJ POJEDYNEK" /> : ""}</Table.Cell>
                    </Table.Row>
                  ) : ""}
              </Table.Body>


            </Table>
          }
          {rowData && rowData.current.length > 0 ? <ShowPagination myTab={rowData} setMyTab={setrowData} rowCurrent={width < mobileW() ? 5 : 15} /> : ""}
        </div>


          </TabPanel>
          <TabPanel header={"MOJE ROZEGRANE POJEDYNKi"} >
           

          <div style={{ overflowX: "auto", width: "100%" }} >
          {width < mobileWSmall() ?

            rowData2 && rowData2.current.length > 0 ?
              rowData2.current.map((row, i) =>
                <Table key={i} size='small' className='smalltab' celled striped compact unstackable >
                  <Table.Header>
                    <Table.HeaderCell colSpan={4} textAlign='center'>
                      <Grid style={{ margin: 0, padding: 0 }}>
                        <Grid.Row style={{ margin: 0, padding: 0 }} columns={1} verticalAlign='middle'>
                          <Grid.Column>
                            <Icon name="handshake" /> {row.points_calc_system} <br /><Icon name="calendar" /> {row.create_ts}
                          </Grid.Column>

                         
                        </Grid.Row>
                      </Grid>
                    </Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>

                    <Table.Row >

                      <Table.Cell textAlign='center' singleLine width={6}>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <figure className='imgPlayer'><span style={{ marginRight: 5 }}>{getlinkplayerprofile(row.member1_id, row.member1_name, 1, width < mobileWSmall() ? 13 : 15)}</span><ShowImgAvatar urlpic={row.member1_img} mtype={"0"} msize={'mini'} /><figcaption></figcaption></figure>
                        </div>
                      </Table.Cell>
                      <Table.Cell textAlign='center' width={2}>{row.member1_score !== null && row.member2_score !== null ? row.member1_score + ":" + row.member2_score : row.match_details !== null ? "" : "-:-"}
                        <ShowScoreDetails matche_info={{ member1_name: row.member1_name, member2_name: row.member2_name, member1_score: row.member1_score, member2_score: row.member2_score }} systemId={row.points_calc_system_id} match_detailstmp={{ subtournament_match_id: row.match_id, bracket: false, match_details: row.match_details, match_member1_id: row.member1_id, match_member2_id: row.member2_id, reverse: false, checkboxMD: true, member1_video: row.member1_video, member2_video: row.member2_video }} />
                      </Table.Cell>
                      <Table.Cell textAlign='center' width={6} singleLine>{row.member2_id === null ? width < mobileWSmall() ? <> <Icon name="spinner" loading /> Oczekuje</> : loadingShowPlayerv2() : <figure className='imgPlayer'><ShowImgAvatar urlpic={row.member2_img} mtype={"0"} msize={'mini'} /><figcaption>{getlinkplayerprofile(row.member2_id, row.member2_name, 1, width < mobileWSmall() ? 13 : 15)}</figcaption></figure>}</Table.Cell>
                     
                    </Table.Row>


                  </Table.Body>


                </Table>
              ) : ""

            :
            <Table size='small' className='smalltab' celled striped compact unstackable >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell singleLine width={1} textAlign='center'>DATA UTWORZENIA</Table.HeaderCell>
                  <Table.HeaderCell singleLine width={1} textAlign='center'>RODZAJ</Table.HeaderCell>
                  <Table.HeaderCell singleLine textAlign='center'>ZAWODNIK 1</Table.HeaderCell>
                  <Table.HeaderCell singleLine textAlign='center'>WYNIK</Table.HeaderCell>
                  <Table.HeaderCell singleLine textAlign='center'>ZAWODNIK 2</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rowData2 && rowData2.current.length > 0 ?
                  rowData2.current.map((row, i) =>
                    <Table.Row key={i}>
                      <Table.Cell textAlign='center'>{row.create_ts}</Table.Cell>
                      <Table.Cell textAlign='center'>{row.points_calc_system} </Table.Cell>
                      <Table.Cell textAlign='center' singleLine>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <figure className='imgPlayer'><span style={{ marginRight: 5 }}>{getlinkplayerprofile(row.member1_id, row.member1_name)}</span><ShowImgAvatar urlpic={row.member1_img} mtype={"0"} msize={'mini'} /><figcaption></figcaption></figure>
                        </div>
                      </Table.Cell>
                      <Table.Cell textAlign='center' width={1}>{row.member1_score !== null && row.member2_score !== null ? row.member1_score + ":" + row.member2_score : row.match_details !== null ? "" : "-:-"}
                        <ShowScoreDetails matche_info={{ member1_name: row.member1_name, member2_name: row.member2_name, member1_score: row.member1_score, member2_score: row.member2_score }} systemId={row.points_calc_system_id} match_detailstmp={{ subtournament_match_id: row.match_id, bracket: false, match_details: row.match_details, match_member1_id: row.member1_id, match_member2_id: row.member2_id, reverse: false, checkboxMD: true, member1_video: row.member1_video, member2_video: row.member2_video }} />
                      </Table.Cell>
                      <Table.Cell textAlign='center' singleLine>{row.member2_id === null ? loadingShowPlayerv2() : <figure className='imgPlayer'><ShowImgAvatar urlpic={row.member2_img} mtype={"0"} msize={'mini'} /><figcaption>{getlinkplayerprofile(row.member2_id, row.member2_name)}</figcaption></figure>}</Table.Cell>
                    </Table.Row>
                  ) : ""}
              </Table.Body>


            </Table>
          }
          {rowData2 && rowData2.current.length > 0 ? <ShowPagination myTab={rowData2} setMyTab={setrowData2} rowCurrent={width < mobileW() ? 5 : 15} /> : ""}
        </div>


          </TabPanel>

        </TabView>

        
      </Segment>
    </div>
  )
}

export default Duel1vs1;