import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy'
import { Grid, Label, Segment } from 'semantic-ui-react';
import AuthContext from '../context/authContext';


function ShowVideoPlayer({ arrDataVideo, viewSmall }) {
    const [arrData, setarrData] = useState([])

    const authContext = useContext(AuthContext);


    useEffect(() => {
       
        if (arrDataVideo !== null && typeof arrDataVideo !== "undefined" && arrDataVideo.length > 0) {
            setarrData(arrDataVideo)
        } else {
            setarrData([])
        }
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [arrDataVideo])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        arrData.length > 1 ?
            <Grid stackable>
                <Grid.Row columns={arrData.length ? arrData.length>4 ? 2 : arrData.length : 1} >
                    {arrData.map((row, i) =>
                        <Grid.Column key={i}   style={{marginBottom:10}} >
                            <Segment raised style={{ marginBottom: 10, maxWidth: 800, margin: "0 auto", backgroundColor: authContext.darkMode ? "#2e2d2d" : "#fff", overflow:"auto" }} color='blue'>
                                <Label style={{ marginBottom: 5 }} color='blue' ribbon>
                                    {row.name}
                                </Label>
                                <ReactPlayer controls={true} url={row.url} width={"100%"} style={{ overflow:"hidden"}} height={arrData.length > 3 ? 200 : 350} />
                            </Segment>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
            : arrData.length === 1 ? <Segment raised style={{ marginBottom: 10, maxWidth: 800, margin: "0 auto", backgroundColor: authContext.darkMode ? "#2e2d2d" : "#fff" }} color='blue'>
                <Label style={{ marginBottom: 5 }} color='blue' ribbon>
                    {arrData[0].name}
                </Label>
                <ReactPlayer controls={true} url={arrData[0].url} width={"100%"} height={350} style={{ overflow:"hidden"}}/>
            </Segment> : ""
    )
}

export default ShowVideoPlayer;