import React, { useState, useEffect } from 'react';
import { Dropdown, Grid, Icon, Image, Input, Message, Segment, Table } from 'semantic-ui-react';


import ShowImgAvatar from '../ShowImgAvatar';
import { getlinkplayerprofile } from '../../myfunctions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { t } from 'i18next';


function EditLeagueMatchDetailsExMatcheVersion2({ match_details_ex, setmatch_details_ex, members, row, onlyView }) {
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars


    const [team1Members, setteam1Members] = useState([])
    const [team2Members, setteam2Members] = useState([])


    useEffect(() => {
    
        if (match_details_ex) {
            createMatch_details_ex_tmp()
        }
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps




    function mergeArrays(tap1, tap2) {
        // Tworzymy mapę dla szybszego dostępu do elementów z tap2
       
        try{
            if(tap1 && tap1.length>0 && tap2 && tap2.length>0){
                const tap2Map = new Map(tap2.map(item => [item.member_id, item.value]));
    
                // Przechodzimy przez tap1 i łączymy dane z tap2 (jeśli istnieją)
                return tap1.map(item => {
                    const valueFromTap2 = tap2Map.get(item.member_id);
                    return {
                        ...item,
                        value: valueFromTap2 !== undefined ? valueFromTap2 : item.value
                    };
                });
            }
            return tap1
        }catch(e){
            console.log(e)
            return tap1
        }
        
    }

    function createMatch_details_ex_tmp() {
        let team1 = []
        let team2 = []
        let team1v1 = []
        let team2v1 = []
        if (members && members.length > 0) {

            members.forEach((rowtmp) => {

                if (rowtmp.member_id === row.member1_id) {
                    if (rowtmp.members_list && rowtmp.members_list.length > 0) {
                        rowtmp.members_list.forEach((row1) => {
                            team1.push({ ...row1, team_id: rowtmp.member_id })
                            team1v1.push({ member_id: row1.member_id, value: null })
                        })
                    }
                } else if (rowtmp.member_id === row.member2_id) {
                    if (rowtmp.members_list && rowtmp.members_list.length > 0) {
                        rowtmp.members_list.forEach((row1) => {
                            team2.push({ ...row1, team_id: rowtmp.member_id })
                            team2v1.push({ member_id: row1.member_id, value: null })
                        })
                    }
                }
            })

            setteam1Members(team1)
            setteam2Members(team2)
        }



        let jsonTmp = JSON.parse(JSON.stringify(match_details_ex))
       
        if (match_details_ex && match_details_ex.members ) {
            for (const [key] of Object.entries(match_details_ex.members).sort((a, b) => a[1].sort - b[1].sort)) {

                jsonTmp.members[key].team1_id = team1.length>0 ?  team1[0].team_id : null
                jsonTmp.members[key].team1_members = mergeArrays(team1v1, match_details_ex.members[key].team1_members,key)
                jsonTmp.members[key].team2_id = team2.length>0 ?  team2[0].team_id : null
                jsonTmp.members[key].team2_members = mergeArrays(team2v1, match_details_ex.members[key].team2_members,key)
            }


        }
   
   
        setmatch_details_ex(jsonTmp)
    }






    const optionsBoolCards = [
        { key: 'yellow', text: '', value: 'yellow', image: { avatar: true, src: process.env.REACT_APP_PUBLIC_URL + "/img/icon/yellowc.png" } },
        { key: 'red', text: '', value: 'red', image: { avatar: true, src: process.env.REACT_APP_PUBLIC_URL + "/img/icon/redc.png" }, },
        { key: 'yellow-red', text: '', value: 'yellow-red', image: { avatar: true, src: process.env.REACT_APP_PUBLIC_URL + "/img/icon/cardsyr.png" }, },
        { key: 'brak', text: 'Brak', value: null }
    ]

    function getCardsImg(value) {
        if (value === "yellow") {
            return <Image style={{ height: 20, margin: "0 auto" }} src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/yellowc.png"} />
        } else if (value === "red") {
            return <Image style={{ height: 20, margin: "0 auto" }} src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/redc.png"} />
        } else if (value === "yellow-red") {
            return <Image style={{ height: 20, margin: "0 auto" }} src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/cardsyr.png"} />
        } else {
            return ""
        }

    }

    function getIconGoals(value) {
        try {
            let arr = []
            if (Number(value) > 0) {
                for (let i = 0; i < Number(value); i++) {
                    arr.push(<Icon name="soccer" />)
                }
                return arr
            }
            return ""
        } catch (e) {
            return value
        }
    }

    function updateJsonOrder(value, key, team_nr, team_id) {

        let jsonTmp = JSON.parse(JSON.stringify(match_details_ex))

        if (team_nr === 1) {
            jsonTmp.others[key].team1_id = team_id
            jsonTmp.others[key].team1_value = value === "" ? null : value
        } else if (team_nr === 2) {
            jsonTmp.others[key].team2_id = team_id
            jsonTmp.others[key].team2_value = value === "" ? null : value
        } else {

        }
        setmatch_details_ex({ ...jsonTmp })

    }

    function updateJson(findIndex, row1, team_id, value) {
   
        let jsonTmp = JSON.parse(JSON.stringify(match_details_ex))
        if (row1.team1_id === team_id) {
            jsonTmp.members[row1.id].team1_members[findIndex].value = value === "" ? null : row1.type==="inputnumber" ? Number(value) : value
        } else if (row1.team2_id === team_id) {
            jsonTmp.members[row1.id].team2_members[findIndex].value = value === "" ? null : row1.type==="inputnumber" ? Number(value) : value
        } else {

        }

        setmatch_details_ex({ ...jsonTmp })

    }


    function getValuRow(member_team_id, row1, team_id) {
        try {
            if (row1.team1_id === team_id) {

                let findIndex = row1.team1_members.findIndex(({ member_id }) => member_id === member_team_id.member_id)
                if (findIndex >= 0) {

                    if (onlyView) {
                        return row1.type === "boolimagecard" ? getCardsImg(row1.team1_members[findIndex].value) : row1.type === "inputboolicon" ? row1.team1_members[findIndex].value === true ? <Icon color='yellow' name={row1.icon} /> : "" : row1.type === "inputbool" ? row1.team1_members[findIndex].value === null ? <Icon name="x" color='grey' title="NIE" /> : row1.team1_members[findIndex].value === true ? <Icon name="check" color='green' title="TAK" /> : <Icon name="x" color='grey' title="NIE" /> : row1.id === "points_scored_goals" ? getIconGoals(row1.team1_members[findIndex].value) : row1.team1_members[findIndex].value
                    } else if (row1.type === "inputbool" || row1.type === "inputboolicon") {
                        return row1.team1_members[findIndex].value ? <Icon className='cursorPointer' name="check" color='green' onClick={() => updateJson(findIndex, row1, team_id, false)} /> : <Icon name="x" className='cursorPointer' onClick={() => updateJson(findIndex, row1, team_id, true)} />

                    } else if (row1.type === "boolimagecard") {
                        return <Dropdown inline options={optionsBoolCards} value={row1.team1_members[findIndex].value} onChange={(e, d) => updateJson(findIndex, row1, team_id, d.value)} />
                    } else {
                        return <Input style={{ width: row1.type === "inputnumber" ? 80 : 120 }} type={row1.type === "inputnumber" ? "number" : row1.type.type === "inputurl" ? "url" : "text"} value={row1.team1_members[findIndex].value} onChange={(e, d) => updateJson(findIndex, row1, team_id, d.value)} />
                    }
                } else {
                    return "?"
                }

 

            } else if (row1.team2_id === team_id) {
                let findIndex = row1.team2_members.findIndex(({ member_id }) => member_id === member_team_id.member_id)
                if (findIndex >= 0) {

                    if (onlyView) {
                        return row1.type === "boolimagecard" ? getCardsImg(row1.team2_members[findIndex].value) : row1.type === "inputboolicon" ? row1.team2_members[findIndex].value === true ? <Icon color='yellow' name={row1.icon} /> : "" : row1.type === "inputbool" ? row1.team2_members[findIndex].value === null ? <Icon name="x" color='grey' title="NIE" /> : row1.team2_members[findIndex].value === true ? <Icon name="check" color='green' title="TAK" /> : <Icon name="x" color='grey' title="NIE" /> : row1.id === "points_scored_goals" ? getIconGoals(row1.team2_members[findIndex].value) : row1.team2_members[findIndex].value
                    } else if (row1.type === "inputbool" || row1.type === "inputboolicon") {
                        return row1.team2_members[findIndex].value ? <Icon className='cursorPointer' name="check" color='green' onClick={() => updateJson(findIndex, row1, team_id, false)} /> : <Icon name="x" className='cursorPointer' onClick={() => updateJson(findIndex, row1, team_id, true)} />

                    } else if (row1.type === "boolimagecard") {
                        return <Dropdown inline options={optionsBoolCards} value={row1.team2_members[findIndex].value} onChange={(e, d) => updateJson(findIndex, row1, team_id, d.value)} />
                    } else {
                        return <Input style={{ width: row1.type === "inputnumber" ? 80 : 120 }} type={row1.type === "inputnumber" ? "number" : row1.type.type === "inputurl" ? "url" : "text"} value={row1.team2_members[findIndex].value} onChange={(e, d) => updateJson(findIndex, row1, team_id, d.value)} />
                    }
                } else {
                    return "?"
                }

            } else {
                return "?"
            }

        } catch (e) {
            return "?"
        }
    }

    function getValueOther(team_id, json, team_nr) {
        if (json.team1_id === null && team_nr === 1) {
            return null
        } else if (json.team2_id === null && team_nr === 2) {
            return null
        } else if (json.team1_id !== null && team_nr === 1) {
            return json.team1_value
        } else if (json.team2_id !== null && team_nr === 2) {
            return json.team2_value
        }
    }

    function getOthersInfo(team_id, team_nr) {
        try {
            const rows = [];

            if (match_details_ex && match_details_ex.others) {
                for (const [key, value] of Object.entries(match_details_ex.others).sort((a, b) => a[1].sort - b[1].sort)) {

                    if (onlyView) {
                        rows.push(
                            <div><Icon color='grey' name={value.icon} />{t("stats." + value.name)}: {getValueOther(team_id, value, team_nr) ? getValueOther(team_id, value, team_nr) : <i>{t("BrakDanych")}</i>}</div>
                        )
                    } else {
                        if (value.type === 'inputnumber') {
                            rows.push(
                                <div><Icon color='grey' name={value.icon} />{t("stats." + value.name)}: <Input type="number" value={getValueOther(team_id, value, team_nr)} onChange={(e, d) => updateJsonOrder(d.value, key, team_nr, team_id)} /></div>
                            )
                        } else {
                            rows.push(
                                <div><Icon color='grey' name={value.icon} />{t("stats." + value.name)}: <Input value={getValueOther(team_id, value, team_nr)} onChange={(e, d) => updateJsonOrder(d.value, key, team_nr, team_id)} /></div>
                            )
                        }
                    }

                }
                return <Segment textAlign='left'>{rows}</Segment>
            }
        } catch (e) {
            console.log(e)
        }

    }

    function getAllMembers(team_id, member_img_file, team_nr) {
        try {

            let team = []
            if (team_nr === 1) {
                team = team1Members
            } else if (team_nr === 2) {
                team = team2Members
            }

            const rows = [];
            const cells = [];

            for (const [key, value] of Object.entries(match_details_ex.members).sort((a, b) => a[1].sort - b[1].sort)) {
                if(!value.hide_viewers===true || onlyView===0){
                    rows.push(
                        <Table.HeaderCell textAlign='center' width={1} style={{ lineHeight: "12px", textTransform: "uppercase" }}>{t("stats." + value.name)}</Table.HeaderCell>
                    ) 
                    cells.push(
                        { ...value, id: key }
                    )
                }
                
                

            }
            if (team.length > 0) {
                return <><div style={{ overflowY: "auto", width: "100%" }} >
                    <Table size='small' celled striped compact unstackable >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={1} textAlign='center'>LP</Table.HeaderCell>
                                <Table.HeaderCell width={1} textAlign='center'>NR</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' style={{ lineHeight: "12px" }}><figure className='imgPlayer'><ShowImgAvatar urlpic={member_img_file} mtype={"USER"} /><figcaption> {t("WidokTurnieju.zawodnik")}</figcaption></figure></Table.HeaderCell>
                                {rows}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {team.map((row, i) =>
                                <Table.Row key={i}  >
                                    <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                    <Table.Cell textAlign='center'>{row.jersey_nr}</Table.Cell>
                                    <Table.Cell><figure className='imgPlayer'><ShowImgAvatar urlpic={row.member_img_file} mtype={"0"} msize={"mini"} /><figcaption>{getlinkplayerprofile(null, row.member_name, 0, 14)}</figcaption></figure></Table.Cell>
                                    {cells.map((row1, j) =>
                                        <Table.Cell width={1} textAlign='center'>{getValuRow(row, row1, team_id)}</Table.Cell>
                                    )}
                                </Table.Row>
                            )}

                        </Table.Body>
                    </Table>
                </div>

                    {getOthersInfo(team_id, team_nr)}
                </>
            } else {
                return <Message>{t("WidokTurnieju.brak_przypisanych_zawodnikow")}</Message>
            }

        } catch (e) {
            return ""
        }
    }


    return (
        <div style={{ marginBottom: 20 }}>
            {match_details_ex && match_details_ex.version === 2 ?
                <Grid stackable style={{ marginBottom: 10 }}>
                    <Grid.Row>
                        <Grid.Column width={width < 1100 ? 16 : 8} textAlign='center' >
                            {getAllMembers(row.member1_id, row.member1_img_file, 1)}
                        </Grid.Column>

                        <Grid.Column width={width < 1100 ? 16 : 8} textAlign='center' >
                            <div style={{ marginTop: width < 1100 ? 20 : 0 }}>
                                {getAllMembers(row.member2_id, row.member2_img_file, 2)}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                : ""}
            
        </div>
    )
}

export default EditLeagueMatchDetailsExMatcheVersion2;