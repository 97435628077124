import React, { useState } from "react"
import { useEffect } from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "react-google-places-suggest"
import { Form, Input } from "semantic-ui-react"

const MY_API_KEY = "AIzaSyB8EgdGSHZGzbY0cruJQrFM5ryozh_36lY"

function GoogleSuggest({ form, setForm,valueIn }) {

    const [state, setState] = useState({
        search: "",
        value: "",
    })

    const [stateCurr, setStateCurr] = useState({
        value: 0,
    })



    const handleInputChange = e => {
        setState({ search: e.target.value, value: e.target.value })
        setStateCurr({ value: 1 })
        setForm({ ...form, InputValuePlace: null })   
    }

    const handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
        //console.log(geocodedPrediction, originalPrediction) // eslint-disable-line
        let location = {lat: 0, lng: 0}
        try{
            location = JSON.parse(JSON.stringify(geocodedPrediction.geometry.location))
        }catch(e){

        }
        
        //let niedziala1 = { address: "Polska", description: "Polska", place_id: "ChIJuwtkpGSZAEcR6lXMScpzdQk", lat:"51.919438",lng:"19.145136" }
        //let niedziala2 = {value:"Polska",data:niedziala1}

        let jsonw = ({ address: geocodedPrediction.formatted_address, description: originalPrediction.structured_formatting.main_text, place_id: originalPrediction.place_id, ...location })
       
        //console.log({value:originalPrediction.structured_formatting.main_text + " " + geocodedPrediction.formatted_address,data:jsonw})
        setForm({ ...form, InputValuePlace: jsonw })
        setStateCurr({ value: 2 })

        setState({
            search: "",
            value: originalPrediction.structured_formatting.main_text + " " + geocodedPrediction.formatted_address,
        })

    }

    const handleNoResult = () => {
        console.log("No results for ", state.search)
    }

    const handleStatusUpdate = status => {
      // console.log("asd")
    }

    useEffect(() => {
        if(form.InputValuePlace && form.InputValuePlace.value!==""){
            setState({
                search: "",
                value: form.InputValuePlace.value,
            })
        }

  
      return () => {
        
      }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps




    return (
        <div style={{marginBottom:10}}>
        <ReactGoogleMapLoader
            params={{
                key: MY_API_KEY,
                libraries: "places,geocode",
            }}
            render={googleMaps =>
                googleMaps && (
                    <ReactGooglePlacesSuggest
                        googleMaps={googleMaps}
                        autocompletionRequest={{
                            input: state.search,
                            // Optional options
                            // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                        }}
                        // Optional props
                        onNoResult={handleNoResult}
                        onSelectSuggest={handleSelectSuggest}
                        onStatusUpdate={handleStatusUpdate}
                        textNoResults="Brak wyników wyszukiwania" // null or "" if you want to disable the no results item
                        customRender={prediction => (
                            <div className="customWrapper">
                                {prediction
                                    ? prediction.description
                                    : "Brak wyników wyszukiwania"}
                            </div>
                        )}
                    >
                        <Form.Field
                        
                            control={Input}
                            label='Lokalizacja rozgrywek '
                            placeholder='Wpisz dokładny adres obiektu, w którym będzie odbywały się rozgrywki.'
                            value={state.value}
                            required={true}
                            error={stateCurr.value === 1 ? { content: 'Proszę wybrać lokalizację z rozwijanej listy!', pointing: 'below' } : false}
                            onChange={handleInputChange}
                        />

                    </ReactGooglePlacesSuggest>
                    
                )
            }
        />
        </div>
    )

}


export default GoogleSuggest;