import React from 'react';
import 'normalize.css';
import './index.css';
import './darktheme.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { render } from 'react-dom'
const appRoot = document.getElementById('root')
  appRoot.setAttribute('notranslate', true)
  render(<App />, appRoot)
/*
ReactDOM.render(
    <App />,
  document.getElementById('root')
);
*/


reportWebVitals();
