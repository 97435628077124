import React, { useState, useEffect, useContext, } from 'react';
import { Button, Message, Table, Segment, Icon, Input, Header, Checkbox, Label } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../../../ConnectWS'
import AuthContext from '../../../context/authContext';
import useAuth from '../../../hooks/useAuth';
import JudgesList from '../Dashboard/MyAccount/Judges/JudgesList';
import { TabPanel, TabView } from 'primereact/tabview';
import { getInicjaly } from '../../../leagueFuntions';
import { confirmAlert } from 'react-confirm-alert';


function BracketIndividual(propsRoot) {
    const { addToast } = useToasts();
    const authContext = useContext(AuthContext);

    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const [selectedJudgesListFull, setselectedJudgesListFull] = useState(null)
    const [stepCreateS, setstepCreateS] = useState(1)// eslint-disable-line no-unused-vars
    const [tournament_jsonTmp, settournament_jsonTmp] = useState([])
    const [cfgToSave, setcfgToSave] = useState(false)

    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps
        if (propsRoot && propsRoot.subTournamentJson && propsRoot.subTournamentJson.persons && propsRoot.subTournamentJson.persons.arbiters) {
            setselectedJudgesListFull(propsRoot.subTournamentJson.persons.arbiters)
        }
        if (propsRoot && propsRoot.subTournamentJson && propsRoot.subTournamentJson.tournament_json) {
            settournament_jsonTmp(propsRoot.subTournamentJson.tournament_json)
        }
        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.struct_confirmed, propsRoot.remoteJson, propsRoot.subtournaments, propsRoot.subTournamentJson])// eslint-disable-line react-hooks/exhaustive-deps





    function addIndividualRound() {
        let subtournament_id = propsRoot.subtournamentId
        ConnectWS("/generator/addIndividualRound", authContext.token, { subtournament_id: subtournament_id, round_name: "Nowa runda" }, function (response, logout) {
            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);

            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {

                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
                //console.log(response);
            }



        });

    }

    function removeIndividualRound(individual_round_id) {
        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz skasować rundę?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => removeIndividualRoundAcc(individual_round_id)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });

        function removeIndividualRoundAcc(individual_round_id) {

            let subtournament_id = propsRoot.subtournamentId
            ConnectWS("/generator/removeIndividualRound", authContext.token, { subtournament_id: subtournament_id, individual_round_id: individual_round_id }, function (response, logout) {
                if (logout === 1) {
                    addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                    setTimeout(() => {
                        setAuth(false);
                    }, 4000);

                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    authContext.changeRefreshView(authContext.refreshView + 1)
                } else {

                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }
                    //console.log(response);
                }



            });
        }
    }




    function editIndividualRound(i, name, value) {

        let tmp = [...tournament_jsonTmp]
        tmp[i][name] = value
        settournament_jsonTmp(tmp)
        setcfgToSave(true)

    }

    function editIndividualRoundSave(i) {
        let subtournament_id = propsRoot.subtournamentId
        ConnectWS("/generator/editIndividualRound", authContext.token, { subtournament_id: subtournament_id, ...tournament_jsonTmp[i] }, function (response, logout) {
            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);

            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
                setcfgToSave(false)
            } else {

                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
                //console.log(response);
            }



        });
    }


    function getConfigRound(row, i) {
        return <Segment className='CardBlack' >
            <Label color='blue' ribbon style={{ marginBottom: 10 }}>
                <Icon name="winner" /> Konfiguracja rundy
            </Label>
            <Button style={{ marginTop: 10 }} fluid size='mini' color={cfgToSave ? "orange" : "grey"} onClick={() => editIndividualRoundSave(i)}><Icon name="save" />Zapisz konfigurację rundy</Button>
            <div style={{ marginTop: 10, marginBottom: 10, overflowX: "auto", width: "100%" }} >

                <Table
                    inverted={authContext.darkMode}
                    size='small'
                    celled structured striped compact unstackable >
                    <Table.Header >
                        <Table.Row style={{ fontSize: 11 }}>
                            <Table.HeaderCell width={1} textAlign='center'>Nazwa rundy</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>Algorytm punktacji</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>Czy pomijać skrajne punkty sędziowskie</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>Uwzględnij punkty z poprzedniej rundy</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>Najlepsi u góry</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>Ilość uczestników w rundzie</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>Min PKT</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>Max PKT</Table.HeaderCell>
                            {row.round_state === "NEW" && propsRoot && propsRoot.struct_confirmed !== 0 ? <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell> : ""}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell textAlign='center' ><Input value={row.round_name} onChange={(e, d) => editIndividualRound(i, "round_name", d.value)} /></Table.Cell>
                            <Table.Cell textAlign='center' singleLine>
                                <Button.Group size='mini' >
                                    <Button disabled={row.round_state !== "NEW"} color={row.calc_points_algorithm === "SUM" ? "blue" : ""} onClick={(e, d) => editIndividualRound(i, "calc_points_algorithm", "SUM")}>SUMA</Button>
                                    <Button.Or text='lub' />
                                    <Button disabled={row.round_state !== "NEW"} color={row.calc_points_algorithm === "AVG" ? "blue" : ""} onClick={(e, d) => editIndividualRound(i, "calc_points_algorithm", "AVG")}>ŚREDNIA</Button>
                                </Button.Group>
                            </Table.Cell>
                            <Table.Cell textAlign='center'  ><Checkbox disabled={row.round_state !== "NEW"} checked={row.discard_extreme_arbiter_points ? true : false} toggle onChange={(e, d) => editIndividualRound(i, "discard_extreme_arbiter_points", d.checked ? 1 : 0)} /></Table.Cell>
                            <Table.Cell textAlign='center' ><Checkbox disabled={row.round_state !== "NEW"} checked={row.include_prev_round_points ? true : false} toggle onChange={(e, d) => editIndividualRound(i, "include_prev_round_points", d.checked ? 1 : 0)} /></Table.Cell>
                            <Table.Cell textAlign='center'  ><Checkbox checked={row.sort_best_on_top ? true : false} toggle onChange={(e, d) => editIndividualRound(i, "sort_best_on_top", d.checked ? 1 : 0)} /></Table.Cell>
                            <Table.Cell textAlign='center'  ><Input disabled={row.round_state !== "NEW"} style={{ width: 100 }} type='number' value={row.members_count} onChange={(e, d) => editIndividualRound(i, "members_count", d.value)} /></Table.Cell>
                            <Table.Cell textAlign='center' ><Input type='number' style={{ width: 100 }} value={row.min_points} onChange={(e, d) => editIndividualRound(i, "min_points", d.value)} /></Table.Cell>
                            <Table.Cell textAlign='center' ><Input type='number' style={{ width: 100 }} value={row.max_points} onChange={(e, d) => editIndividualRound(i, "max_points", d.value)} /></Table.Cell>
                            {row.round_state === "NEW" && propsRoot && propsRoot.struct_confirmed !== 0 ? <Table.Cell textAlign='center' width={1} > <Icon className='cursorPointer' name="trash" onClick={() => removeIndividualRound(row.individual_round_id)} /></Table.Cell> : ""}

                        </Table.Row>
                    </Table.Body>


                </Table>
            </div>

        </Segment>
    }


    function saveScore(row) {

        if (row.members_points && row.members_points.length > 0) {
            let error = false
            row.members_points.forEach(element => {
                console.log(element)
                if (element.points === null || element.points === "") {
                    error = true
                    addToast("Brak wyniku uzupełnionego przez sędziego: " + element.arbiter_name, { appearance: 'info' });
                }
            });
            if (error) {
                return
            }
        }

        ConnectWS("/generator/setMembersPointsIndividual", authContext.token, { ...row }, function (response, logout) {
            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);

            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {

                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
                //console.log(response);
            }



        });
    }

    function getScoreCell(row, line, row1, i, j, k) {


        if (propsRoot.onlyView === 0 && row.round_state !== "FINISHED") {
            return <Input type='number' error={line.min_score || line.max_score} style={{ width: 100 }} onChange={(e, d) => saveScoreCell(row, d.value, i, j, k)} value={line.points ? line.points : ""} />
        } else {
            return line.points !== null ? line.min_score || line.max_score ? <s style={{ color: "grey", fontSize: 11 }}>{line.points}</s> : line.points : ""
        }
    }

    function saveScoreCell(row, value, i, j, k) {

        if (Number(row.min_points) > Number(value)) {
            addToast("Wpisana wartość jest mniejsza niż MIN PKT", { appearance: 'warning' });
            return
        } else if (Number(value) > Number(row.max_points)) {
            addToast("Wpisana wartość jest większa niż MAX PKT", { appearance: 'warning' });
            return
        }

        let tmp = [...tournament_jsonTmp]
        tmp[i].members_data[j].members_points[k].points = value
        settournament_jsonTmp(tmp)

    }

    function passCurrentIndividualRound(individual_round_id) {

        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz zatwierdzić obecną rundę? Nie będzie można już nic w niej edytować! Dodatkowo należy zweryfikować konfigurację kolejnej rundy pod kątem ilości awansujących uczestników i uwzględniania pkt z poprzedniej rundy.',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => passCurrentIndividualRoundACC(individual_round_id)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });


        function passCurrentIndividualRoundACC(individual_round_id) {
            ConnectWS("/generator/passCurrentIndividualRound", authContext.token, { individual_round_id: individual_round_id }, function (response, logout) {
                if (logout === 1) {
                    addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                    setTimeout(() => {
                        setAuth(false);
                    }, 4000);

                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    authContext.changeRefreshView(authContext.refreshView + 1)
                } else {

                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }
                    //console.log(response);
                }



            });
        }

    }


    function getColorRow(i, j, row, members_data) {
        try {
            if (row.round_state !== "NEW") {
                if (tournament_jsonTmp[i + 1].members_count > j && row.sort_best_on_top && (members_data.end_round_order_nr_range !== null || members_data.current_round_points !== null)) {

                    return "tablePromotionRowUP"
                } else if ((row.members_data.length - tournament_jsonTmp[i + 1].members_count) <= j && !row.sort_best_on_top && (members_data.end_round_order_nr_range !== null || members_data.current_round_points !== null)) {
                    return "tablePromotionRowUP"
                }
            }

            return ""
        } catch (e) {
            return ""
        }

    }


    function getViewData(row, i) {
        return <>
            {propsRoot.onlyView === 0 && row.round_state !== "FINISHED" ? <div style={{ marginTop: 20, marginBottom: 50 }}>
                {getConfigRound(row, i)}
            </div> : ""}

            <div className='tablebs' style={{ marginTop: 10, marginBottom: 10, overflowX: "auto", width: "100%" }} >
                <Table inverted={authContext.darkMode} size='small' celled structured striped compact={propsRoot.onlyView === 0 ? true : false} unstackable >
                    <Table.Header >
                        <Table.Row >
                            <Table.HeaderCell rowSpan='2' textAlign='center'>Pozycja</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2' textAlign='center'>Uczestnik</Table.HeaderCell>
                            <Table.HeaderCell colSpan={propsRoot && propsRoot.subTournamentJson ? propsRoot.subTournamentJson.persons.arbiters.length : 0} textAlign='center'>Sędziowie</Table.HeaderCell>
                            {propsRoot.onlyView === 0 && row.round_state !== "FINISHED" ? <Table.HeaderCell rowSpan='2' textAlign='center'>Zapisz</Table.HeaderCell> : ""}
                            <Table.HeaderCell rowSpan='2' textAlign='center'>Punkty</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>

                            {propsRoot.subTournamentJson.persons.arbiters.map((line1, j) => <Table.HeaderCell textAlign='center' title={line1.person_name}>{getInicjaly(line1.person_name)}<span style={{ fontSize: 11 }}>(S{j + 1})</span></Table.HeaderCell>)}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body >
                        {row && row.members_data && row.members_data.length > 0 ?
                            row.members_data.map((row1, j) =>
                                <Table.Row key={j} className={getColorRow(i, j, row, row1)}>
                                    <Table.Cell width={1} textAlign='center'  >{row1.end_round_order_nr_range ? row1.end_round_order_nr_range : ""}</Table.Cell>
                                    <Table.Cell width={5} textAlign='center'  >{row1.member_name}</Table.Cell>
                                    {row1.members_points.map((line1, k) => <Table.Cell width={1} textAlign='center'>{getScoreCell(row, line1, row1, i, j, k)}</Table.Cell>)}
                                    {propsRoot.onlyView === 0 && row.round_state !== "FINISHED" ? <Table.Cell width={1} textAlign='center'><Icon className='cursorPointer' color='orange' size="large" onClick={() => saveScore(row1)} name="save" /></Table.Cell> : ""}
                                    <Table.Cell width={1} textAlign='center'  >{row.include_prev_round_points ? <span style={{ fontSize: 11 }}> ({row1.start_round_points}) </span> : ""}<span style={{ fontSize: 16, fontWeight: "bold" }}>{row1.current_round_points ? row1.current_round_points : row1.end_round_points ? row1.end_round_points : ""}</span></Table.Cell>
                                </Table.Row>
                            )
                            : ""}
                    </Table.Body>
                </Table>
            </div>
            {row && row.members_data && row.members_data.length === 0 ? <Message style={{ marginTop: 30 }}>Oczekuje na zatwierdzenie wcześniejszej rundy...</Message> : ""}
            {propsRoot.onlyView === 0 && row.round_state === "CURRENT" ?
                <Button style={{ marginTop: 50 }} fluid color='blue' onClick={() => passCurrentIndividualRound(row.individual_round_id)}><Icon name="check" />ZATWIERDŹ RUNDĘ</Button>
                : ""}
        </>
    }

    return (
        <>
            {propsRoot && propsRoot.struct_confirmed === 0 && propsRoot.onlyView === 0 ?
                stepCreateS === 1 ? <>
                    <Header as='h3' icon textAlign='center'>
                        <Icon name='users' circular />
                        <Header.Content>Proszę wybrać sędziów</Header.Content>
                    </Header>

                    <Segment className='CardBlack'>
                        <JudgesList addJudges={false} judgesListArr={selectedJudgesListFull} addATS={propsRoot.subtournamentId} selectionType={2} rootHeight={300} />
                    </Segment>

                    {selectedJudgesListFull && selectedJudgesListFull.length > 0 ?
                        <>

                            <Label style={{ marginTop: 20 }}>Lista dodanych sędziów: {selectedJudgesListFull.length}</Label>
                            <div style={{ marginTop: 4, marginBottom: 10, overflowX: "auto", width: "100%" }} >

                                <Table
                                    inverted={authContext.darkMode}
                                    size='small'
                                    celled structured striped compact unstackable >
                                    <Table.Header >

                                        <Table.Row>
                                            <Table.HeaderCell width={1} textAlign='center'>LP</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>Nazwa</Table.HeaderCell>

                                        </Table.Row>

                                    </Table.Header>
                                    <Table.Body>
                                        {selectedJudgesListFull.map((line, j) => <Table.Row>
                                            <Table.Cell textAlign='center' width={1} >{j + 1}</Table.Cell>
                                            <Table.Cell textAlign='center'  >{line.person_name}</Table.Cell>
                                        </Table.Row>)
                                        }
                                    </Table.Body>


                                </Table>

                            </div> </> : ""}
                    <Message info size='mini' >Nazwy sędziów w tabeli będą wyświetlane jako skrócone, jeżeli sędzia ma podane IMIĘ i NAZWISKO wówczas będą inicjały. Jeżeli będzie podana inna nazwa niezawierajaca spacji wówczas tylko 3 pierwsze litery. </Message>
                    <Button fluid style={{ marginTop: 20 }} size='mini' onClick={() => setstepCreateS(2)} disabled={selectedJudgesListFull && selectedJudgesListFull.length > 0 ? false : true}>Kolejny krok<Icon name="arrow right" /></Button>
                </>
                    :
                    stepCreateS === 2 ? <>
                        <Header as='h3' icon textAlign='center'>
                            <Icon name='users' circular />
                            <Header.Content>Skonfiguruj ilość grup oraz jej parametry</Header.Content>
                        </Header>


                        <Button color='orange' size='mini' onClick={() => addIndividualRound()}><Icon name="add" />Dodaj kolejną rundę</Button>
                        <TabView scrollable >
                            {tournament_jsonTmp && tournament_jsonTmp.length > 0 ?
                                tournament_jsonTmp.map((row, i) =>
                                    <TabPanel key={i} header={row.round_name}  >
                                        <Button fluid size='mini' onClick={() => removeIndividualRound(row.individual_round_id)}><Icon name="trash" />Kasuj rundę</Button>
                                        {getConfigRound(row, i)}
                                    </TabPanel>
                                ) : ""}

                        </TabView>
                        <Message>
                            Jeżeli ukończyłeś/aś konfigurację rund kliknij brązowy przycisk ZATWIERDŹ KONFIGURACJĘ.
                        </Message>
                        <Button size='mini' style={{ marginTop: 20 }} onClick={() => setstepCreateS(1)} fluid><Icon name="arrow left" />Wstecz</Button>
                    </>
                        : ""
                : ""
            }
            {propsRoot && propsRoot.struct_confirmed !== 0 ?
                propsRoot.viewTV ? tournament_jsonTmp && tournament_jsonTmp.length > 0 ?
                    tournament_jsonTmp.map((row, i) =>
                        <div key={i}  >
                            <Label style={{ marginTop: 10 }} color='blue'>{row.round_name}</Label>
                            {getViewData(row, i)}
                        </div>
                    ) : "" : <TabView scrollable >
                    {tournament_jsonTmp && tournament_jsonTmp.length > 0 ?
                        tournament_jsonTmp.map((row, i) =>
                            <TabPanel key={i} header={row.round_name}  >
                                {getViewData(row, i)}

                            </TabPanel>
                        ) : ""}

                </TabView>


                : ""}

        </>
    )
}

export default BracketIndividual;