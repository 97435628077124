import React, { useState, useEffect, useRef } from 'react';
import { Button,Container, Modal } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import { Carousel } from 'primereact/carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';



function MoveYtTG() {

    const API_KEY = 'AIzaSyB90bMqHkZyQGYnxwsuOdhHXvx26iqXn0E'; // Tutaj wstaw swój klucz API
    const PLAYLIST_ID = 'PLwl3m1CZ3b6ByR5tJqO4Os8T7FRWNASwQ'; // Tutaj wstaw ID playlisty
    const [videos, setVideos] = useState([]); // Stan do przechowywania filmów
    const [playingVideo, setPlayingVideo] = useState(null); // Stan do odtwarzania filmu
    const playerRef = useRef(null); // Referencja do odtwarzacza
    const [width, setWidth] = useState(0);
    const divRef = useRef(null);
    useEffect(() => {
        const fetchPlaylistVideos = async () => {
            try {
                const response = await fetch(
                    `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${PLAYLIST_ID}&maxResults=10&key=${API_KEY}`
                );
                const data = await response.json();
                if (data.items) {
                    setVideos(data.items);
                }
            } catch (error) {
                console.error('Błąd podczas pobierania filmów:', error);
            }
        };

        fetchPlaylistVideos();
        if (divRef.current) {
            setWidth(divRef.current.offsetWidth);
          }
    }, [API_KEY, PLAYLIST_ID]);


    // Funkcja do otwierania filmu na YouTube
    const openOnYouTube = () => {
        if (playingVideo) {
            // Zatrzymaj odtwarzanie przed przekierowaniem
            try {
                if (playerRef.current) {
                    const internalPlayer = playerRef.current.getInternalPlayer();
                    if (internalPlayer && internalPlayer.pauseVideo) {
                        internalPlayer.pauseVideo(); // Zatrzymaj odtwarzanie YouTube
                    }
                }
            } catch (e) {

            }

            window.open(`https://www.youtube.com/watch?v=${playingVideo}`, '_blank');
        }
    };


    const responsiveOptions = [
        {
            breakpoint: '1699px',
            numVisible: 4,
            numScroll: 1
        },
        {
            breakpoint: '1630px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '1200px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    const moveTemplate = (video) => {
        return (
            <div className='productCardModule' onClick={() => setPlayingVideo(video.snippet.resourceId.videoId)}
                style={{position:"relative", cursor: 'pointer', maxWidth: 300, margin: "0 auto", marginBottom: 15, marginTop: 5 }}>
                   <div className="yt-play-button"></div>
                <LazyLoadImage
                    alt={video.snippet.thumbnails.medium.url}
                    style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
                    width={"100%"}
                    height={160}    
                    src={video.snippet.thumbnails.medium.url}
                    className="imgProductTG"
                    effect="blur" />
                <div className='productCardModuleContent'>
                    <div style={{fontSize:14,margin:2}} ><b>{video.snippet.title}</b></div>
                </div>
            </div>
        );
    };


    return (

        <div ref={divRef} style={{ width: '100%' }}>
            <Container style={{ padding: '20px' }} textAlign='center'>
                <h2>Instrukcje wideo jak korzystać z Turniej Generatora</h2>

            </Container>
            <Container textAlign='center'>
                {videos ?   
                <Carousel value={videos} circular autoplayInterval={3000} numScroll={1} numVisible={width < 1600 ? 3 : 5} responsiveOptions={responsiveOptions} itemTemplate={moveTemplate} />
                :""}  
            </Container>
            <Modal
                open={!!playingVideo} // Modal jest otwarty, gdy playingVideo nie jest null
                onClose={() => setPlayingVideo(null)} // Zamknij modal, gdy użytkownik kliknie poza nim
                size='large'
                style={{ backgroundColor: "#000000" }}
            >

                <Modal.Content style={{ backgroundColor: "#000000" }}>
                    {playingVideo && (

                        <ReactPlayer
                            ref={playerRef}
                            url={`https://www.youtube.com/watch?v=${playingVideo}`}
                            controls
                            playing
                            width="100%"
                            height="500px"

                        />

                    )}
                </Modal.Content>
                <Modal.Actions style={{ backgroundColor: "#000000" }}>
                    <Button onClick={openOnYouTube} color='black' floated='left'>
                        Otwórz na YouTube
                    </Button>

                    <Button color='black' onClick={() => setPlayingVideo(null)}>Zamknij</Button>
                </Modal.Actions>
            </Modal>
        </div>

    )
}

export default MoveYtTG;