import React, { useEffect, useContext, useState, createRef } from 'react';
import { Button, Container, Form, FormField, Grid, Header, Icon, Image, Input, Label, Modal, Segment, Table } from 'semantic-ui-react';
import AuthContext from '../context/authContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { getlinkplayerprofile, getZoomT, noMemberId, noSubIsActive, removeEmojis, removeSpecialChars, txtCase } from '../myfunctions';
import { ConnectWS } from '../ConnectWS';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import ShowImgAvatar from './ShowImgAvatar';
import { confirmAlert } from 'react-confirm-alert';
import LogoTeamLigaGenerator from '../assets/tglogov6_small.png';
import useCheckUserIsDemo from '../hooks/useCheckUserIsDemo';
import AddStaffToTeam from './AddStaffToTeam';
import { TabPanel, TabView } from 'primereact/tabview';
import MyTournamentEditUsers from '../layout/ui/Dashboard/MyTournaments/MyTournamentEditUsers';

function MembersInTeams(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [open, setOpen] = useState(false)
  const [openData, setopenData] = useState(null)

  const [isUpdate, setisUpdate] = useState(0)
  const { t } = useTranslation();
  const isDemo = useCheckUserIsDemo()
  const firstNameRef = createRef();


  const [tmpCount, settmpCount] = useState(0)


  const [isAS, setisAS] = useState(true)
  const [isMemberId, setisMemberId] = useState(null)

  const [openChangeUserData, setopenChangeUserData] = useState({ open: false, data: null });

  function checkSubIsActiv() {
    try {
      if (isDemo) {
        setisAS(true)
      } else if (authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended >= 0 && authContext.user.subscription_info.subscription_active) {
        setisAS(true)
      } else {
        // wylaczone sprwadzanie bo dla innych turniejów też można dodać 
        setisAS(true)
      }
    } catch (e) {
      setisAS(true)
    }
  }

  useEffect(() => {

    checkSubIsActiv()
    setOpen(props.openModalMembersTeams.open)
    setisUpdate(0)


    if (props.openModalMembersTeams.open) {

      getData(props.openModalMembersTeams.data.subtournament_id)
      setisMemberId(props.openModalMembersTeams.data.row.member_id)
    }
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [props.openModalMembersTeams, authContext.refreshView])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {


    if (firstNameRef && firstNameRef.current) {
      firstNameRef.current.focus()
    }
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [tmpCount])// eslint-disable-line react-hooks/exhaustive-deps

  //editMemberInSubtournamentTeam -> member_team_id, jersey_nr

  function updateJerseyNr(value, row) {
    let team_id = props.openModalMembersTeams.data.row.member_id;
    let subtournament_id = props.openModalMembersTeams.data.subtournament_id
    ConnectWS("/generator/editMemberInSubtournamentTeam", authContext.token, { team_id: team_id, subtournament_id: subtournament_id, member_id: row.member_id, jersey_nr: value === "" ? null : value }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {

        addToast(response.data.message, { appearance: 'success' });
        getData(props.openModalMembersTeams.data.subtournament_id)
        // getData()
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }


  function exitModal() {
    if (isUpdate) {
      authContext.changeRefreshView(authContext.refreshView + 1)
    }

    props.setopenModalMembersTeams({ open: false, data: null })
    setOpen(false)
  }


  function getDataALL() {
    try {
      let team_id = props.openModalMembersTeams.data.row.member_id;
      if (props.subTournamentJson && props.subTournamentJson.members && props.subTournamentJson.members.length > 0) {
        let ret = []
        props.subTournamentJson.members.forEach(element => {
          if (element.member_id === team_id) {
            console.log(element)
            ret = element.members_list
            return ret
          }

        });
        return ret
      }
      return []
    } catch (e) {
      return []
    }

  }

  function getData(subtournament_id) {

    if (props.onlyView === 0) {
      ConnectWS("/generator/getMembersInSubtournamentTeams", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

        if (logout === 1) {


        } else if (response && response.status && Number(response.status) === 200) {
          //
          let arrtmp = [];
          arrtmp.length = 0;
          if (response.data.length > 0) {
            response.data.forEach((data, i) => {
              if (data.team_id === props.openModalMembersTeams.data.row.member_id && data.members.length > 0) {
                arrtmp.push(...data.members)
              }

            })
          }

          if (arrtmp.length > 0) {
            setopenData(arrtmp)
          } else {
            setopenData(null)
          }

        } else {
          setopenData(null)
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //console.log(response);
        }

      });
    } else {
      setopenData(getDataALL())
    }
  }


  const [formAddUsers, setFormAddUsers] = useState({
    name: "",
    surname: "",
    email: "",
    jersey_nr: ""
  });
  const [loading, setloading] = useState(false);

  function addDBUsersToTournament() {

    setloading(true)
    let nametmp = txtCase(formAddUsers.name.trim()) + " " + txtCase(formAddUsers.surname.trim())

    let team_id = props.openModalMembersTeams.data.row.member_id;
    let subtournament_id = props.openModalMembersTeams.data.subtournament_id


    ConnectWS("/generator/addMemberToSubtournamentTeam", authContext.token, { team_id: team_id, subtournament_id: subtournament_id, name: nametmp, email: formAddUsers.email, jersey_nr: formAddUsers.jersey_nr === "" ? null : formAddUsers.jersey_nr }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        setFormAddUsers({
          name: "",
          surname: "",
          email: "",
          jersey_nr: ""
        })
        addToast(response.data.message, { appearance: 'success' });
        getData(props.openModalMembersTeams.data.subtournament_id)
        setisUpdate(1)

        // getData()
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }
      settmpCount(tmpCount + 1)

      setloading(false)


    });

  }

  function removeMember(row) {
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować uczestnika ' + row.member_name + '?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => removeMemberAcc(row)
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function removeMemberAcc(row) {
      let subtournament_id = props.openModalMembersTeams.data.subtournament_id
      ConnectWS("/generator/removeMemberFromSubtournamentTeam", authContext.token, { subtournament_id: subtournament_id, ...row }, function (response, logout) {

        if (logout === 1) {

        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          getData(props.openModalMembersTeams.data.subtournament_id)
          setisUpdate(1)
          authContext.changeRefreshView(authContext.refreshView + 1)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }

        }

      });
    }
  }


  function addMemberToSubtournamentTeam(row) {

    let subtournament_id = props.openModalMembersTeams.data.subtournament_id
    ConnectWS("/generator/addMemberToSubtournamentTeam", authContext.token, { subtournament_id: subtournament_id, team_id: props.openModalMembersTeams.data.row.member_id, member_id: row.member_id }, function (response, logout) {

      if (logout === 1) {
      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        getAllMembersInSubtournamentTeam()
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }

    });
  }

  function getAllMembersInSubtournamentTeam() {
    let team_id = props.openModalMembersTeams.data.row.member_id

    if (!team_id) {
      return
    }
    let subtournament_id = props.openModalMembersTeams.data.subtournament_id
    ConnectWS("/members/getAllMembersInSubtournamentTeam", authContext.token, { subtournament_id: subtournament_id, team_id: team_id }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {


        if (response.data && response.data.length > 0) {
          setopenData(response.data)
        } else {
          setopenData(null)
        }

      } else {
        setopenData(null)
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }

    });

  }

  return (
    <>
      <Modal
        open={open}
        centered={width < 900 ? false : true}
        size='large'
        style={{ zoom: getZoomT(width) }}

      >
        <Modal.Header>

          <Grid >
            <Grid.Row >
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='left'>
                  <Icon name='users' color="red" />
                  <Header.Content>Lista uczestników
                    <Header.Subheader>
                      Drużyna: {open && props.openModalMembersTeams && props.openModalMembersTeams.data ? props.openModalMembersTeams.data.row.name : ""}
                    </Header.Subheader>
                  </Header.Content>

                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" verticalAlign="middle">
                <Image alt="logo" floated='right' style={{ maxWidth: 200, opacity: 0.2 }} centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>


        </Modal.Header>
        <Modal.Content style={{ backgroundColor: "#e0e1e2" }} >
          <Modal.Description >
            <TabView>
              <TabPanel header={"ZAWODNICY"} leftIcon={<span className='colorIconTabMenu'><Icon name="users" /></span>} >
                <Container style={{ padding: 8 }}>
                  <div style={{ overflowX: "auto", marginTop: 5, width: "100%" }} >
                    <Table style={{ marginTop: 10 }} singleLine size='small' celled striped compact unstackable >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={2} textAlign='center'>LP</Table.HeaderCell>
                          <Table.HeaderCell width={2} textAlign='center'>NUMER</Table.HeaderCell>
                          <Table.HeaderCell textAlign='center' style={{ lineHeight: "12px" }}>ZAWODNIK</Table.HeaderCell>
                          {props.onlyView === 1 ? "" : <Table.HeaderCell width={2} textAlign='center' style={{ lineHeight: "12px" }}>EMAIL</Table.HeaderCell>}
                          {props.onlyView === 1 ? "" : <Table.HeaderCell width={2} textAlign='center'></Table.HeaderCell>}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {openData ? openData.map((row, i) =>
                          <Table.Row key={i}   >
                            <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                            <Table.Cell textAlign='center'>
                              {props.onlyView === 1 ? row.jersey_nr ? row.jersey_nr : "" :
                                <Input value={row.jersey_nr ? row.jersey_nr : ""} type='number' onChange={(e, d) => updateJerseyNr(d.value, row)} />
                              }
                            </Table.Cell>
                            {console.log(row)}
                            <Table.Cell ><figure className='imgPlayer'><ShowImgAvatar urlpic={row.member_img} mtype={"0"} msize={"mini"} /><figcaption >{getlinkplayerprofile(null, row.member_name, 0, 14)} {props.onlyView === 1 ? "" : <Icon name='edit' color="orange" className='cursorPointer hiddenPrint' title="Zmień nazwę uczestnika" onClick={() => setopenChangeUserData({ open: true, data: { member_id: row.member_id, team_id: props ? props.openModalMembersTeams.data.row.member_id : 0, name: row.member_name }, subTournamentId: props.openModalMembersTeams ? props.openModalMembersTeams.data.subtournament_id : 0, subTurnamentType: "USER", edit: 3 })} />}</figcaption></figure></Table.Cell>
                            {props.onlyView === 1 ? "" : <Table.Cell textAlign='center'>{row.member_email}</Table.Cell>}
                            {props.onlyView === 1 ? "" : <Table.Cell textAlign='center'>
                              {row.current_subtournament_member_exists ? <Icon name="trash" className='cursorPointer' onClick={() => removeMember(row)} title="KASUJ UCZESTNIKA" />
                                : <Icon name="add" className='cursorPointer' onClick={() => addMemberToSubtournamentTeam(row)} title="DODAJ UCZESTNIKA" />
                              }

                            </Table.Cell>}
                          </Table.Row>
                        ) :
                          <Table.Row >
                            <Table.Cell colspan={4} textAlign='center'>
                              Brak przypisanych uczestników do tej drużyny
                            </Table.Cell>
                          </Table.Row>}

                      </Table.Body>
                    </Table>
                  </div>
                  {props.onlyView === 1 ? "" : <>

                    <Button color='blue' fluid size='mini' onClick={() => getAllMembersInSubtournamentTeam()}>POBIERZ WSZYSTKICH ZAWODNIKÓW</Button>
                    <Segment style={{ backgroundColor: "#f0f0f0" }}>
                      <Label color='blue' ribbon style={{ marginBottom: 10 }}>
                        <Icon name="user" /> Dodaj zawodnika
                      </Label>

                      {isAS && isMemberId !== null ?
                        <Form size='tiny' onSubmit={() => addDBUsersToTournament()}>
                          <FormField required={true}>
                            <label>{t("WidokTurnieju.zapisy_imie")}</label>
                            <Input
                              autoFocus
                              ref={firstNameRef}
                              value={formAddUsers.name}
                              onChange={(event, data) => setFormAddUsers({ ...formAddUsers, name: removeSpecialChars(removeEmojis(data.value)) })}
                              required={true}
                              placeholder={t("WidokTurnieju.zapisy_imie")}
                            />
                          </FormField>
                          <Form.Field
                            control={Input}
                            value={formAddUsers.surname}
                            onChange={(event, data) => setFormAddUsers({ ...formAddUsers, surname: removeSpecialChars(removeEmojis(data.value)) })}
                            required={true}
                            label={t("WidokTurnieju.zapisy_nazwisko")}
                            placeholder={t("WidokTurnieju.zapisy_nazwisko")}


                          />

                          <Form.Field
                            control={Input}
                            value={formAddUsers.jersey_nr}
                            onChange={(event, data) => setFormAddUsers({ ...formAddUsers, jersey_nr: data.value })}
                            label={"Numer zawodnika"}

                            placeholder={"Numer zawodnika"}
                            type='number'

                          />

                          <Form.Field
                            control={Input}
                            value={formAddUsers.email}
                            onChange={(event, data) => setFormAddUsers({ ...formAddUsers, email: data.value })}
                            label={t("WidokTurnieju.zapisy_email")}

                            placeholder={t("WidokTurnieju.zapisy_email")}
                            type='email'

                          />

                          <Button loading={loading} disabled={loading} color='blue' size='small' fluid type='submit'>DODAJ ZAWODNIKA</Button>
                        </Form>
                        : !isAS ? noSubIsActive() : noMemberId()}

                    </Segment>
                  </>}
                </Container>
              </TabPanel>
              <TabPanel header={"SZTAB"} leftIcon={<span className='colorIconTabMenu'><Icon name="users" /></span>} >
                <Container style={{ padding: 8 }}>
                  <AddStaffToTeam onlyView={props.onlyView} subTournamentJson={props.subTournamentJson} openModalMembersTeams={props.openModalMembersTeams} />
                </Container>
              </TabPanel>

            </TabView>


          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>



          <Button size="tiny" labelPosition='right' icon='close' color='grey' onClick={() => exitModal()} content="WYJDŹ" />
        </Modal.Actions>
      </Modal>
      <Modal
        open={openChangeUserData.open}
      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='center'>
                  <Icon name='edit' />
                  <Header.Content>Edycja dane</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image floated='right' size='big' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content image>

          <Modal.Description>
            <MyTournamentEditUsers editType={openChangeUserData.edit} data={openChangeUserData.data} subTurnamentType={openChangeUserData.subTurnamentType} subTournamentId={openChangeUserData.subTournamentId} setopenChangeUserData={setopenChangeUserData} />
          </Modal.Description>

        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => setopenChangeUserData({ open: false, data: null })}>
            ZAMKNIJ
          </Button>

        </Modal.Actions>
      </Modal>
    </>
  )
}

export default MembersInTeams;