import React, { useState, useEffect } from 'react';
import { Button,  Grid, Header, Icon, Image, Label, Modal, Segment, Table } from 'semantic-ui-react';

import { Dropdown } from 'primereact/dropdown'; 
import { getZoomT, mobileWSmall } from '../../myfunctions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import LogoTeamLigaGenerator from '../../assets/tglogov6_small.png';
import ShowImgAvatar from '../ShowImgAvatar';
import { t } from 'i18next';
import { changeValuDataFibo } from '../../leagueFuntions';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function CompareLeagueMembers({ memberDataArr, isDarkTheme }) {


    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    const [membersList, setmembersList] = useState([])
    const [openModal, setopenModal] = useState(false)
    const [showCompare, setshowCompare] = useState(false)

    const [m1, setm1] = useState(null)
    const [m2, setm2] = useState(null)




    useEffect(() => {

        if (memberDataArr && memberDataArr.length > 0) {
            createML(memberDataArr)
            checkShowCompare(memberDataArr)
        }
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [memberDataArr])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {

        if (m1 !== null && m2 !== null) {
            setopenModal(true)

        }
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [m1, m2])// eslint-disable-line react-hooks/exhaustive-deps

    function getStatsMember(member_id, memberDataArr, newkey) {
        try {
            let mdata = memberDataArr.find((x) => x.member_id === member_id)
            let stats = mdata.stats.find((x) => x.newkey === newkey)
            return changeValuDataFibo(stats.value, newkey)
        } catch (e) {
            return 0
        }
    }

    function closeModal() {
        setm1(null)
        setm2(null)
        setopenModal(false)
    }


    function checkShowCompare(memberDataArr) {
        try {
            if (memberDataArr[0].stats.find((x) => x.key === "pts")) {
                setshowCompare(true)
            } else {
                setshowCompare(false)
            }

        } catch (e) {
            setshowCompare(false)
        }
    }

    function createML(memberDataArr) {
        let tmp = []
        memberDataArr.forEach(row1 => {

            tmp.push({
                name: row1.member_name,
                member_id: row1.member_id,
                member_img: row1.member_img
                // key: row1.member_id,
                // text: row1.member_name,
                // value: JSON.stringify({ member_id: row1.member_id, member_name: row1.member_name, member_img: row1.member_img }),
                // image: { avatar: true, src: row1.member_img ? row1.member_img : process.env.REACT_APP_PUBLIC_URL + '/img/nouser.png' },
            })
        });
        setmembersList(tmp)
    }

   

    function getCompareViewRoot() {
        return <div>



            <div >
                <Grid fluid verticalAlign='middle' style={{ padding: 0, margin: 0 }} >
                    <Grid.Row>
                        <Grid.Column width={width < mobileWSmall() ? 6 : 7} textAlign='center'>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <figure className='imgPlayer2'><ShowImgAvatar msize={width < mobileWSmall() ? "" : "massive"} urlpic={m1 ? m1.member_img : null} mtype={"0"} /><figcaption>
                                    <Dropdown value={m1} onChange={(e) => setm1(e.value)} options={membersList} optionLabel="name" placeholder="Wybierz zawodnika"
                                     filter    />

                                </figcaption></figure>
                            </div>
                        </Grid.Column>
                        <Grid.Column singleLine width={width < mobileWSmall() ? 4 : 2} textAlign='center' style={{ fontSize: 30 }}>
                            <Icon name="balance scale" color='grey' />

                        </Grid.Column>
                        <Grid.Column width={width < mobileWSmall() ? 6 : 7} textAlign='center'>
                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                <figure className='imgPlayer2'><ShowImgAvatar msize={width < mobileWSmall() ? "" : "massive"} urlpic={m2 ? m2.member_img : null} mtype={"0"} /><figcaption>
                                <Dropdown value={m2} onChange={(e) => setm2(e.value)} options={membersList} optionLabel="name" placeholder="Wybierz zawodnika"
                                     filter    />
                                </figcaption></figure>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    }

    function showCharts() {
        try {

            let labels = []
            let m1tmp = []
            let m2tmp = []

            if (memberDataArr && memberDataArr.length > 0) {
                memberDataArr[0].stats.forEach(row =>
                    row.key === "sec" ? "" : addR(row)
                );
            }

            function addR(row) {
                labels.push(t("stats." + row.newkey))
                m1tmp.push(getStatsMember(m1 ? m1.member_id : 0, memberDataArr, row.newkey))
                m2tmp.push(getStatsMember(m2 ? m2.member_id : 0, memberDataArr, row.newkey))
            }

            if(labels.length===m1tmp.length && labels.length===m2tmp.length){

            }else{
                return ""
            }


            let options = {
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                plugins: {
                    datalabels: {
                        display: true,
                        color: isDarkTheme ? "white" : "black",
                        formatter: Math.round,
                        anchor: "end",
                        offset: -16,
                        align: "start"
                    }
                },
                scales: {
                    x: {
                        display: true,
                        grid: {
                            color: isDarkTheme ? '#2d2c2c' : '#dbdada'
                        },
                        title: {
                            display: true,
                            text: 'RUNDA'
                        }
                    },
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        grid: {
                            color: isDarkTheme ? '#2d2c2c' : '#dbdada'
                        },
                        title: {
                            display: false,
                            text: ''
                        },
                    },

                },
            };

            let dataChart = {
                labels,
                datasets: [
                    {
                        label: m1 ? m1.name : "?",
                        data: m1tmp,
                        backgroundColor: isDarkTheme ? 'rgb(37, 124, 60)' : 'rgb(151, 204, 165)',
                    },
                    {
                        label: m2 ? m1.name : "?",
                        data: m2tmp,
                        backgroundColor: isDarkTheme ? 'rgba(75, 145, 192, 0.8)' : 'rgba(75, 145, 192, 0.8)',
                    },
                ],
            };
            return <Segment><Bar redraw={true} data={dataChart}  plugins={[ChartDataLabels]} options={options} height={width < 1300 ? 100 : 70} /></Segment>
        } catch (e) {
            console.log(e)
            return ""
        }
    }

    return (
        showCompare ?
            <>

                <div style={{ marginTop: 30, marginBottom: 80 }} >
                    <Segment style={{ marginLeft: 10, marginRight: 10, backgroundColor: isDarkTheme ? "#1d1d1d" : "#f7f7f7" }} basic className='tablebs'>
                        <Label color='blue' ribbon>PORÓWNAJ ZAWODNIKÓW</Label>
                        {openModal ? "" : getCompareViewRoot()}
                    </Segment>


                </div>
                <Modal
                    open={openModal}
                    style={{ paddingBottom: 1, zoom: getZoomT(width) }}
                    centered={width < 900 ? false : true}
                    size='large'
              

                >
                    <Modal.Header>
                        <Grid >
                            <Grid.Row >
                                <Grid.Column width={12} verticalAlign="middle">
                                    <Header as='h2' textAlign='left'>
                                        <Icon name='balance scale' color="red" />
                                        <Header.Content>Porównaj Zawodników</Header.Content>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column width={4} textAlign="right" verticalAlign="middle">
                                    <Image alt="logo" floated='right' style={{ maxWidth: 200 }} centered src={LogoTeamLigaGenerator} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </Modal.Header>
                    <Modal.Content style={{ backgroundColor: "#e0e1e2" }} >

                        {m1 && m2 && openModal ? <Modal.Description >
                            {getCompareViewRoot()}
                            {showCharts()}
                            <div style={{ overflowY: "auto", width: "100%" }} >
                                <Table size='small' className='rowSelectedColor' celled compact unstackable striped  >
                                    <Table.Header >

                                        <Table.Row>
                                            <Table.HeaderCell textAlign='center' width={3}>{m1 ? m1.name.toUpperCase() : ""}</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center' width={3}>STATYSTYKI</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center' width={3}>{m2 ? m2.name.toUpperCase() : ""}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {memberDataArr && memberDataArr.length > 0 ?
                                            memberDataArr[0].stats.map((row, i) => <>
                                                <Table.Row key={i} >
                                                    <Table.Cell width={3} textAlign='center'>{getStatsMember(m1 ? m1.member_id : 0, memberDataArr, row.newkey)}</Table.Cell>
                                                    <Table.Cell width={3} textAlign='center'><b>{t("stats." + row.newkey)}</b></Table.Cell>
                                                    <Table.Cell width={3} textAlign='center'>{getStatsMember(m2 ? m2.member_id : 0, memberDataArr, row.newkey)}</Table.Cell>
                                                </Table.Row>
                                            </>)
                                            : ""}
                                    </Table.Body>
                                </Table>
                            </div>




                        </Modal.Description> : ""}
                    </Modal.Content>
                    <Modal.Actions>

                        <Button size="tiny" labelPosition='right' icon='close' color='grey' onClick={() => closeModal()} content={"ZAMKNIJ"} />
                    </Modal.Actions>
                </Modal>

            </>
            : ""
    )
}

export default CompareLeagueMembers;