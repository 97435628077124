import React, { useEffect, useState, useContext, useRef } from 'react';
import { Header, Grid, Container, Table, Segment, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import AuthContext from '../../../context/authContext'
import { Line } from "react-chartjs-2";
import { ConnectWS } from '../../../ConnectWS';
import { TabView, TabPanel } from 'primereact/tabview';
import { LiveIcon3, loadingShow } from '../../../myfunctions';
import ReactPlayer from 'react-player';
import ShowImgAvatar from '../../../tools/ShowImgAvatar';

function ShowScoreDetailsView({ match_detailstmp, systemId, matche_info, refreshView, isLiveDuel }) {
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    const authContext = useContext(AuthContext);
    const [dataChart, setDataChart] = useState(null)
    const [loading, setloading] = useState(false)
    const { t } = useTranslation();
    const [match_details, setmatch_details] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const [mReverse, setmReverse] = useState(false);

    let interval2 = useRef(null);

    useEffect(() => {
       
        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
            //  console.log("matche_info2", matche_info)
          //  console.log("match_detailstmp2", match_detailstmp)
          //    console.log("systemId2", match_detailstmp)
          //    console.log("isLiveDuel", isLiveDuel)
            if (match_detailstmp.reverse) {
                setmReverse(match_detailstmp.reverse)
            }
        }

        if (match_detailstmp.match_details) {
            setloading(true)
            preStart()
        }

        return () => {
        
        }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      //  console.log("match_detailstmp2", match_detailstmp)
        ClearAllInterval()
        if (match_detailstmp.match_details) {
            if (isLiveDuel) {
                interval2.current = setInterval(() => {

                    GetCurrentDataInLive(activeIndex)

                }, 5000);

            }
        }
        return () => {
            ClearAllInterval()
        }; // use effect cleanup to set flag false, if unmounted
    }, [activeIndex])// eslint-disable-line react-hooks/exhaustive-deps

    function ClearAllInterval() {
        clearInterval(interval2.current)
    }


    function GetCurrentDataInLive(activeIndex) {
        try {
            ConnectWS("/generator/checkMatchExists", null, { match_id: match_detailstmp.subtournament_match_id, member1_id: match_detailstmp.match_member1_id, member2_id: match_detailstmp.match_member2_id }, function (response, logout) {
                if (logout === 1) {

                } else if (response && response.status && Number(response.status) === 200) {
                    if (response.data.match_data.match_details) {
                        setmatch_details(response.data.match_data.match_details)
                        if (systemId === 5) {
                            createChartDara(activeIndex, response.data.match_data.match_details)
                        }
                    }
                } else {

                }

            });
        } catch (e) {

        }
    }

    function preStart() {

        if (match_detailstmp.match_details && JSON.parse(match_detailstmp.match_details).mde === true) {

            //  setloading(true)
            ConnectWS("/generator/checkMatchExists", null, { match_id: match_detailstmp.subtournament_match_id, member1_id: match_detailstmp.match_member1_id, member2_id: match_detailstmp.match_member2_id }, function (response, logout) {
                if (logout === 1) {

                } else if (response && response.status && Number(response.status) === 200) {
                    if (response.data.match_data.match_details) {
                       
                        setmatch_details(response.data.match_data.match_details)
                        if (systemId === 5) {
                            createChartDara(activeIndex, response.data.match_data.match_details)
                        }

                    }

                } else {

                }
                setloading(false)

            });


        } else {

            setmatch_details(match_detailstmp.match_details)
            if (systemId === 5) {
                createChartDara(0, match_detailstmp.match_details)
            }
            setloading(false)
        }


    }

    //OGÓLNE WYNIKI
    function getMatchDetails() {
        try {

            if (match_details && JSON.parse(match_details).score) {
                let tmparr = JSON.parse(match_details).score
                let tmparr2 = []


                if (match_detailstmp.bracket) {
                    tmparr.forEach((row1) =>
                        tmparr2.push(" " + row1[match_detailstmp.match_member1_id] + ":" + row1[match_detailstmp.match_member2_id])
                    )
                    return <> <Header as='h4' block>
                        Wyniki szczegółowe
                    </Header><span style={{ fontSize: 22 }}>{tmparr2.toString()}</span></>
                } else {
                    if (mReverse) {
                        tmparr.forEach((row1) =>
                            tmparr2.push(" " + row1[match_detailstmp.match_member2_id] + ":" + row1[match_detailstmp.match_member1_id])
                        )

                        return <> <Header as='h4' block>
                            Wyniki szczegółowe
                        </Header><span style={{ fontSize: 22 }}>{tmparr2.toString()}</span></>

                    } else {
                        tmparr.forEach((row1) =>
                            //  tmparr2.push(" " + row1[match_detailstmp.match_member2_id] + ":" + row1[match_detailstmp.match_member1_id])
                            tmparr2.push(" " + row1[match_detailstmp.match_member1_id] + ":" + row1[match_detailstmp.match_member2_id])
                        )
                        return <> <Header as='h4' block>
                            Wyniki szczegółowe
                        </Header><span style={{ fontSize: 22 }}>{tmparr2.toString()}</span></>
                    }
                }


                //return <span style={{fontSize:11}}><br/>{JSON.parse(row.match_details).score}</span>
            } else {
                return ""
            }

        } catch (e) {
            console.log(e)
        }

    }

    // PREDEFINIOWANE DART

    function retdata(check) {
        if (check !== null) {
            return check
        } else {
            return <span style={{ fontSize: 12 }}>---</span>
        }

    }

    let options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,

        scales: {
            x: {
                display: true,
                grid: {
                    
                    color: authContext.darkMode ? '#2d2c2c' : '#dbdada'
                },
                title: {
                    display: true,
                    text: 'RUNDA'
                }
            },
            y: {
                type: 'linear',
                display: true,
                grid: {
                    color: authContext.darkMode ? '#2d2c2c' : '#dbdada'
                },
                position: 'left',
                title: {
                    display: true,
                    text: 'TRAFIENIA '
                },
            }
        }
    }






    function createChartDara(i, match_details) {
        try {

            let jsondata = JSON.parse(match_details)

            if (jsondata) {
                let ccc = jsondata.score_dart.game.legs.length - 1

                if (ccc > i) {
                    ccc = i
                }

                if (jsondata.score_dart.game.legs[ccc].score.length > 0) {

                   

                    let h1 = jsondata.score_dart.game.handicap[match_detailstmp.match_member1_id]
                    let h2 = jsondata.score_dart.game.handicap[match_detailstmp.match_member2_id]


                    let m1 = []
                    let m2 = []

                    m1.push(h1)
                    m2.push(h2)

                   
                    jsondata.score_dart.game.legs[ccc].score.forEach((data, i) => {
                        if (data.hit_point[match_detailstmp.match_member1_id] !== null) {
                            h1 = Number(h1) - Number(data.hit_point[match_detailstmp.match_member1_id])
                            m1.push(h1)
                        }
                    })
                    jsondata.score_dart.game.legs[ccc].score.forEach((data, i) => {
                        if (data.hit_point[match_detailstmp.match_member2_id] != null) {
                            h2 = Number(h2) - Number(data.hit_point[match_detailstmp.match_member2_id])
                            m2.push(h2)
                        }

                    })

                    let cr = m1.length >= m2.length ? m1.length : m2.length

                    setDataChart({
                        labels: [...Array(cr).keys()],
                        datasets: [
                            {

                                label: matche_info.member1_name,
                                data: m1,
                                borderColor: 'rgb(255, 99, 132)',
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',

                            },
                            {
                                label: matche_info.member2_name,
                                data: m2,
                                borderColor: 'rgb(53, 162, 235)',
                                backgroundColor: 'rgba(53, 162, 235, 0.5)',

                            },

                        ],

                    })
                }

            }

        } catch (e) {

        }


    }


    function getScoreInLiveD() {
        try {
            let m1 = 0
            let m2 = 0
            if (JSON.parse(match_details).score_dart.game.legs && JSON.parse(match_details).score_dart.game.legs.length > 0) {
                JSON.parse(match_details).score_dart.game.legs.forEach((row) => row.winner_member === match_detailstmp.match_member1_id ? m1++ : row.winner_member === match_detailstmp.match_member2_id ? m2++ : "")
            }

            return <>{m1}:{m2}</>
        } catch (e) {
            return ""
        }
    }


    function getMatchDetailsDarts() {
        try {


            return <><Grid stackable><Grid.Row verticalAlign='middle' >
                <Grid.Column width={width < 860 ? 16 : 6}>

                    <span style={{ fontSize: 16 }}>

                        {match_detailstmp && match_details && JSON.parse(match_details).score_dart ?
                       <div style={{ overflowX: "auto", width:  "100%" }} >
                            <Table compact striped unstackable style={{fontSize: width>500 ? 16: 11}}>
                             

                                <Table.Body>
                                    {JSON.parse(match_details).score_dart.stats.score_avg1D ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_avg1D[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell  textAlign='center' style={{fontSize: width>500 ? 14: 11}}>{t("dartStats.score_avg1D")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_avg1D[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_avg3D ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_avg3D[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell  textAlign='center' style={{fontSize: width>500 ? 14: 11}} >{t("dartStats.score_avg3D")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_avg3D[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_first1D9 ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_first1D9[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell  textAlign='center' style={{fontSize: width>500 ? 14: 11}} >{t("dartStats.score_first1D9")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_first1D9[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_first3D9 ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_first3D9[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell  textAlign='center' style={{fontSize: width>500 ? 14: 11}} >{t("dartStats.score_first3D9")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_first3D9[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_60p ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_60p[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell singleLine textAlign='center'>{t("dartStats.score_60p")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_60p[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_80p ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_80p[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell singleLine textAlign='center'>{t("dartStats.score_80p")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_80p[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_100p ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_100p[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell singleLine textAlign='center'>{t("dartStats.score_100p")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_100p[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_120p ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_120p[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell singleLine textAlign='center'>{t("dartStats.score_120p")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_120p[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_avg1D ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_140p[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell singleLine textAlign='center'>{t("dartStats.score_140p")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_140p[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_170p ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_170p[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell singleLine textAlign='center'>{t("dartStats.score_170p")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_170p[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_180 ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_180[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell singleLine textAlign='center'>{t("dartStats.score_180")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_180[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_hight_finish ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_hight_finish[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell singleLine textAlign='center'>{t("dartStats.score_hight_finish")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_hight_finish[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                    {JSON.parse(match_details).score_dart.stats.score_best_leg ?
                                        <Table.Row>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_best_leg[mReverse ? match_detailstmp.match_member2_id : match_detailstmp.match_member1_id])}</Table.Cell>
                                            <Table.Cell singleLine textAlign='center'>{t("dartStats.score_best_leg")}</Table.Cell>
                                            <Table.Cell width={2} singleLine textAlign='center'>{retdata(JSON.parse(match_details).score_dart.stats.score_best_leg[mReverse ? match_detailstmp.match_member1_id : match_detailstmp.match_member2_id])}</Table.Cell>
                                        </Table.Row>
                                        : ""}
                                </Table.Body>
                            </Table>
                            </div>
                            : ""}

                    </span>  </Grid.Column>
                <Grid.Column width={width < 860 ? 16 : 10}>
                    <Segment>

                        {match_details && JSON.parse(match_details) && JSON.parse(match_details).score_dart ?

                            <TabView activeIndex={activeIndex} onTabChange={(e) => {
                                setActiveIndex(e.index)
                                createChartDara(e.index, match_details)
                            }}>

                                {JSON.parse(match_details).score_dart.game.legs && JSON.parse(match_details).score_dart.game.legs.length > 0 ?
                                    JSON.parse(match_details).score_dart.game.legs.map((row, i) =>
                                        <TabPanel header={"LEG " + (i + 1)} key={i}>
                                            {dataChart ? <Line data={dataChart} options={options} height={width < 700 ? 300 : "auto"} /> : loadingShow()}
                                            {row.winner_member === match_detailstmp.match_member1_id ? <Message positive header={"LEGA WYGRYWA: " + matche_info.member1_name} /> : row.winner_member === match_detailstmp.match_member2_id ? <Message positive header={"LEGA WYGRYWA: " + matche_info.member2_name} /> : <Message info header={"W FAZIE ROZGRYWANIA"} />}
                                        </TabPanel>)
                                    : <TabPanel header={"Brak wykresu"} >
                                        Brak punktacji dla każdego lega z rozegranego pojedynku.
                                    </TabPanel>}


                            </TabView>
                            : ""}
                        {dataChart && dataChart.datasets && dataChart.datasets.length===2 ?  
                        <div style={{ overflowX: "auto", width: "100%" }} >
                        <Table compact celled unstackable >
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell singleLine><b>{dataChart.datasets[0].label}</b></Table.Cell>
                                    {dataChart.datasets[0].data ? 
                                    dataChart.datasets[0].data.map((row,i)=>
                                    <Table.Cell singleLine style={{fontSize:13}} key={i}>{row}{i>0 ? <span style={{fontSize:10}}> ({(dataChart.datasets[0].data[i-1] - dataChart.datasets[0].data[i])})</span> :""}</Table.Cell>
                                    ):""}
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell singleLine ><b>{dataChart.datasets[1].label}</b></Table.Cell>
                                    {dataChart.datasets[1].data ? 
                                    dataChart.datasets[1].data.map((row,i)=>
                                    <Table.Cell singleLine style={{fontSize:13}} key={i}>{row}{i>0 ? <span style={{fontSize:10}}> ({(dataChart.datasets[1].data[i-1] - dataChart.datasets[1].data[i])})</span> :""}</Table.Cell>
                                    ):""}
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        </div>
                        :""}  
                    </Segment>
                </Grid.Column>
            </Grid.Row></Grid>



            </>




        } catch (e) {
            console.log(e)
        }

    }


    function returnData() {

        if (systemId === 5) {
            // dart
            return getMatchDetailsDarts()
        } else {
            return getMatchDetails()
        }

    }


    return (
        loading ? loadingShow() : match_details ? <>
            {isLiveDuel ? LiveIcon3(t("LiveText")) : ''}
            <Container textAlign='center' style={{ backgroundColor: "#f5f5f5", padding: 15 }}>

                {matche_info ? <>
                    <Grid verticalAlign='middle' fluid  columns={3}>
                        <Grid.Row>
                            <Grid.Column width={7} textAlign='center'>
                                <Segment style={{ border: mReverse ? "1px solid #35a2eb" : "1px solid #ff6384", overflow: "hidden" }}>
                                <div className='imgPlayer2'><ShowImgAvatar urlpic={matche_info ? mReverse ? matche_info.member2_img_file : matche_info.member1_img_file : null} mtype={"USER"} msize={'large'}  /></div>
                                  <b><label style={{ fontSize: 20 }}>
                                       {mReverse ? matche_info.member2_name : matche_info.member1_name}</label></b><br />
                                    <span style={{ fontSize: 12 }}>UCZESTNIK</span>
                                    {match_detailstmp && match_detailstmp.member1_video && match_detailstmp.member1_video!=="local"?
                                        <ReactPlayer controls={true} url={match_detailstmp.member1_video} width={"100%"} height={200} />
                                        : ""}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column textAlign='center' width={2}>
                                {matche_info.member1_score !== null && matche_info.member2_score !== null ?
                                    mReverse ? <label style={{ fontSize: 25, fontWeight: "bold" }}>{matche_info.member2_score !== null ? matche_info.member2_score : ""}:{matche_info.member1_score !== null ? matche_info.member1_score : ""}</label>
                                        : <label style={{ fontSize: 25, fontWeight: "bold" }}>{matche_info.member1_score !== null ? matche_info.member1_score : ""}:{matche_info.member2_score !== null ? matche_info.member2_score : ""}</label>
                                    : <label style={{ fontSize: 25, fontWeight: "bold" }}>{getScoreInLiveD()}</label>}
                            </Grid.Column>
                            <Grid.Column width={7} textAlign='center'>
                                <Segment style={{ border: mReverse ? "1px solid #ff6384" : "1px solid #35a2eb", overflow: "hidden" }}>
                                <div className='imgPlayer2'><ShowImgAvatar urlpic={matche_info ? mReverse ? matche_info.member1_img_file : matche_info.member2_img_file : null} mtype={"USER"} msize={'large'}  /></div>
                                    <b><label style={{ fontSize: 20 }} >{mReverse ? matche_info.member1_name : matche_info.member2_name}</label></b><br />
                                    <span style={{ fontSize: 12 }}>UCZESTNIK</span>
                            
                                    {match_detailstmp && match_detailstmp.member2_video && match_detailstmp.member2_video!=="local" ?
                                        <ReactPlayer controls={true} url={match_detailstmp.member2_video} width={"100%"} height={200} />
                                        : ""}
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>


                </> : ""}
                {returnData()}

            </Container></>
            : ""
    )
}

export default ShowScoreDetailsView;



