import React, { useEffect, useContext, useState } from 'react';
import { Tab, Segment, Menu, Icon, Label, MenuItem, Table, Button } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { changePageName, checkiscompanypay, checkIsREFTOKENOn } from '../../../../myfunctions';
import ChangePassword from './ChangePassword';
import ChangeMyData from './ChangeMyData';
import PackagesGTL from './PackagesGTL';
import ViewRoot from './ViewRoot';
import MyReferrals from './MyReferrals';
import MyPayments from './MyPayments';
import WebsiteConfigurator from './WebsiteConfigurator';
import SupportPage from '../../../../tools/SupportPage/SupportPage';
import { ConnectWS } from '../../../../ConnectWS';
import ShowUserInfo from '../../../../tools/ShowUserInfo';
import { Dialog } from 'primereact/dialog';
import { useToasts } from 'react-toast-notifications';

//import Voucher from './Voucher';

function MyAccount(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);// eslint-disable-line no-unused-vars
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [activeIndex, setactiveIndex] = useState(0);
  const [panes, setpanes] = useState([]);
  const handleTabChange = (e, { activeIndex }) => setactiveIndex(activeIndex)
  const [visibleDialog, setvisibleDialog] = useState(null);
  const [visibleDialogBody, setvisibleDialogBody] = useState(null);

  useEffect(() => {
    changePageName()
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MyAccount")
    }
    getUserInfo()
    createPane(0, 0)

    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  function getContactRequest() {
    ConnectWS("/data/getContactRequest", authContext.token, { show_finished: false }, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        if (response.data && response.data.length > 0) {
          setvisibleDialogBody(response.data)
        } else {
          setvisibleDialogBody(null)
          setvisibleDialog(null)
        }
      } else {
      }

    });
  }


  function getUserInfo() {
    ConnectWS("/login/getUserInfo", authContext.token, {}, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        let tmpCount = 0
        let tmpCountMessage = 0

        let getUserPayableServices = []
        if (response.data && response.data.userPayableServices) {
          getUserPayableServices = response.data.userPayableServices
        }
        getUserPayableServices.forEach((row1) => {
          if ((row1.manual_transaction && row1.payment_status === "PENDING")) {
            tmpCount++
          } else if (row1.manual_transaction === 0) {
            tmpCount++
          }
        })
        if (tmpCount > 0) {
          authContext.changePaymentBlock(true)
        } else {
          authContext.changePaymentBlock(false)
        }

        if (response.data && response.data.unseenTicketMessagesCount) {
          tmpCountMessage = response.data.unseenTicketMessagesCount
        }
        if (response.data.unfinishedContactRequests > 0) {
          getContactRequest()
          setvisibleDialog(response.data.unfinishedContactRequests)
        } else {
          setvisibleDialog(null)
        }
        createPane(tmpCount, tmpCountMessage)
      } else {
      }

    });
  }


  function createPane(countPay, countMessage) {
    let panestmp = [
      { menuItem: { key: 'wk', icon: 'users', content: 'Widok konta' }, render: () => <Tab.Pane><ViewRoot setactiveIndex={setactiveIndex} /></Tab.Pane> },
      { menuItem: { key: 'ab', icon: 'certificate', content: 'Pakiety - Cennik' }, render: () => <Tab.Pane><PackagesGTL /></Tab.Pane> },
      //{ menuItem: { key: 'voucher', icon: 'credit card', content: 'Voucher' }, render: () => <Tab.Pane><Voucher /></Tab.Pane> },

      { menuItem: { key: 'ed', icon: 'edit', content: 'Moje dane' }, render: () => <Tab.Pane><ChangeMyData /></Tab.Pane> },
      { menuItem: { key: 'pass', icon: 'unlock', content: 'Zmień hasło' }, render: () => <Tab.Pane><ChangePassword /></Tab.Pane> },
      checkiscompanypay() ? {
        menuItem: (<MenuItem key='payment'>
          <Icon name="payment" />
          Moje płatności {countPay > 0 ? <Label size='mini' className='blinkmpN2' >{countPay}</Label> : ""}
        </MenuItem>), render: () => <Tab.Pane><MyPayments /></Tab.Pane>
      } : "",
      { menuItem: { key: 'ks', icon: 'globe', content: 'Konfigurator strony' }, render: () => <Tab.Pane><WebsiteConfigurator /></Tab.Pane> },
      {
        menuItem: (<MenuItem key='zg'>
          <Icon name="mail" />
          Zgłoszenia/Pytania {countMessage > 0 ? <Label size='mini' className='blinkmpN2' >{countMessage}</Label> : ""}
        </MenuItem>), render: () => <Tab.Pane><SupportPage /></Tab.Pane>
      },

    ]

    if (checkIsREFTOKENOn()) {
      panestmp.push({
        menuItem: (<Menu.Item key='messages' color='red'>
          <span style={{ color: "orange" }}><Icon name="handshake" />Poleć innym</span>
        </Menu.Item>), render: () => <Tab.Pane><MyReferrals /></Tab.Pane>
      })
    }

    setpanes(panestmp)
  }


  function finishContactRequest(id){
    ConnectWS("/data/finishContactRequest", authContext.token, { id: id }, function (response, logout) {
      if (logout === 1) {
  

      } else if (response && response.status && Number(response.status) === 200) {
        getContactRequest()
        addToast(response.data.message, { appearance: 'success' });
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
  
    });
  
  }
 
  return (
    <>
      <ShowUserInfo />

      <Segment>

        {width < 1200 ?
          <Tab activeIndex={activeIndex} onTabChange={handleTabChange} menu={{ className: "wrapped", color: "red" }} panes={panes} />
          :
          <Tab activeIndex={activeIndex} onTabChange={handleTabChange} menu={{ fluid: true, secondary: true, pointing: true, className: "wrapped", color: "red" }} panes={panes} />
        }

      </Segment>
      <Dialog header={<span><Icon name="exclamation circle" size='large' /> Masz {visibleDialog} prośby o kontakt</span>} visible={visibleDialog !== null} position={'bottom-right'} modal style={{ maxWidth: '50vw', minWidth: '25vw' }} onHide={() => setvisibleDialog(null)}
        draggable={false} resizable={false}>
        <Table style={{fontSize:11}} color="blue" celled striped compact >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine width={1} textAlign='center'>OSOBA</Table.HeaderCell>
              <Table.HeaderCell singleLine width={1} textAlign='center'>DATA DODANIA</Table.HeaderCell>
              <Table.HeaderCell singleLine width={1} textAlign='center'>DATA KONTAKTU</Table.HeaderCell>
              <Table.HeaderCell singleLine width={1} textAlign='center'>TEL</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>

          {visibleDialogBody ? visibleDialogBody.map((row, i) => <Table.Row key={i}>
            <Table.Cell textAlign='center' singleLine>{row.name}{row.logged_user ? <> <br/>({row.logged_user}) </> : ""}</Table.Cell>
            <Table.Cell textAlign='center' singleLine>{row.create_ts}</Table.Cell>
            <Table.Cell textAlign='center' singleLine>{row.contact_dt}</Table.Cell>
            <Table.Cell textAlign='center' singleLine>{row.phone}</Table.Cell>
            <Table.Cell textAlign='center' width={1} singleLine><Button size="mini" onClick={()=>finishContactRequest(row.id)}>WYKONANE</Button></Table.Cell>
          </Table.Row>) : ""}

          </Table.Body>


        </Table>
      
      </Dialog>
    </>
  )
}

export default MyAccount;