import React, { useState, useEffect, useContext } from 'react';
import { Button, Checkbox, Icon, Input, Label, Message, Segment, Table } from 'semantic-ui-react';

import AuthContext from '../../context/authContext';
import { ConnectWS } from '../../ConnectWS';
import { useToasts } from 'react-toast-notifications';
import { t } from 'i18next';
import { fiba_style_cfg } from '../../leagueFuntions';



function EditLeagueGlobalStatsOther(props) {

    const authContext = useContext(AuthContext);

    const [globalStats, setglobalStats] = useState(null)
    const [loading, setloading] = useState(false)
    const [onFibaStats, setonFibaStats] = useState(false)
    const [subtournamentExtConf, setsubtournamentExtConf] = useState(null)

    const [jsonOptFiba, setjsonOptFiba] = useState({ // eslint-disable-line no-unused-vars
        "fiba": true,
        "dataJson": {
            "team1_id": null,
            "team1_name": null,
            "team1_members": [],
            "team2_id": null,
            "team2_name": null,
            "team2_members": []
        }
    })

    const [stats_cfg, setstats_cfg] = useState({ ...fiba_style_cfg() })

    const [jsonOpt, setjsonOpt] = useState([{ // eslint-disable-line no-unused-vars
        "icon": "star",
        "name": "mvp",
        "sort": 1,
        "type": "inputboolicon",
        "hide_viewers": false,
        "show_chart": true,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "in_match",
        "sort": 2,
        "type": "inputbool",
        "hide_viewers": false,
        "show_chart": true,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "matche_cards",
        "sort": 3,
        "type": "boolimagecard",
        "hide_viewers": false,
        "show_chart": true,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "count_assist",
        "sort": 4,
        "type": "inputnumber",
        "hide_viewers": false,
        "show_chart": true,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "technical_foul",
        "sort": 5,
        "type": "inputnumber",
        "hide_viewers": false,
        "show_chart": true,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "points_scored_own_goals",
        "sort": 6,
        "type": "inputnumber",
        "hide_viewers": true,
        "show_chart": true,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "points_collection",
        "sort": 7,
        "type": "inputnumber",
        "hide_viewers": false,
        "show_chart": true,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "points_capture",
        "sort": 8,
        "type": "inputnumber",
        "hide_viewers": false,
        "show_chart": true,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "points_scored_goals",
        "sort": 100,
        "type": "inputnumber",
        "hide_viewers": false,
        "show_chart": true,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "points_scored",
        "sort": 101,
        "type": "inputnumber",
        "hide_viewers": false,
        "show_chart": true,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }])

    const { addToast } = useToasts();


    function checkIsFibaOn() {
        try {
            if (authContext && authContext.user && authContext.user.ai === 2) {
                return true
            }
            return false
        } catch (e) {
            return false
        }
    }

    function createJsonIfIsNull(checked) {

        if (!checked) {
            let tmp = {
                "others": {
                    "technical_foul_by_coach": {
                        "icon": "user",
                        "name": "technical_foul_by_coach",
                        "type": "inputnumber",
                        "team1_id": null,
                        "team2_id": null,
                        "team1_members": null,
                        "team2_members": null
                    }
                },
                "members": {
                },
                "version": 2
            }
            setglobalStats(tmp)
        } else {
            setstats_cfg({ ...fiba_style_cfg() })
        }
        setonFibaStats(checked)
    }

    useEffect(() => {
        getSubtournamentMatchJsonTemplate()
        getSubtournamentExtConf()

        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    function getSubtournamentMatchJsonTemplate() {
        setloading(true)
        ConnectWS("/generator/getSubtournamentMatchJsonTemplate", authContext.token, { subtournament_id: props.openModalmatch_details_ex.subtournament_id, system_id: props.systemId }, function (response, logout) {
            if (logout === 1) {


            } else if (response && response.status && Number(response.status) === 200) {
                if (response.data.ai_data_type === "FIBA") {
                    setonFibaStats(true)
                    setstats_cfg({ ...fiba_style_cfg(), ...response.data.stats_cfg })
                } else {
                    setonFibaStats(false)
                }
                setglobalStats(response.data.match_details_ex)


            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }
            setloading(false)

        });
    }



    function getSubtournamentExtConf() {

        if (props.systemId !== 9) {
            return
        }
        setloading(true)
        ConnectWS("/generator/getSubtournamentExtConf", authContext.token, { subtournament_id: props.openModalmatch_details_ex.subtournament_id }, function (response, logout) {
            if (logout === 1) {


            } else if (response && response.status && Number(response.status) === 200) {


                let analyze_penalties = [{ id: "technical_foul", enable: true, count_fouls: 2, pause_match: 1 },
                    { id: "fouls_technical", enable: true, count_fouls: 2, pause_match: 1 },
                   ]
                if (response.data) {
                    if(response.data.analyze_penalties.length>2){
                        setsubtournamentExtConf(response.data)
                    }
                    else{
                        setsubtournamentExtConf({
                            analyze_penalties: analyze_penalties
                        })
                    }
                 

                } else {
                    setsubtournamentExtConf({
                        analyze_penalties: analyze_penalties
                    })
                }

            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }
            setloading(false)

        });
    }

    function closeModal() {
        props.setopenModalmatch_details_ex({ open: false, row: null, onlyView: 1, st: null })
        props.setOpen(false)
    }


    function checkISOn(row) {
        try {
            if (globalStats.version && globalStats.members[row] && globalStats.members[row].name === row) {
                return <Icon name="check" color='green' className='cursorPointer' onClick={() => removeStats(row)} />
            } else {
                return <Icon name="x" color='grey' />
            }
        } catch (e) {
            console.log(e)
            return <Icon name="x" />
        }

    }
    function checkOnlyViewAdmin(row) {
        try {
            if (globalStats.version && globalStats.members[row] && globalStats.members[row].hide_viewers === false) {
                return <Icon name="x" color='grey' className='cursorPointer' onClick={() => onChangeView(row, true, "hide_viewers")} />
            } else if (globalStats.version && globalStats.members[row] && globalStats.members[row].hide_viewers === true) {
                return <Icon name="check" color='green' className='cursorPointer' onClick={() => onChangeView(row, false, "hide_viewers")} />
            } else {
                return "Wyłacz i włacz ponownie"
            }
        } catch (e) {
            console.log(e)
            return <Icon name="x" />
        }

    }
    function checkShowChart(row) {
        try {
            if (globalStats.version && globalStats.members[row] && globalStats.members[row].show_chart === false) {
                return <Icon name="x" color='grey' className='cursorPointer' onClick={() => onChangeView(row, true, "show_chart")} />
            } else if (globalStats.version && globalStats.members[row] && globalStats.members[row].show_chart === true) {
                return <Icon name="check" color='green' className='cursorPointer' onClick={() => onChangeView(row, false, "show_chart")} />
            } else {
                return "Wyłacz i włacz ponownie"
            }
        } catch (e) {
            console.log(e)
            return <Icon name="x" />
        }

    }



    function onChangeView(row, value, view) {
        try {
            let tmp = JSON.parse(JSON.stringify(globalStats))
            tmp.members[row][view] = value
            setglobalStats(tmp)
        } catch (e) {
            console.log(e)

        }
    }

    function removeStats(row) {
        try {
            let tmp = JSON.parse(JSON.stringify(globalStats))
            delete tmp.members[row];
            setglobalStats(tmp)
        } catch (e) {
            console.log(e)

        }
    }

    function checkIsOff(row) {
        try {
            if (globalStats.version && globalStats.members[row.name]) {
                return false
            }
            return true
        } catch (e) {
            console.log(e)
            return false

        }
    }

    function checkIsOffExtConf(row) {
        try {
            if (onFibaStats) {
                if (row.id === "technical_foul") {
                    return false
                }
            } else {
                if (row.id === "fouls_technical") {
                    return false
                }
            }

            return true
        } catch (e) {
            console.log(e)
            return false

        }
    }


    function addRow(row, i) {
        try {

            let tmp = JSON.parse(JSON.stringify(globalStats))
            tmp.members[row.name] = row
            setglobalStats(tmp)
        } catch (e) {
            console.log(e)


        }
    }





    function saveDb() {
        if (subtournamentExtConf) {
            ConnectWS("/generator/setSubtournamentExtConf", authContext.token, { subtournament_id: props.openModalmatch_details_ex.subtournament_id, ext_conf: subtournamentExtConf }, function (response, logout) {
                if (logout === 1) {


                } else if (response && response.status && Number(response.status) === 200) {

                } else {
                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }
                }

            });
        }

        setloading(true)
        ConnectWS("/generator/setSubtournamentMatchJsonTemplate", authContext.token, { subtournament_id: props.openModalmatch_details_ex.subtournament_id,  match_details_ex: onFibaStats ? jsonOptFiba : globalStats, ai_data_type: onFibaStats ? "FIBA" : "DEFAULT", stats_cfg: onFibaStats ? stats_cfg : null }, function (response, logout) {
            if (logout === 1) {


            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                closeModal()
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }
            setloading(false)

        });
    }


    function updateFibaStatsClick(key, value, co) {
        let tmp = JSON.parse(JSON.stringify(stats_cfg))
        if (co === "showInMembers" && value["showInMembers"] === true) {
            // czyli false
            tmp[key]["showChart"] = false
            tmp[key]["showTop"] = false

        }
        if (co === "showTop" && value["showTop"] === false) {
            // czyli false
            tmp[key]["showInMembers"] = true

        }
        if (co === "showChart" && value["showChart"] === false) {
            // czyli false
            tmp[key]["showInMembers"] = true

        }
        tmp[key][co] = value[co] ? false : true
        setstats_cfg(tmp)
    }



    function updatesubtournamentExtConfStats(i, co, value) {
        let tmp = JSON.parse(JSON.stringify(subtournamentExtConf))
        tmp.analyze_penalties[i][co] = value
        setsubtournamentExtConf(tmp)
    }

    return (
        <div style={{ marginBottom: 20 }}>


            {props.systemId === 9 ? <Segment>
                <Label color='blue' ribbon>FIBA LiveStats - KOSZYKÓWKA - <a target='_blank' rel="noreferrer" href="https://www.fibaorganizer.com/">https://www.fibaorganizer.com</a></Label>
                <Message>
                    Po włączeniu tej opcji wszystkie statystyki zgodne z protokołem FIBA będą automatycznie zapisywane i dostępne w raportach meczowych.
                </Message>
                <Checkbox style={{ marginBottom: 10, marginTop: 10 }} toggle label={"Włącz statystyki z protokołu FIBA"} checked={onFibaStats} onChange={(e, d) => checkIsFibaOn() ? createJsonIfIsNull(d.checked) : addToast("Brak włączonej funkcjonalności", { appearance: 'info' })} />
            </Segment> : ""}


            <Message info size='mini'>W tym oknie możesz zmienić globalnie zbierane statystyki uczestników dla tej ligi</Message>
            {onFibaStats ?
                <Table size='small' celled striped compact unstackable >
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell width={1} textAlign='center'>STATYSTYKI</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>ZAKŁADKA ZAWODNICY</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>ZAKŁADKA STATYSTYKI ZAWODNIKÓW (tabele TOP10)</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>ZAKŁADKA STATYSTYKI ZAWODNIKÓW - WYKRES WŁĄCZONY  (tabele TOP10)</Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {stats_cfg ?
                            Object.entries(stats_cfg).map(([key, value]) => <Table.Row key={key}>
                                <Table.Cell textAlign='center'>
                                    {t("stats." + key)}
                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'><Icon className='cursorPointer' onClick={() => updateFibaStatsClick(key, value, "showInMembers")} name={value.showInMembers ? "check" : "x"} color={value.showInMembers ? "green" : "grey"} /></Table.Cell>
                                <Table.Cell width={1} textAlign='center'><Icon className='cursorPointer' onClick={() => updateFibaStatsClick(key, value, "showTop")} name={value.showTop ? "check" : "x"} color={value.showTop ? "green" : "grey"} /></Table.Cell>
                                <Table.Cell width={1} textAlign='center'><Icon className='cursorPointer' onClick={() => updateFibaStatsClick(key, value, "showChart")} name={value.showChart ? "check" : "x"} color={value.showChart ? "green" : "grey"} /></Table.Cell>

                            </Table.Row>) : ""}
                    </Table.Body>
                </Table>

                :
                <Table size='small' celled striped compact unstackable >
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell width={1} textAlign='center'>STATYSTYKI</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>WIDOCZNE TYLKO DLA ADMINA</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>WYKRES WŁĄCZONY </Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>WŁĄCZONE</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {globalStats ?
                            Object.keys(globalStats.members).map((row, i) => <Table.Row key={i}>

                                <Table.Cell textAlign='center'>
                                    {t("stats." + globalStats.members[row].name)}

                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'>
                                    {checkOnlyViewAdmin(row)}
                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'>
                                    {checkShowChart(row)}
                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'>
                                    {checkISOn(row, i)}
                                </Table.Cell>
                            </Table.Row>) : ""}
                        {jsonOpt ?
                            jsonOpt.map((row, i) => checkIsOff(row) ? <Table.Row key={i}>
                                <Table.Cell textAlign='center'>
                                    {t("stats." + row.name)}
                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'></Table.Cell>
                                <Table.Cell width={1} textAlign='center'></Table.Cell>
                                <Table.Cell width={1} textAlign='center'>
                                    <Icon name="add" color='orange' title="dodaj" className='cursorPointer' onClick={() => addRow(row, i)} />
                                </Table.Cell>
                            </Table.Row> : "") : ""}
                    </Table.Body>
                </Table>
            }


            {props.systemId === 9 ? <>
                <Label color="brown">Konfiguracja wyklucze z kolejnych pojedynków</Label>

                <Table size='small' celled striped compact unstackable >
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell width={1} textAlign='center'>STATYSTYKI</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>ILOŚĆ</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>ILOŚĆ MECZY KARY </Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>WŁĄCZONE</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      
                        {subtournamentExtConf && subtournamentExtConf.analyze_penalties ?
                            subtournamentExtConf.analyze_penalties.map((row, i) => checkIsOffExtConf(row) ? <Table.Row key={i}>
                                <Table.Cell textAlign='center'>
                                    {row.type ? t("stats." + row.type) : t("stats." + row.id)}
                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'>
                                    <Input type='number' value={row.count_fouls} onChange={(e, d) => updatesubtournamentExtConfStats(i, "count_fouls", d.value = "" ? 0 : Number(d.value))} />
                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'>
                                    <Input type='number' value={row.pause_match} onChange={(e, d) => updatesubtournamentExtConfStats(i, "pause_match", d.value = "" ? 0 : Number(d.value))} />
                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'>
                                    <Icon className='cursorPointer' onClick={() => updatesubtournamentExtConfStats(i, "enable", row.enable ? false : true)} name={row.enable ? "check" : "x"} color={row.enable ? "green" : "grey"} />
                                </Table.Cell>
                            </Table.Row> : "") : ""}
                    </Table.Body>
                </Table>
            </> : ""}

            <Button loading={loading} disabled={loading} color='green' fluid onClick={() => saveDb()}>ZAPISZ KONFIGURACJĘ DLA LIGI</Button>


        </div>
    )
}

export default EditLeagueGlobalStatsOther;